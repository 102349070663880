

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useState } from "react";

/* eslint-disable react/prop-types */
import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import TeamProfileCard from "examples/Cards/TeamCards/TeamProfileCard";
import BasicStatisticsCard from "examples/Cards/StatisticsCards/BasicStatisticsCard";
import EventCard from "examples/Cards/EventCard";

// Teams page components
import Stories from "examples/Stories/PhotoStories";
import Post from "examples/Posts";
import EatPost from "examples/EatPosts";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/logos/food-icon-white.png";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoInvision from "assets/images/small-logos/logo-invision.svg";


function Page(props) {


    const { id } = useParams();
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/eatlist-restaurant/?restaurant_id=${id}`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, []);



    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={4} md={4}>
                    <BasicStatisticsCard
                        icon="star"
                        count={{ number: "Rating", label: props.data?.result?.restaurant_review_count+" Scores" }}
                        percentage={0 > 0 ? "2♥" : "‎"}
                        dropdown={{
                            action: "openClickEventsMenu",
                            menu: "",
                        }}
                        color={props.data?.result?.restaurant_review_count ? "warning" : "light"}
                        isLoading={!props.data?.result}
                    />

                </Grid>
                <Grid item xs={4} md={4}>
                    <BasicStatisticsCard
                        icon="comment"
                        count={{ number: "Comment", label: props.data?.result?.restaurant_review_count+" Reviews" }}
                        percentage={0 > 0 ? "3♥" : "‎"}
                        dropdown={{
                            action: "openClickEventsMenu",
                            menu: "",
                        }}
                        color={props.data?.result?.restaurant_review_count ? "warning" : "light"}
                        isLoading={!props.data?.result}
                    />

                </Grid>
                <Grid item xs={4} md={4}>
                    <BasicStatisticsCard
                        icon="favorite"
                        count={{ number: "Highligt", label: props.data?.result?.restaurant_user_tag_count + " Highlights" }}
                        percentage={0 > 0 ? "1♥" : "‎"}
                        dropdown={{
                            action: "openClickEventsMenu",
                            menu: "",
                        }}
                        color={props.data?.result?.restaurant_user_tag_count ? "warning" : "light"}
                        isLoading={!props.data?.result}

                    />

                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SoftBox my={3} mt={0} >

                    <Stories />
                </SoftBox>
            </Grid>
            <Grid item xs={12}>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid container spacing={3} item xs={12} lg={8}>
                            {data?.restaurant_reviews?.map((item, index) => (
                                <Grid item xs={12} key={index}>
                                    <EatPost data={item} type={"place"} />

                                </Grid>
                            ))}
                        </Grid>

                        <Grid item xs={12} lg={4} display={{ xs: "none", sm: "unset" }}>
                            <Grid item xs={12}>
                                <SoftBox mb={3}>
                                    <TeamProfileCard
                                        title="Cleaning"
                                        description="Higlights the most common labels in comments, providing key insights into customer feedback and sentiment about our {label} services to enhance quality."
                                        industry="100 Label"
                                        color="warning"
                                        rating={3.6}
                                        members={[
                                            { image: team1, name: "Alexa Tompson" },
                                            { image: team2, name: "Martin Doe" },
                                            { image: team3, name: "Alexaner Smith" },
                                            { image: team4, name: "Romina Hadid" },
                                        ]}
                                        dropdown={{
                                            action: "",
                                            menu: "",
                                        }}

                                    />
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12}>
                                <SoftBox mb={3}>
                                    <TeamProfileCard

                                        title="design"
                                        description="Because it's about motivating the doers. Because I’m here to follow my dreams and inspire other people to follow their dreams, too."
                                        industry="design team"
                                        rating={5}
                                        color="warning"
                                        members={[
                                            { image: team1, name: "Alexa Tompson" },
                                            { image: team2, name: "Martin Doe" },
                                            { image: team3, name: "Alexaner Smith" },
                                            { image: team4, name: "Romina Hadid" },
                                        ]}
                                        dropdown={{
                                            action: "",
                                            menu: "",
                                        }}
                                    />
                                </SoftBox>
                            </Grid>



                        </Grid>
                    </Grid>
                </SoftBox>
            </Grid>
        </>
    );
}

export default Page;