

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Rating from '@mui/material/Rating';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";
import SoftSelect from "components/SoftSelect";

// NewProduct page components
import FormField from "layouts/place/components/new-food/components/FormField";
/* eslint-disable react/prop-types */



function ProductInfo({ onFormChange }) {
  const [formValues, setFormValues] = useState({
    flavorName: '',
    rating: null,
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    onFormChange({ ...formValues, [name]: value });
  };

  const handleRatingChange = (event, newValue) => {
    setFormValues({
      ...formValues,
      rating: newValue,
    });
    onFormChange({ ...formValues, rating: newValue });
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">Add Flavor</SoftTypography>
      
      <SoftBox mt={3}>
        <Grid container spacing={3}>

        <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Name of Flavor&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="regular" color="text">
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <FormField 
              type="text" 
              placeholder="Food Name" 
              name="flavorName"
              value={formValues.flavorName}
              onChange={handleChange}
            />

            
          </Grid>

          <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Rating Score&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="regular" color="text">
                
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            

          </Grid>
          <Rating 
              sx={{ marginLeft: "30px" }} 
              name="customized-10" 
              defaultValue={null} 
              max={10} 
              value={formValues.rating}
              onChange={handleRatingChange}
            />
          <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Description&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="regular" color="text">
                  (optional)
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <FormField 
              type="text" 
              placeholder="Experience about food" 
              multiline 
              rows={5} 
              name="description"
              value={formValues.description}
              onChange={handleChange}
            />

            
          </Grid>

        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
