import React, { useState } from 'react';
import './TopBar.css';
/* eslint-disable react/prop-types */

const TopBar = ({ onDataUpdate, onSelectDataUpdate }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedButton, setSelectedButton] = useState('Top');

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
        onDataUpdate(event.target.value);
    };

    const handleButtonClick = (btnText) => {
        setSelectedButton(btnText);
        onSelectDataUpdate(btnText);
    };

    

    const buttons = ['Top' ,'Eatlist', 'Place', 'Foodie', 'Category', 'Flavor'];

    return (
        <div className="topbar">
            <div className="headers">
                <div className="buttons-container">

                    {searchQuery != "" ? buttons.map((btnText, idx) => (
                        <button 
                            key={idx} 
                            className={`header-button ${selectedButton === btnText ? 'selected' : ''}`} 
                            onClick={() => handleButtonClick(btnText)}
                        >
                            {btnText}
                        </button>
                    )) : <></>}
                </div>
            </div>
            <marquee className="marquee">
                Kashik Search - Meet a new tournament - Kashik Search - Match your food taste - Kashik Search - Find your common taste and share it!
            </marquee>
            <div className="search-bar-container">
                <button className="back-button"><i className="fas fa-arrow-left"></i></button>
                <div className="profile-text">
                <input 
                        type="text" 
                        className="search-input" 
                        placeholder="Search..." 
                        value={searchQuery}
                        onChange={handleInputChange}
                    />
                </div>
                <button className="search-button"><i className="fas fa-search"></i></button>
            </div>
        </div>
    );
};

export default TopBar;
