/* eslint-disable react/prop-types */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

const index = (data) => {

    try {

        function Author({ image, name }) {
            return (
              <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                  <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography variant="button" fontWeight="medium">
                    {name}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }


    const truncatedTextStyle = {
        width: '300px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        height: '20px',
        WebkitBoxOrient: 'vertical'
    };

    

        const { size } = typography;

        let tableRows = data.data.pozisyon.map(index => {
            return {
              "Müdürlük": [
                <img key="logoNewspaper" src="" alt="Logo" />,
                <span key="task1" > {index.mudurluk} </span>
              ],
              "İhtiyaç Sayısı": <SoftBadge variant="gradient" badgeContent={index.ihtiyac_sayisi} color="success" size="xs" container />,
              "Pozisyon": <SoftBadge variant="gradient" badgeContent={index.pozisyon} color="default" size="xs" container />,
              "Uyruk": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.uyruk}</SoftTypography>,
              "Statü": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.statu}</SoftTypography>,
              "Müdürlük": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.mudurluk}</SoftTypography>,
              "Birim": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.birim}</SoftTypography>,
              "Cinsiyet": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.cinsiyet}</SoftTypography>,
              "Notlar": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.notlar}</SoftTypography>,

              /*"detail": <><SoftTypography variant="caption">Sorumlu Kişi: {index.ad_soyad} ({index.ad_soyad}) <br />Dosya No: {index.ad_soyad} <br /> <SoftBadge variant="gradient" badgeContent={index.ad_soyad} color="default" size="xs" container /> <br /> <Icon sx={{ fontWeight: "bold", color: "error"}}>
              label_important
          </Icon>  Açıklama: {index.ad_soyad} <br />
              {index.ad_soyad === "null" ? "ChatBubble" : <><br /><SoftBadge variant="gradient" badgeContent="KARAR" color="error" size="xs" container /> <br /> {index.ad_soyad}  </>}
              </SoftTypography></>,*/
              
              //<SoftTypography variant="caption" color="text" fontWeight="medium">{index.aciklama}</SoftTypography>
        
        
            };
          });


        const projectsTableData = {
            columns: [
                { name: "Müdürlük", align: "center" },
                { name: "Birim", align: "center" },
                { name: "Pozisyon", align: "center" },
                { name: "Cinsiyet", align: "center" },
                { name: "İhtiyaç Sayısı", align: "center" },
                { name: "Notlar", align: "left" },
                
            

            ],

            rows: [
                ...tableRows,

            ],
        };


        return (
            <Grid item xs={12} md={12} xl={12}>
                <Card>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Açık Pozisyonlar Listesi</SoftTypography>
                    </SoftBox>
                    <SoftBox
                        sx={{
                            "& .MuiTableRow-root:not(:last-child)": {
                                "& td": {
                                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                        `${borderWidth[1]} solid ${borderColor}`,
                                },
                            },
                        }}
                    >
                        <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                    </SoftBox>
                </Card>
                
            </Grid>
        );
    }
    catch (error) {
        console.log(error);
        return(
        <Grid item xs={12} md={12} xl={12}>
            <SoftBox pt={3} px={3}>
                <SoftTypography variant="h2" fontWeight="medium">
                    Yetkilendirilmemiş Giriş
                </SoftTypography>
            </SoftBox>
        </Grid>
        );
    }
}

export default index;
