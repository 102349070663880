import React, { useState, useEffect } from "react";
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";

// NewProduct page components
import FormField from "layouts/place/components/new-rate/components/FormField";

import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import BasicModal from 'layouts/place/components/new-rate/components/ProductInfo/modal';

import { useParams } from 'react-router-dom';

import TemperatureSlider from "examples/Ratings/TemperatureSlider";

/* eslint-disable react/prop-types */

function ProductInfo({ data, onComplete }) {

  const { id } = useParams(); // Restoran ID'sini alıyoruz

  const [loading, setLoading] = useState(false);


  const [expandFood, setExpandFood] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [yolculuklar, setYolculuklar] = useState([]);
  const [availableFoods, setAvailableFoods] = useState(data?.result?.foods || []); // Gelen foods verisini burada saklıyoruz ve boş diziyle başlatıyoruz

  // Restoranın genel rating ve yorumu
  const [generalRating, setGeneralRating] = useState(null);  // Restoranın genel rating
  const [generalComment, setGeneralComment] = useState("");  // Restoranın genel yorumu

  const [formData, setFormData] = useState({}); // Form verilerini tutmak için state
  const [ratings, setRatings] = useState({}); // Her yiyecek için rating bilgisi
  const [comments, setComments] = useState({}); // Her yiyecek için comment bilgisi

  useEffect(() => {
    // Sayfa açıldığında hedef bölüme kaydır
    const targetElement = document.getElementById("rating");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // Modal açma fonksiyonu
  const handleModalOpen = (index) => {
    setModalContent(index);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Yolculuk ekleme fonksiyonu
  const handleYolculukEkle = (foodName, index) => {
    const selectedFood = availableFoods.find((food) => food.name === foodName);
    if (selectedFood) {
      const newYolculuk = {
        id: yolculuklar.length + 1,
        food_id: selectedFood.id, // food_id ekliyoruz
        yolculuk_turu: foodName,
        image: selectedFood.food_photo,
        gidisDonus: false,
      };
      setYolculuklar([...yolculuklar, newYolculuk]);

      // Seçilen öğeyi options listesinden kaldır
      setAvailableFoods(availableFoods.filter((item) => item.name !== foodName));
    } else {
      handleModalOpen();
    }
  };

  // Modal Submit fonksiyonu
  const handleModalSubmit = (newFood) => {
    const newYolculuk = {
      id: yolculuklar.length + 1,
      food_id: newFood.id, // food_id ekliyoruz
      yolculuk_turu: newFood.name,
      image: newFood.food_photo,
      gidisDonus: false,
    };
    setYolculuklar([...yolculuklar, newYolculuk]);

    // Modalda girilen yiyeceği doğrudan seçeneklerden de ekleyelim
    handleYolculukEkle(newFood.name);
  };

  // Yolculuk silme fonksiyonu
  const handleYolculukSil = (id, foodName) => {
    setYolculuklar((prevState) => prevState.filter((yolculuk) => yolculuk.id !== id));

    // Silinen öğeyi options listesine geri ekle
    const removedFood = data?.result?.foods?.find((food) => food.name === foodName);
    if (removedFood) {
      setAvailableFoods([...availableFoods, removedFood]);
    }

    // Silinen yiyeceğin yorumlarını ve puanlarını temizleyelim
    setRatings((prevRatings) => {
      const updatedRatings = { ...prevRatings };
      delete updatedRatings[id]; // Silinen yiyeceğin rating bilgisini temizliyoruz
      return updatedRatings;
    });

    setComments((prevComments) => {
      const updatedComments = { ...prevComments };
      delete updatedComments[id]; // Silinen yiyeceğin comment bilgisini temizliyoruz
      return updatedComments;
    });
  };

  // Her yiyecek için rating güncelleme
  const handleRatingChange = (id, newValue) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: newValue,
    }));
  };

  // Her yiyecek için comment güncelleme
  const handleCommentChange = (id, newComment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [id]: newComment,
    }));
  };

  // Axios ile backend'e veri gönderme fonksiyonu
  const handleSubmit = async () => {
    setLoading(true);
    // Tüm yolculuklar için rating ve comment verilerini birleştiriyoruz
    const yolculukVerileri = yolculuklar.map((yolculuk) => ({
      ...yolculuk,
      rating: ratings[yolculuk.id] || null, // Eğer rating yoksa null olarak gönderir
      comment: comments[yolculuk.id] || "", // Eğer comment yoksa boş string gönderir
    }));

    // Tüm form verilerini güncelliyoruz, restoranın genel rating ve yorumunu ekliyoruz
    const updatedFormData = {
      ...formData,
      yolculuklar: yolculukVerileri,
      generalRating,    // Restoranın genel rating'i
      generalComment,   // Restoranın genel yorumu
      google_id: id  // Restoranın ID'si
    };

    try {
      const response = await axios.post(window.BASE_URL + "/review/create", updatedFormData);
      if (response.status >= 200 && response.status < 300) {
        console.log("Data submitted successfully:", response.data);
        onComplete();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    } finally {
      setLoading(false); // İşlem bittiğinde loading durumunu kapat
    }
  };

  const NewTarget = ({ name, image }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="sm" icon="add" bgColor="dark" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
        </SoftBox>
      </SoftBox>
    } color="light" size="xl" container />
  );

  const Target = ({ name, image }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={image ? window.BASE_URL + "/" + image : ""} size="sm" icon="local_dining" bgColor="flatwhite" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
        </SoftBox>
      </SoftBox>
    } color="light" size="xl" container />
  );

  return (
    <SoftBox px={3} pt={2}>
      <BasicModal open={modalOpen} handleClose={handleModalClose} content={modalContent} handleModalSubmit={handleModalSubmit} />
      <SoftTypography id="rating" variant="h5">Place Rating</SoftTypography>

      {/* Restoranın genel ratingi */}
      <SoftBox mt={3} mb={3}  >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {/* <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Overall Rating
              </SoftTypography>
            </SoftBox> */}
          </Grid>
          {/* <Rating
            sx={{ marginLeft: "30px" }}
            name="generalRating"
            value={generalRating}
            onChange={(event, newValue) => setGeneralRating(newValue)} // Restoran genel rating state güncelleme
            max={10}
          /> */}
        </Grid>

        <SoftBox  style={{ marginInline: "-20px" }} >
          <Card sx={{ height: "99.5%" }}>
            <TemperatureSlider
              handle1={{
                value: generalRating,
                onChange: (v) => setGeneralRating(Number(v.toFixed(1))),

              }}
              title="‎"
              current={
                <>
                  {generalRating ? generalRating : "0.0"}
                  <SoftTypography component="span" variant="h4" color="text">
                    ‎‎‎ {"‎ "}★
                    <br /> General
                  </SoftTypography>
                </>
              }
              label="Point"
              color="leaf"
              value={generalRating}
              start={<>0.0</>}
              end={<>10.0</>}
              minValue={0.0}
              maxValue={10.0}

            />
            <SoftBox p={2} pt={0} position="relative" height="100%" style={{ justifyItems: "center", marginTop:"-20px", alignSelf: "center" }}>
            <Rating
          style={{ marginTop: "20px", marginBottom: "30px" }}
          name="generalRating"
          value={generalRating}
          precision={0.5}
          // Restoran genel rating state güncelleme
          max={10}
          onChange={(event, newValue) => setGeneralRating(newValue)}
        />
        </SoftBox>
        
          </Card>
        </SoftBox>


        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
              <SoftBox pt={2} pb={3} px={0}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                  <SoftBox style={{ width: "100%", display: "flex", justifyContent: "space-between" }} onClick={() => setExpandFood(!expandFood)}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftAvatar src="" variant="rounded" icon="local_dining" bgColor={"dark"} />
                      <SoftBox ml={2}>
                        <SoftTypography variant="h5" fontWeight="medium">
                          Flavor Rating
                        </SoftTypography>
                        <SoftBox display="flex" alignItems="flex-end">
                          <SoftTypography variant="button" color="text" fontWeight="regular">
                            Tasting Details
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>

                    </SoftBox>

                    <SoftButton color="secondary" variant="white" sx={{ background: "transparent" }}>

                      <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }} fontSize={expandFood ? "small" : "medium"} color="dark">
                        {expandFood ? "visibility_off" : "expand_more"}
                      </Icon>
                    </SoftButton>
                  </SoftBox>




                  {<SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt={expandFood ? 3 : 0}
                    sx={{
                      width: "100%",
                      maxWidth: "100%",
                      display: "block",
                      opacity: expandFood ? 1 : 0,
                      transform: expandFood ? "translateY(0)" : "translateY(-20px)",
                      height: expandFood ? "auto" : 0, // Alanın kaybolması
                      // Taşmaları gizler
                      transition: "opacity 0.3s ease, transform 0.3s ease, height 0.3s ease", // Yükseklik de animasyona dahil
                      visibility: expandFood ? "visible" : "hidden",
                      zIndex: "10" // Görünürlük kontrolü
                    }}
                  >
                    <SoftSelect
                      defaultValue={[]}
                      options={[
                        { value: "create_new_flavor", label: <NewTarget name="Create New Flavor" image="" /> },
                        ...availableFoods.map((food) => ({
                          value: food.name,
                          label: <Target name={food.name} image={food.food_photo} />
                        }))
                      ]}
                      value=""
                      size="large"
                      fullWidth
                      onChange={(e) => handleYolculukEkle(e.value)}
                      sx={{ width: "100%", minWidth: "400px", maxWidth: "100%" }}
                    />
                  </SoftBox>}
                </SoftBox>

                <SoftBox pt={yolculuklar.length > 0 ? 6 : 2} pb={3} px={0}>
                  {yolculuklar.map((yolculuk) => (
                    <Card key={yolculuk.id} ml={3} sx={{ padding: "20px", paddingBottom: "10px", marginInline: "-20px", marginBottom: "30px" }}>
                      <div key={yolculuk.id}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                          <SoftBox display="flex" alignItems="center">
                            <SoftAvatar src={window.BASE_URL + "/" + yolculuk.image} alt="logo" variant="rounded" icon="local_dining" bgColor="flatwhite" />
                            <SoftBox ml={2}>
                              <SoftTypography variant="h5" fontWeight="medium">
                                {yolculuk.yolculuk_turu}
                              </SoftTypography>
                              <SoftBox display="flex" alignItems="flex-end">
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                  Flavor Detail
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox display="flex" alignItems="center" justifyContent="flex-end">
                            <SoftAvatar size="sm" variant="rounded" icon="bookmark" bgColor="flatwhite" style={{ marginRight: "9px", cursor: "pointer" }} > delete </SoftAvatar>
                            <SoftAvatar size="sm" variant="rounded" icon="delete" bgColor="dark" style={{ cursor: "pointer" }} onClick={() => handleYolculukSil(yolculuk.id, yolculuk.yolculuk_turu)} > delete </SoftAvatar>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox ml={2} pl={0} pt={2} lineHeight={1}>
                          <Rating
                            name={`rating-${yolculuk.id}`}
                            value={ratings[yolculuk.id] || null}
                            onChange={(event, newValue) => handleRatingChange(yolculuk.id, newValue)} // Her yiyecek için rating güncelleme
                            max={5}
                          />
                          <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Description&nbsp;&nbsp;
                                <SoftTypography variant="caption" fontWeight="regular" color="text">
                                  (optional)
                                </SoftTypography>
                              </SoftTypography>
                            </SoftBox>
                            <FormField
                              type="text"
                              placeholder="Experience about flavor"
                              multiline
                              rows={5}
                              value={comments[yolculuk.id] || ""} // Her yiyecek için comment güncelleme
                              onChange={(e) => handleCommentChange(yolculuk.id, e.target.value)}
                            />
                          </SoftBox>
                        </SoftBox>
                        <Divider />
                      </div>
                    </Card>
                  ))}
                </SoftBox>



                <Divider />
                {/* Restoranın genel yorumu */}
                <SoftTypography mt={6} variant="h5">General Comment</SoftTypography>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  {/* <SoftTypography component="label" variant="caption" fontWeight="bold">
                      General Comment
                    </SoftTypography> */}
                </SoftBox>
                <Card ml={3} sx={{ paddingInline: "20px", marginTop: "-20px", paddingBottom: "10px", marginInline: "-20px", marginBottom: "30px" }}>

                  <Grid item xs={12} sm={12} pt={0}>

                    {/* <FormField
                      type="text"
                      placeholder="Write a general comment about the restaurant"
                      multiline
                      rows={5}
                      value={generalComment}
                      onChange={(e) => setGeneralComment(e.target.value)} // Restoran genel yorum state güncelleme
                    /> */}

                    <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Description&nbsp;&nbsp;
                          <SoftTypography variant="caption" fontWeight="regular" color="text">
                            (optional)
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                      <FormField
                        type="text"
                        placeholder="Experience about flavor"
                        multiline
                        rows={5}
                        value={generalComment}
                        onChange={(e) => setGeneralComment(e.target.value)}
                      />
                    </SoftBox>
                  </Grid>
                </Card>

                <Grid item xs={12} sm={12} pt={3}>
                  <SoftBox display="flex" justifyContent="center" pt={3}>
                    <SoftButton variant="gradient" color={loading ? "warning": "warning"} onClick={handleSubmit} sx={{ maxWidth: "400px" }} disabled={loading} fullWidth>
                      <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }} fontSize={expandFood ? "small" : "medium"} color="dark">
                        {"save"}
                      </Icon>
                      {loading ? "Saving" : "Save"}
                    </SoftButton>
                  </SoftBox>
                </Grid>


              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
