

/* eslint-disable react/prop-types */
import { useState, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Bileşenler
import ProductInfo from "layouts/place/components/new-rate/components/ProductInfo";
import AddList from "layouts/place/components/new-rate/components/AddList";

function NewProduct({ data }) {
  const [activeStep, setActiveStep] = useState(0);

  // Adımları dinamik olarak belirle
  const steps = useMemo(() => {
    const stepList = ["1. Description"];
    if (!data.result.foodie_tags) {
      stepList.push("2. Media");
    }
    return stepList;
  }, [data.result.foodie_tags]);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    if (!isLastStep) {
      setActiveStep((prev) => prev + 1);
    } else {
      // Son adımda farklı bir sayfaya yönlendirme yapılabilir.
      window.location.href = "/next-page"; // Yeni sayfa yönlendirme
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <ProductInfo data={data} onComplete={handleNext} />;
      case 1:
        return <AddList onComplete={handleNext} />;
      default:
        return null;
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={8}>
        <Card sx={{ overflow: "visible", background: "transparent" }}>
          <SoftBox p={2}>
            <SoftBox>{getStepContent(activeStep)}</SoftBox>
            <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
              {activeStep > 0 && (
                <>
                  <SoftButton variant="outlined" color="secondary" onClick={handleBack}>
                    Back
                  </SoftButton>
                  <SoftButton
                    variant="gradient"
                    color="dark"
                    onClick={handleNext}
                  >
                    {isLastStep ? "Finish" : "Next"}
                  </SoftButton>
                </>
              )}
            </SoftBox>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NewProduct;
