import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FoodItem from './FoodItem2';
import SoftBox from "components/SoftBox";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Grid from "@mui/material/Grid";
import './Content.css';
import axios from "axios";
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */

const Content = ({ searchQuery, searchSelect }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (searchQuery) {
                    const response = await axios.get(`${window.BASE_URL}/search?query=${searchQuery}`, { withCredentials: true });
                    setData(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [searchQuery]);

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                if (searchQuery) {
                    const response = await axios.get(`${window.BASE_URL}/nearby-category?format=json`, { withCredentials: true });
                    setCategoryData(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchCategoryData();
    }, [searchQuery]);

    const categoryIconMap = {
        Hamburger: "lunch_dining",
        Pizza: "local_pizza",
        Healthy: "spa",
        World: "restaurant_menu",
        Dessert: "icecream",
        Cocktail: "local_bar",
        "Ice Cream": "icecream",
        Salad: "spa",
        Coffee: "local_cafe",
        Sushi: "set_meal",
        Bread: "bakery_dining",
        Tofu: "spa",
        Steak: "restaurant_menu",
        Pasta: "local_pizza",
        Noodles: "ramen_dining"
    };
    
    const softBoxItemsTemplate = [
        { title: "Foods Near", count: "Hamburger", icon: { color: "warning", component: "lunch_dining" } },
        { title: "Drinks Nearby", count: "Cocktail", icon: { color: "warning", component: "local_bar" } },
        { title: "Desserts", count: "Ice Cream", icon: { color: "warning", component: "icecream" } },
        { title: "Fast Foods", count: "Pizza", icon: { color: "warning", component: "local_pizza" } },
        { title: "Healthy Options", count: "Salad", icon: { color: "warning", component: "spa" } },
        { title: "Coffee Shops", count: "Coffee", icon: { color: "warning", component: "local_cafe" } },
        { title: "Seafood", count: "Sushi", icon: { color: "warning", component: "set_meal" } },
        { title: "Bakeries", count: "Bread", icon: { color: "warning", component: "bakery_dining" } },
        { title: "Vegan Options", count: "Tofu", icon: { color: "warning", component: "spa" } },
        { title: "Steakhouses", count: "Steak", icon: { color: "warning", component: "restaurant_menu" } },
        { title: "Italian", count: "Pasta", icon: { color: "warning", component: "local_pizza" } },
        { title: "Asian Cuisine", count: "Noodles", icon: { color: "warning", component: "ramen_dining" } }
    ];

    const transformData = (data) => {
        if (!data || !data.subcategories) return []; // Eğer data veya data.categories yoksa boş bir array döndür
    
        return Object.keys(data.subcategories).map(category => {
            const count = data.subcategories[category] || 0;
            const iconComponent = categoryIconMap[category] || "local_dining"; // İkon haritasından ikon seç
            const templateItem = softBoxItemsTemplate.find(item => item.count === category);
    
            if (templateItem) {
                return {
                    ...templateItem,
                    title: `${count} ${templateItem.title}`,
                    count: category,
                    icon: { color: "dark", component: iconComponent }
                };
            } else {
                return {
                    title: `${count} Foods Near`,
                    count: category,
                    icon: { color: "dark", component: iconComponent }
                };
            }
        });
    };
    
    const softBoxItems = transformData(categoryData);

    const data_item = data?.results?.map(item => ({
        name: item.name,
        address: item.address,
        rating: item.rating,
        in_list_count: item.in_list_count,
        place_id: item.place_id,
        food_type: item.food_type
    }));

    return (
        <>
            <div className="content">
                <p className="menu-title">{searchQuery != "" ? t("search.content.title.results") : t("search.content.title.suggestions")}</p>
            </div>

            <Grid container spacing={2} pb={3} sx={{
                paddingX: {
                    xs: 0,  // Küçük ekranlar için
                    sm: 3,  // Orta ekranlar için
                    md: 6,  // Büyük ekranlar için
                    lg: 8,  // Daha büyük ekranlar için
                    xl: 10  // En büyük ekranlar için
                }
            }}>
                {searchQuery && searchQuery.length > 0 ? (
                    searchSelect === "Top" || searchSelect === "Place" ? (
                        data_item?.map((item, idx) => (
                            <FoodItem
                                key={idx}
                                imageUrl={item.name}
                                foodName={item.name}
                                restaurantName={item.address}
                                rating={item.rating}
                                inListCount={item.in_list_count}
                                placeId={item.place_id}
                                placeType={item.food_type}
                                style={{ marginBottom: "100px" }}
                                
                            />
                        ))
                    ) : null
                ) : (
                    <Grid container spacing={2} p={3} pr={0}>
                        {softBoxItems.map((item, idx) => (
                            <Grid item xs={12} md={6} lg={4} key={idx}>
                                <SoftBox key={idx} mb={1}>
                                <a href={`/category/${encodeURIComponent(item.count)}`} style={{ textDecoration: 'none' }}>
                                    <MiniStatisticsCard
                                        bgColor="white"
                                        title={{ fontWeight: "medium", text: item.title }}
                                        count={item.count}
                                        icon={{ color: item.icon.color, component: item.icon.component }}
                                        direction="left"
                                    />
                                    </a>
                                </SoftBox>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default Content;
