/* eslint-disable react/prop-types */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Images
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
//import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
//import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

function ComplexStatisticsCard({ color, icon, count, percentage, dropdown, boxSize, link , isLoading }) {
  const { size } = typography;
  const navigate = useNavigate();

  return (
<Card
  sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
    background: {
      xs: "#ffffff00",     
      sm: "#fff",      
    },
    backgroundImage: `url(${whiteCurved})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  })}
>

      <SoftBox p={2}>
        <Grid container sx={{justifyContent:"center",flexDirection:{xs: "row",sm: "row"}, cursor: link ? "pointer": "auto" }} onClick={() => navigate(link)} >
          <Grid item xs={8} sx={{display:"flex", justifyContent:"space-evenly", alignItems: "center", flexDirection:{xs: "column",sm: "row"} }}>
            <SoftBox
              width="3.5rem"
              minWidth="3.5rem"
              height="3.5rem"
              bgColor="white"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={color}
              shadow="md"
              fontSize="1.25rem"
              sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
                background: gradients[color]
                  ? `${linearGradient(
                      rgba(gradients[color].main, 0.9),
                      rgba(gradients[color].state, 0.9)
                    )}, url(${whiteCurved})`
                  : `${linearGradient(
                      rgba(gradients.dark.main, 0.9),
                      rgba(gradients.dark.state, 0.9)
                    )}, url(${whiteCurved})`,
              })}
            >
              <Icon
                sx={{
                  fontSize: "1.25rem !important",
                  backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
                    gradients[color]
                      ? linearGradient("white", "white")
                      : linearGradient("white", "white"),
                    
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  
                }}
              >
                {icon}
              </Icon>
            </SoftBox>
            <SoftBox mt={2} lineHeight={0} display={{ xs: "unset", sm: "unset" }} sx={{marginLeft: { xs: "0px", sm: "15px" }, width: { xs: "160%",sm: "auto" }, textAlign: { xs: "center",sm: "inherit" } }}>
              <SoftTypography display={{ xs: "inherit", sm: "none" }} variant="button" fontWeight="medium" color="#434343" isLoading={isLoading}>
                {count.number}
              </SoftTypography>
              <SoftTypography display={{ xs: "none", sm: "inherit" }} variant="h5" fontWeight="bold" color="#434343" isLoading={isLoading}>
                {count.number}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                sx={{fontSize: {xs: "11px",sm: "14px"} , color: { xs: "#656464 !important" , sm: "#70747a" }}}
                color={{ xs: "#7b7c7d !important" , sm: "#70747a" }}
                textTransform="capitalize"
                display={{ xs: "unset", sm: "unset" }}
                isLoading={isLoading}
              >
                {count.label}
              </SoftTypography>
            </SoftBox> 
          </Grid>
          <Grid item xs={4} display={{ xs: "none", sm: "unset" }}>
            <SoftBox textAlign="right">
              {dropdown && (
                <SoftBox mb={7} lineHeight={1} color="#dddada">
                  <Icon fontSize="default" onClick={dropdown.action} sx={{ cursor: "pointer" }}>
                    more_horiz
                  </Icon>
                  {dropdown.menu}
                </SoftBox>
              )}
              <SoftTypography variant="button" fontWeight="bold" color="#434343" align="right">
                {percentage}
              </SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card >
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "dark",
  dropdown: false,
  boxSize: "1",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  count: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  percentage: PropTypes.string.isRequired,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexStatisticsCard;
