

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHeart, faHouse, faIdCard, faMessage, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'


/* eslint-disable react/prop-types */

// Custom styles for ComplexProjectCard
function ComplexProjectCard({ color, image, title, dateTime, description, members, owner, dropdown, ratingScore, raterCount }) {
  const renderMembers = members.map((member, key) => {
    const memberKey = `member-${key}`;

    return (
       owner ? 
      <SoftAvatar
        key={memberKey}
        icon="place"
        size="xs"
        bgColor="flatwhite"
        style={{ fontSize: "10px !important" }}
        iconSize="16px"
      /> : ""
    
    );
  });

  return (
    <Card style={{ overflow: "hidden", marginTop: "30px" }} >
      <SoftBox p={2}>
        <SoftBox style={{ margin: "-10px", background: "rgb(239 241 244)", marginBottom: "10px", height: "120px", borderRadius: "0.5rem" }}>

        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, position: "absolute", filter: "blur(15px)", marginTop: "-40px" }}
          />
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={"flatwhite"}
            sx={{ p: 1, marginTop: "-40px" }}
          />

          <SoftBox ml={2} lineHeight={0}>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium">
                {title}
              </SoftTypography>
            </SoftBox>
            {members.length > -1 ? <SoftBox display="flex" style={{marginTop:"-10px"}}>{renderMembers}
              <SoftTypography variant="button" fontWeight="regular" fontSize="10px" alignContent="center" marginLeft="3px">

                {owner}
              </SoftTypography>
            </SoftBox> : null}

          </SoftBox>
          {dropdown && (
            <SoftTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </SoftTypography>
          )}
          {dropdown.menu}
        </SoftBox>
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        <Divider />

        <SoftBox display="flex" justifyContent="space-between" alignItems="center" style={{ padding: "30px", paddingBlock: "20px", background: "#f8f9fa", borderRadius: "1rem" }}>


        
            <SoftBox display="flex" flexDirection="row" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">
                {raterCount}
              </SoftTypography>
              {title ? <SoftTypography variant="button" fontWeight="medium" color="secondary" style={{ marginLeft: "5px" }}>
                Total Rating
              </SoftTypography> : ""}
            </SoftBox>
          
            <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
              <SoftTypography variant="button" fontWeight="medium">
                {ratingScore}
              </SoftTypography>
              <SoftBox display="flex" alignItems="center" justifyContent="center" style={{ width: "35px", height: "35px", marginRight: "-10px", marginLeft: "10px", background: "white", borderRadius: "1rem" }}>
                <FontAwesomeIcon icon={faSplotch} fontSize="1rem" color="#8392ab" />
              </SoftBox>
            </SoftBox>
         





        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
