/* eslint-disable react/prop-types */

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import bruceMars from "assets/images/bruce-mars.jpg";
import workSpace from "assets/images/work-space.jpeg";
//import foodIcon from "assets/images/logos/food-icon-white.png";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'


function Post({ data, type }) {
    const { borderWidth, borderColor } = borders;
    console.log("data")
    console.log(data)

    const [comments] = useState([
        {
            image: bruceMars,
            name: "michael lewis",
            text: "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves!",
            like: 3,
            share: 2,
        },
        {
            image: team5,
            name: "jessica stones",
            text: "Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy.",
            like: 10,
            share: 1,
        },
    ]);

    const renderComments = comments.map(({ image, name, text, like, share }, key) => (
        <SoftBox key={name} display="flex" mt={key === 0 ? 0 : 3}>
            <SoftBox flexShrink={0}>
                <SoftAvatar src={image} alt={name} />
            </SoftBox>
            <SoftBox flexGrow={1} ml={2}>
                <SoftTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                    {name}
                </SoftTypography>
                <SoftBox mt={1} mb={2} lineHeight={0.75}>
                    <SoftTypography variant="button" fontWeight="regular" color="text">
                        {text}
                    </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" flexWrap="wrap" alignItems="center">
                    <SoftBox display="flex" alignItems="center" mr={1}>
                        <SoftTypography component="a" href="#" variant="body2" color="text">
                            <Icon>thumb_up</Icon>&nbsp;
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            {like} likes
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                        <SoftTypography component="a" href="#" variant="body2" color="text">
                            <Icon>share</Icon>&nbsp;
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="regular" color="text">
                            {share} shares
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </SoftBox>
    ));

    const conditionPlace = data?.type == "CB" ? true : false;


    return (
        <Card style={{ overflow: "visible", marginTop: type != "place" ? ( "50px") : ( "20px") }}>
            <SoftBox p={3} paddingTop="5px" style={{  position: "relative" }}>
                { ""}
                <SoftBox
                    display="flex"
                    //justifyContent="space-between"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexDirection={{ xs: "row", sm: "row" }}
                    // borderBottom={`${borderWidth[1]} solid ${borderColor}`}
                    py={2}
                    px={0}
                    pt={4}

                >
                    <SoftAvatar bgColor={conditionPlace ? "leaf" : "flatwhite"} src={type != "place" ? "" : (window.BASE_URL + "/" + data?.foodie?.foodie_profilephoto)} alt="profile-image" variant="rounded" size="lg" icon={conditionPlace ? "restaurant" : "route"} />
                    <SoftBox mx={2} lineHeight={1}>
                        <SoftTypography component="a" href="#" variant="h5" fontWeight="medium">
                            {data?.item?.type != "CB" ? data?.item?.name : data?.foodie?.full_name}
                        </SoftTypography>
                        <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                            {data?.created_at}
                        </SoftTypography>
                    </SoftBox>
                    {/* <SoftBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 6, sm: 0 }} style={{ position: "absolute", right: "20px" }}>
                        <SoftButton variant="gradient" color="leaf" size="small" style={{ paddingInline: "20px" }}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; follow
                        </SoftButton>
                    </SoftBox> */}
                </SoftBox>
                {conditionPlace ? <> <Divider />





                 <SoftBox display="flex" justifyContent="space-between" alignItems="center" style={{ padding: "20px", paddingBlock: "20px", background: "#f8f9fa", borderRadius: "1rem", margin: "15px 0px 10px" }}>

                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" lineHeight={0} textAlign="left">
                        <SoftTypography variant="button" fontWeight="medium">
                            
                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="medium" color="secondary" style={{ marginLeft: "5px" }}>
                            Total Rating Score
                        </SoftTypography>
                    </SoftBox>


                    <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
                        <SoftTypography variant="button" fontWeight="medium">
                            {data?.rating} / 10
                        </SoftTypography>
                        <SoftBox display="flex" alignItems="center" justifyContent="center" style={{ width: "35px", height: "35px", marginRight: "-10px", marginLeft: "10px", background: "white", borderRadius: "1rem" }}>
                            <FontAwesomeIcon icon={faSplotch} fontSize="1rem" color="#8392ab" />
                        </SoftBox>
                    </SoftBox>

                </SoftBox> </>: "" }










                 <SoftBox mt={3} mb={1} px={1}>



                    <Divider />



                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection="column" gap={1} px={1} pt={2}>



                    <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                            {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Route
                            </SoftTypography> */}

                            <SoftAvatar
                                key={"memberKey"}
                                icon="place"
                                bgColor="flatlight"
                                variant="rounded"
                                size="md"
                                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                    border: `${borderWidth[2]} solid ${white.main}`,
                                    cursor: "pointer",
                                    position: "relative",
                            
                                })}
                                style={{ marginRight: "4px", marginTop: "-2px", fontSize:"20px" }}

                            />

                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                            <span style={{ color: "rgb(101 108 121)" }}>{data?.item?.address}  </span>  100m
                            </SoftTypography>
                        </SoftBox>

                        {conditionPlace ?<SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                            {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Flavor
                            </SoftTypography> */}

                            <SoftAvatar
                                key={"memberKey"}
                                icon="local_dining"
                                bgColor="flatwhite"
                                variant="rounded"
                                size="md"
                                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                    border: `${borderWidth[2]} solid ${white.main}`,
                                    cursor: "pointer",
                                    position: "relative",
                                })}
                                style={{ marginRight: "4px", marginTop: "-2px", fontSize:"20px" }}

                            />

                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                            <span style={{ color: "rgb(101 108 121)" }}>{data?.foodie_foodreview_count}   </span>  Flavor  Rating
                            </SoftTypography>
                        </SoftBox> : ""}






                        


                    </SoftBox>


                    {/* <Divider />
                    {renderComments}
                    <SoftBox display="flex" alignItems="center" mt={3}>
                        <SoftBox flexShrink={0} mr={2}>
                            <SoftAvatar src={team4} alt="profile picture" />
                        </SoftBox>
                        <SoftBox flexGrow={1}>
                            <SoftInput placeholder="Write your comment" multiline />
                        </SoftBox>
                    </SoftBox> */}
                </SoftBox> 
            </SoftBox>
        </Card>
    );
}

export default Post;
