import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import TableAcord from "examples/Tables/TableAcord";

import axios from 'axios';



// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";

function Tables() {



  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/disiplin?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);




  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
};

  let egitimRows = data.map(index => {
    return {
      "Şikayet Edilen": [
        <img key="logoNewspaper" src="" alt="Logo" />,
        <span key="task1" style={truncatedTextStyle}> {index.sikayet_edilen.name} </span>
      ],
      "Dosya Adı": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.dosya_isim}</SoftTypography>,
      "Durum": <SoftBadge variant="gradient" badgeContent={index.durum} color="success" size="xs" container />,
      "Şikayetçi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.sikayet_eden.name}</SoftTypography>,
      "Sorumlu Kişi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.sorumlu_kisi.name}</SoftTypography>,
      "detail": <><SoftTypography variant="caption">Sorumlu Kişi: {index.sorumlu_kisi.name} ({index.sorumlu_kisi.trt_arabi_unvan}) <br />Dosya No: {index.dosya_isim} <br /> <SoftBadge variant="gradient" badgeContent={index.durum} color="default" size="xs" container /> <br /> <Icon sx={{ fontWeight: "bold", color: "error"}}>
      label_important
  </Icon>  Açıklama: {index.aciklama} <br />
      {index.sonuc === "null" ? "ChatBubble" : <><br /><SoftBadge variant="gradient" badgeContent="KARAR" color="error" size="xs" container /> <br /> {index.sonuc}  </>}
      </SoftTypography></>,
      
      //<SoftTypography variant="caption" color="text" fontWeight="medium">{index.aciklama}</SoftTypography>


    };
  });

  const projectsTableData = {
    columns: [
      { name: "Şikayet Edilen", align: "left" },
      { name: "Dosya Adı", align: "left" },
      { name: "Durum", align: "left" },
      { name: "Şikayetçi", align: "center" },
      { name: "Sorumlu Kişi", align: "center" },
    ],

    rows: [
      ...egitimRows,

    ],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Disiplin Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <TableAcord columns={projectsTableData.columns} rows={projectsTableData.rows} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
