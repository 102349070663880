import React, { useEffect, useState } from 'react';
import TopBar from './TopBar';
import Content from './Content';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
/* eslint-disable react/prop-types */

const App = () => {


    const [searchQuery, setsearchQuery] = useState([]);
    const [searchSelect, setsearchSelect] = useState('Top');

    const handleDataUpdate = (newData) => {
        setsearchQuery(newData);
    };

    const handleSelectDataUpdate = (newData) => {
        setsearchSelect(newData);
    };

    return (
        <DashboardLayout>
            <TopBar onDataUpdate={handleDataUpdate} onSelectDataUpdate={handleSelectDataUpdate} />
            <Content searchQuery={searchQuery} searchSelect={searchSelect} />
        </DashboardLayout>
    );
};

export default App;
