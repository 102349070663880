/* eslint-disable react/prop-types */

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard PRO React base styles
import borders from "assets/theme/base/borders";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import bruceMars from "assets/images/bruce-mars.jpg";
import workSpace from "assets/images/work-space.jpeg";
import foodIcon from "assets/images/logos/food-icon.png";
import storyIcon from "assets/images/logos/food-icon-white.png";
import { useNavigate } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

function getField(food, field) {
    return food.custom_restaurant?.[field] || food.restaurant?.[field] || "No " + field;
}



function Post({ data, type }) {

    const navigate = useNavigate(); // Yönlendirme için useNavigate hook'u

const handleNavigate = (id) => {
    if (id) {
        navigate(`/place/${id}`); // ID ile belirtilen sayfaya yönlendir
    } else {
        console.error("ID is not valid"); // Hata durumunda log
    }
};

    return (
        <Card style={{ overflow: "visible", marginTop: type != "place" ? (data?.story_count > 0 ? "100px" : "50px") : (data?.story_count > 0 ? "70px" : "20px") }}>
            <SoftBox p={3} paddingTop="5px" style={{ position: "relative" }}>
                {data?.story_count > 0 ? <SoftBox style={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                    <SoftBox component="img" src={data?.cover ? window.BASE_URL + "/" + data?.cover : storyIcon} shadow="lg" borderRadius="xl" width="100%" maxHeight="400px" maxWidth="280px"
                        style={{ aspectRatio: "0.7 / 1", objectFit: "cover", objectPosition: "center", marginTop: "-70px", position: "relative", zIndex: 2, backgroundColor: "rgb(234 236 239)" }} />
                    <SoftBox component="img" src={data?.cover ? window.BASE_URL + "/" + data?.cover : storyIcon} shadow="lg" borderRadius="xl" width="100%" maxHeight="400px" maxWidth="280px"
                        style={{ aspectRatio: "0.7 / 1", objectFit: "cover", objectPosition: "center", marginTop: "-70px", filter: "blur(15px)", position: "absolute", opacity: "0.7", top: "10px", zIndex: 1 }} />


                    <SoftAvatar
                        key={"memberKey"}
                        icon="play_arrow"
                        bgColor={1 > 0 ? "leaf" : "flatwhite"}
                        size="lg"
                        text={data?.story_count + ""}
                        style={{ marginRight: "4px", marginTop: "-2px", position: "absolute", zIndex: 2, fontSize: "30px", right: "calc(50% - 150px)", marginBottom: "10px", boxShadow: 1 > 0 ? "0 8px 18px #ff79007a, 0 0 0 3px #ffffff80 , 0 0 0 0px #dddbdb78" : "", filter: "drop-shadow(2px 4px 6px #00000010)" }}

                    />


                </SoftBox> : ""}
                <SoftBox
                    display="flex"
                    //justifyContent="space-between"
                    alignItems="center"
                    justifyContent="flex-start"
                    flexDirection={{ xs: "row", sm: "row" }}
                    // borderBottom={`${borderWidth[1]} solid ${borderColor}`}
                    py={2}
                    px={0}
                    pt={4}

                >
                    <SoftAvatar bgColor="light" src={type != "place" ? foodIcon : (window.BASE_URL + "/" + data?.foodie?.foodie_profilephoto)} alt="profile-image" variant="rounded" size="lg" />
                    <SoftBox onClick={() => handleNavigate(getField(data, "google_id"))} mx={2} lineHeight={1}>
                        <SoftTypography component="a" variant="h5" fontWeight="medium">
                            {type != "place" ? getField(data, "name") : data?.foodie?.full_name}
                        </SoftTypography>
                        <SoftTypography component="div" variant="button" color="text" fontWeight="regular">
                            {data?.created_at ? data?.created_at : getField(data, "address")}
                        </SoftTypography>
                    </SoftBox>
                    {/* <SoftBox ml={{ xs: 0, sm: "auto" }} mt={{ xs: 6, sm: 0 }} style={{ position: "absolute", right: "20px" }}>
                        <SoftButton variant="gradient" color="leaf" size="small" style={{ paddingInline: "20px" }}>
                            <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; follow
                        </SoftButton>
                    </SoftBox> */}
                </SoftBox>
                <Divider style={{ display: data?.rating ? "flex" : "flex" }} />





                <SoftBox display={data?.rating ? "flex" : "none"} justifyContent="space-between" alignItems="center" style={{ padding: "20px", paddingBlock: "20px", background: "#f8f9fa", borderRadius: "1rem", margin: "15px 0px 10px" }}>

                    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" lineHeight={0} textAlign="left">
                        <SoftTypography variant="button" fontWeight="medium">

                        </SoftTypography>
                        <SoftTypography variant="button" fontWeight="medium" color="secondary" style={{ marginLeft: "5px" }}>
                            Total Rating Score
                        </SoftTypography>
                    </SoftBox>


                    <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
                        <SoftTypography variant="button" fontWeight="medium">
                            {data?.rating} / 10
                        </SoftTypography>
                        <SoftBox display="flex" alignItems="center" justifyContent="center" style={{ width: "35px", height: "35px", marginRight: "-10px", marginLeft: "10px", background: "white", borderRadius: "1rem" }}>
                            <FontAwesomeIcon icon={faSplotch} fontSize="1rem" color="#8392ab" />
                        </SoftBox>
                    </SoftBox>

                </SoftBox>





                {/* <Grid item xs={12} sm={6} >
                    <SoftBox
                        display={{ xs: "flex", sm: "flex" }}
                        justifyContent="flex-start"
                        alignItems="center"
                        width="100%"
                        paddingInline="15px"
                    >
                        <SoftAvatar src={team5} alt="person 1" size="xs" />
                        <SoftAvatar src={team2} alt="person 2" size="xs" />
                        <SoftAvatar src={team1} alt="person 3" size="xs" />
                        <SoftBox pl={1}>
                            <SoftTypography variant="button" color="text" fontWeight="medium">
                                and 30+ more
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </Grid> */}

                <SoftBox mb={3} paddingTop="5px" paddingInline="20px" lineHeight="1.3">
                    <SoftTypography component="a" variant="button" color="text" fontWeight="regular" >
                        {data?.comment}
                    </SoftTypography>
                </SoftBox>



                <SoftBox mt={3} mb={1} px={1}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftBox display="flex" alignItems="center" mr={2}>
                                    <SoftTypography component="a" variant="body2" color="text">
                                        <Icon fontSize="20px">favorite_border</Icon>&nbsp;
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        {data?.like_count > 0 ? data?.like_count : ""}
                                    </SoftTypography>
                                </SoftBox>
                                <SoftBox display="flex" alignItems="center" mr={2}>
                                    <SoftTypography component="a" variant="body2" color="text">
                                        <Icon >mode_comment</Icon>&nbsp;
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        {data?.comment_count > 0 ? data?.comment_count : ""}
                                    </SoftTypography>
                                </SoftBox>
                                {/* <SoftBox display="flex" alignItems="center" mr={2}>
                                    <SoftTypography component="a" href="#" variant="body2" color="text">
                                        <Icon >share</Icon>&nbsp;
                                    </SoftTypography>
                                    <SoftTypography variant="button" fontWeight="regular" color="text">
                                        12
                                    </SoftTypography>
                                </SoftBox> */}
                            </SoftBox>
                        </Grid>

                    </Grid>


                    <Divider style={{ display: data?.foodie_foodreview_count ? "flex" : "none" }} />



                    <SoftBox display={data?.foodie_foodreview_count ? "flex" : "none"} justifyContent="space-between" alignItems="flex-start" flexDirection="column" gap={1} px={1} pt={2}>

                        <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                            {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Flavor
                            </SoftTypography> */}

                            <SoftAvatar
                                key={"memberKey"}
                                icon="local_dining"
                                bgColor="flatwhite"
                                variant="rounded"
                                size="md"
                                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                    border: `${borderWidth[2]} solid ${white.main}`,
                                    cursor: "pointer",
                                    position: "relative",
                                })}
                                style={{ marginRight: "4px", marginTop: "-2px", fontSize: "20px" }}

                            />

                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                <span style={{ color: "rgb(101 108 121)" }}>{data?.foodie_foodreview_count}   </span>  Flavor  Rating
                            </SoftTypography>
                        </SoftBox>






                        {data?.routelist_count > 0 ? <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center" >
                            {/* <SoftTypography variant="button" fontWeight="medium" ml={0.5} mr={1.5}>
                                Route
                            </SoftTypography> */}

                            <SoftAvatar
                                key={"memberKey"}
                                icon="route"
                                bgColor="flatwhite"
                                variant="rounded"
                                size="md"
                                sx={({ borders: { borderWidth }, palette: { white } }) => ({
                                    border: `${borderWidth[2]} solid ${white.main}`,
                                    cursor: "pointer",
                                    position: "relative",

                                })}
                                style={{ marginRight: "4px", marginTop: "-2px", fontSize: "20px" }}

                            />

                            <SoftTypography variant="button" fontWeight="medium" color="secondary">
                                <span style={{ color: "rgb(101 108 121)" }}>{data?.routelist_count}  </span>  Route Contains
                            </SoftTypography>
                        </SoftBox> : ""}


                    </SoftBox>


                    {/* <Divider />
                    {renderComments}
                    <SoftBox display="flex" alignItems="center" mt={3}>
                        <SoftBox flexShrink={0} mr={2}>
                            <SoftAvatar src={team4} alt="profile picture" />
                        </SoftBox>
                        <SoftBox flexGrow={1}>
                            <SoftInput placeholder="Write your comment" multiline />
                        </SoftBox>
                    </SoftBox> */}
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default Post;
