import React, { useEffect, useRef, useState } from "react";
import './AnimationStyles.css';
import PageLayout from "examples/LayoutContainers/PageLayout";

import axios from "axios";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";



const AnimationPage = () => {

  const handleInputChange = (e, nextInputId, pastInputId) => {
    if (e.type === "change") {
      // Değer değişikliği işlemleri
      let { value } = e.target;
      value = value.slice(-1); // İlk karakteri al
      e.target.value = value; // Yeni değeri güncelle
  
      if (value.length === 1 && nextInputId) {
        // Bir sonraki input'a odaklan
        const nextInput = document.getElementById(nextInputId);
        nextInput?.focus();
      }
    } else if (e.type === "keydown" && e.key === "Backspace" && e.target.value.length === 0 && pastInputId) {
      // Backspace ve input boşken bir önceki input'a odaklan
      const pastInput = document.getElementById(pastInputId);
      pastInput?.focus();
    }
  };
  

  

  const handleVerify = async () => {
    try {
      // passcode'u oluşturmak için her bir input'un değerini al
      let passcode = '';
      for (let i = 1; i <= 6; i++) {
        const inputVal = document.getElementById(`pass${i}`).value;
        passcode += inputVal;
      }
  
      // API isteğini passcode ile yap
      const loginResponse = await axios.post(`${window.BASE_URL}/api/verify-tfa`, {
        otp: passcode
      }, { withCredentials: true });
  
      // Başarılı istek sonrası işlemler...
      //console.log('Verify success:', loginResponse.data);
      window.location.href = '/kurum/anasayfa'
  
    } catch (e) {
      console.log('Verify error:', e.message);
      alert("Lütfen Tekrar Deneyiniz!");
    }
  }
  

  const handleClick = async () => {
    try {
      //const loginResponse = await axios.post(`${window.BASE_URL}/api/resend-tfa`, {}, { withCredentials: true });
      const response = await axios.get(`${window.BASE_URL}/api/resend-tfa`, { withCredentials: true });
      alert("Kod Yeniden Gönderildi!");
    
    } catch (e) {
      console.log('Resend error:', e.message);
      alert("Lütfen Tekrar Deneyiniz!");
    }
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/api/check-tfa?format=json`, { withCredentials: true });
        setData(response.data);
        console.log(response);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);

  if (data && data.status == 0){
    
    window.location.href = '/'
  }


  return (
    <>
      <PageLayout>
        <div className="animation-container">
          <div className="shape1"></div>
          <div className="shape2"></div>
          <div className="shape3"></div>
          <div className="shape4"></div>
          <div className="shape5"></div>
          <div className="shape6"></div>

        </div>
        <Grid container justifyContent="center">
          <Grid item  justifyContent="center">
            <Card className="h-100" sx={{ mt: '5%', marginInline: { xs: 6, md: 30, xl: 60 }, maxWidth: "500px" }} >
              <SoftBox
                pt={3}
              >
                <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center", padding: "20px", paddingBlock:"60px" }}>
                  <Grid item  >
                    <SoftBox
                      variant="gradient"
                      bgColor={"info"}
                      color={"white"}
                      width="5rem"
                      height="5rem"
                      borderRadius="md"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      shadow="md"
                    >
                      <Icon fontSize="large" color="inherit">
                        {"lockopen"}
                      </Icon>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} md={12} xl={12} >
                    <SoftTypography variant="h4" color="dark" fontWeight="bold">
                      {"2 Adımlı Doğrulama"}
                    </SoftTypography>
                  </Grid>
                  <Grid item
                    display="flex"
                    gap="10px"
                    marginInline="50px"
                    justifyContent= 'center'
                    alignItems= 'center'

                    maxWidth= "500px"
                    
                  >

                    <SoftInput id="pass1" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, 'pass2','')} onKeyDown={(e) => handleInputChange(e, 'pass2', '')} />
                    <SoftInput id="pass2" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, 'pass3','pass1')} onKeyDown={(e) => handleInputChange(e, 'pass3','pass1')} />
                    <SoftInput id="pass3" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, 'pass4','pass2')} onKeyDown={(e) => handleInputChange(e, 'pass4','pass2')} />
                    <SoftInput id="pass4" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, 'pass5','pass3')} onKeyDown={(e) => handleInputChange(e, 'pass5','pass3')} />
                    <SoftInput id="pass5" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, 'pass6','pass4')} onKeyDown={(e) => handleInputChange(e, 'pass6','pass4')} />
                    <SoftInput id="pass6" type="text" sx={{ minWidth: "40px" }} onChange={(e) => handleInputChange(e, '','pass5')} onKeyDown={(e) => handleInputChange(e, '','pass5')} />
                  </Grid>

                  <Grid item xs={12} md={12} xl={12} >
                    <SoftButton sx={{width:"70%"}} onClick={() => handleVerify()} variant="gradient" color="info" >

                      doğrula
                    </SoftButton>

                  </Grid>

                  {/* <Grid item xs={12} md={12} xl={12} >
                    <SoftButton sx={{width:"50%"}} onClick={() => handleVerify()} variant="transparent" color="white" >

                      Kodu Yeniden Gönder
                    </SoftButton>
                  </Grid> */}

                  <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Kodu Yeniden Almak İçin {" "}
            <SoftTypography
              component={Link}
              to="/support"
              onClick={handleClick}
              variant="button"
              color="info"
              cursor="pointer"
              fontWeight="medium"
              textGradient
            >
              Tıklayın
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
                  

                </Grid>
              </SoftBox>
              
            </Card>
          </Grid>
        </Grid>
      </PageLayout>



    </>
  );
}

export default AnimationPage;
