// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.food-item {
    height: 70px;
    background: white;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
   
}

.food-image {

    border-radius: 10px;
    margin-left: 15px;
    background-size: cover;
}

.food-details {
    margin-left: 15px;
    flex-grow: 1;
}

.food-name {
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
}

.restaurant-name, .food-reviews {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 11px;
}

.restaurant-name {
    color: chocolate;
    color: #878787;
}

.food-reviews {
    color: #7D7D7D;
    font-size: 12px;
}

.food-rating {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.rating-value {
    margin-right: 10px;
    font-weight: bold;
    font-size: 0.9rem;
}

.rating-icon {
    min-width: 40px;
    min-height: 40px;
    background-color: #e1e1e1;
    border-radius: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rating-icon i {
    color: #3b3b3b;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/search/Content/FoodItem2/FoodItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;;AAEpB;;AAEA;;IAEI,mBAAmB;IACnB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".food-item {\n    height: 70px;\n    background: white;\n    width: 100%;\n    border-radius: 10px;\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n   \n}\n\n.food-image {\n\n    border-radius: 10px;\n    margin-left: 15px;\n    background-size: cover;\n}\n\n.food-details {\n    margin-left: 15px;\n    flex-grow: 1;\n}\n\n.food-name {\n    font-weight: bold;\n    color: black;\n    margin-bottom: 5px;\n}\n\n.restaurant-name, .food-reviews {\n    display: flex;\n    align-items: center;\n    margin-bottom: 5px;\n    font-size: 11px;\n}\n\n.restaurant-name {\n    color: chocolate;\n    color: #878787;\n}\n\n.food-reviews {\n    color: #7D7D7D;\n    font-size: 12px;\n}\n\n.food-rating {\n    display: flex;\n    align-items: center;\n    margin-right: 15px;\n}\n\n.rating-value {\n    margin-right: 10px;\n    font-weight: bold;\n    font-size: 0.9rem;\n}\n\n.rating-icon {\n    min-width: 40px;\n    min-height: 40px;\n    background-color: #e1e1e1;\n    border-radius: 40%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.rating-icon i {\n    color: #3b3b3b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
