import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faMagnifyingGlass, faSquarePlus, faMapLocation, faUser, faPlus } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [activeIcon, setActiveIcon] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleIconClick = (iconName, route) => {
    setActiveIcon(iconName);
    navigate(route);
  };

  return (
    <div
      style={{
        display: window.innerWidth < 1200 ? "flex" : "none",
        overflowY: "scroll",
        WebkitOverflowScrolling: "touch",
        paddingBottom: "25px",
        position: "fixed",
        height: "80px",
        width: "200%",
        bottom: "0px",
        background: "linear-gradient(180deg, #ffffffad, #ffffff26)",
        borderRadius: "18px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        maxWidth: "400px",
        gap: "40px",
        overflow: "visible",
        WebkitBackdropFilter: "blur(10px)",
        backdropFilter: "blur(10px)",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "1009090",
      }}
    >
      {/* Left Icons */}
      <div
        onClick={() => handleIconClick("explore", "/explore")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faCompass}
          style={{
            fontSize: "20px",
            color: location.pathname.startsWith("/explore") ? "#d35d1a" : "#434343",
          }}
        />
        {location.pathname.startsWith("/explore") && (
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              background: "#d35d1a",
              marginTop: "4px",
            }}
          ></div>
        )}
      </div>

      <div
        onClick={() => handleIconClick("search", "/search")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{
            fontSize: "20px",
            color: location.pathname.startsWith("/search") ? "#d35d1a" : "#434343",
          }}
        />
        {location.pathname.startsWith("/search") && (
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              background: "#d35d1a",
              marginTop: "4px",
            }}
          ></div>
        )}
      </div>

      {/* Center Plus Icon */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#ffffffb0",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          marginInline: "-10px",   
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          position: "relative",
          top: "-20px",
        }}
        onClick={() => handleIconClick("plus", "/create")}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background:
              location.pathname.startsWith("/create")
                ? "linear-gradient(310deg, rgb(249, 205, 0), rgb(255, 162, 0))"
                : "black",
            borderRadius: "50%",
            width: "45px",
            height: "45px",
            color: "white",
            fontSize: "20px",
          }}
        >
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: "24px", color: "white" }} />
        </div>
      </div>

      {/* Right Icons */}
      <div
        onClick={() => handleIconClick("map", "/map")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faMapLocation}
          style={{
            fontSize: "20px",
            color: location.pathname.startsWith("/map") ? "#d35d1a" : "#434343",
          }}
        />
        {location.pathname.startsWith("/map") && (
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              background: "#d35d1a",
              marginTop: "4px",
            }}
          ></div>
        )}
      </div>

      <div
        onClick={() => handleIconClick("foodie", "/foodie/:id")}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          icon={faUser}
          style={{
            fontSize: "20px",
            color: location.pathname.startsWith("/foodie") ? "#d35d1a" : "#434343",
          }}
        />
        {location.pathname.startsWith("/foodie") && (
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              background: "#d35d1a",
              marginTop: "4px",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
