/* eslint-disable react/prop-types */

function SearchBar({ searchTerm, handleChange, handleSearch, style }) {
  return (
    <div
      id="search"
      className="search-bar-container"
      style={{
        boxShadow: '0 20px 27px 0 rgba(0,0,0,.05)',
        position: 'sticky',
        background: "#fff",
        marginTop: "15px",
        width: "100%",
        ...style // Ek stil özelliklerini buraya dahil ediyoruz
      }}
    >
      <div className="profile-text" style={{ marginLeft: '45px' }}>
        <input
          type="text"
          className="search-input"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
        />
      </div>
      <button
        className="search-button"
        style={{ marginLeft: '20px' }}
        onClick={handleSearch}
      >
        <i className="fas fa-search"></i>
      </button>
    </div>
  );
}

export default SearchBar;
