import React from 'react';

const MyComponent = () => {
    return (
        <div style={{
            marginTop: '45px',
            borderRadius: '20px',
            position: 'fixed',
            width: 'calc(100% - 48px)',
            height: '55px',
            border: 'black',
           
            color: 'white',
            fontSize: '18px',
            fontWeight: '500',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignContent: 'center',
            justifyContent: 'flex-start',
            alignItems: 'center',
            zIndex: 10
        }}>
            <button style={{
                position: 'absolute',
                width: '55px',
                height: '55px',
                background: '#29292929',
                border: 'transparent',
                color: '#FFF',
                fontSize: '24px',
                fontWeight: '600',
                left: '-1%',
                borderRadius: '20px',
                backdropFilter: 'blur(10px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <i className="fas fa-arrow-left" style={{ fontSize: 'large' }}></i>
            </button>
            <div className="profile-text" style={{
                overflow: 'hidden',
                marginLeft: '65px',
                width: '100%',
                outline: 'none',
                height: '100%',
                marginTop: '40px'
            }}>
                <input style={{
                    color: 'white',
                    fontSize: '13px',
                    textAlign: 'initial',
                    background: 'transparent',
                    border: 'aqua',
                    width: '100%',
                    outline: 'none'
                }} />
            </div>
            <button style={{
                position: 'absolute',
                width: '55px',
                height: '55px',
                background: '#29292929',
                border: 'transparent',
                color: '#FFF',
                fontSize: '24px',
                fontWeight: '600',
                right: '-1%',
                borderRadius: '20px',
                backdropFilter: 'blur(20px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <i className="fas fa-bars" style={{ fontSize: 'large' }}></i>
            </button>
        </div>
    );
};

export default MyComponent;
