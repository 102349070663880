import React, { useState } from "react";
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Rating from '@mui/material/Rating';
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";

// NewProduct page components
import FormField from "layouts/place/components/new-rate/components/FormField";

import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import BasicModal from 'layouts/place/components/new-rate/components/ProductInfo/modal';

import { useParams } from 'react-router-dom';

/* eslint-disable react/prop-types */

function ProductInfo({ data }) {

  const { id } = useParams(); // Restoran ID'sini alıyoruz

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [yolculuklar, setYolculuklar] = useState([]);
  const [availableFoods, setAvailableFoods] = useState(data?.result?.foods || []); // Gelen foods verisini burada saklıyoruz ve boş diziyle başlatıyoruz

  // Restoranın genel rating ve yorumu
  const [generalRating, setGeneralRating] = useState(null);  // Restoranın genel rating
  const [generalComment, setGeneralComment] = useState("");  // Restoranın genel yorumu

  const [formData, setFormData] = useState({}); // Form verilerini tutmak için state
  const [ratings, setRatings] = useState({}); // Her yiyecek için rating bilgisi
  const [comments, setComments] = useState({}); // Her yiyecek için comment bilgisi

  // Modal açma fonksiyonu
  const handleModalOpen = (index) => {
    setModalContent(index);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Yolculuk ekleme fonksiyonu
  const handleYolculukEkle = (foodName, index) => {
    const selectedFood = availableFoods.find((food) => food.name === foodName);
    if (selectedFood) {
      const newYolculuk = {
        id: yolculuklar.length + 1,
        food_id: selectedFood.id, // food_id ekliyoruz
        yolculuk_turu: foodName,
        image: selectedFood.food_photo,
        gidisDonus: false,
      };
      setYolculuklar([...yolculuklar, newYolculuk]);

      // Seçilen öğeyi options listesinden kaldır
      setAvailableFoods(availableFoods.filter((item) => item.name !== foodName));
    } else {
      handleModalOpen();
    }
  };

  // Modal Submit fonksiyonu
  const handleModalSubmit = (newFood) => {
    const newYolculuk = {
      id: yolculuklar.length + 1,
      food_id: newFood.id, // food_id ekliyoruz
      yolculuk_turu: newFood.name,
      image: newFood.food_photo,
      gidisDonus: false,
    };
    setYolculuklar([...yolculuklar, newYolculuk]);

    // Modalda girilen yiyeceği doğrudan seçeneklerden de ekleyelim
    handleYolculukEkle(newFood.name);
  };

  // Yolculuk silme fonksiyonu
  const handleYolculukSil = (id, foodName) => {
    setYolculuklar((prevState) => prevState.filter((yolculuk) => yolculuk.id !== id));

    // Silinen öğeyi options listesine geri ekle
    const removedFood = data?.result?.foods?.find((food) => food.name === foodName);
    if (removedFood) {
      setAvailableFoods([...availableFoods, removedFood]);
    }

    // Silinen yiyeceğin yorumlarını ve puanlarını temizleyelim
    setRatings((prevRatings) => {
      const updatedRatings = { ...prevRatings };
      delete updatedRatings[id]; // Silinen yiyeceğin rating bilgisini temizliyoruz
      return updatedRatings;
    });

    setComments((prevComments) => {
      const updatedComments = { ...prevComments };
      delete updatedComments[id]; // Silinen yiyeceğin comment bilgisini temizliyoruz
      return updatedComments;
    });
  };

  // Her yiyecek için rating güncelleme
  const handleRatingChange = (id, newValue) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [id]: newValue,
    }));
  };

  // Her yiyecek için comment güncelleme
  const handleCommentChange = (id, newComment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [id]: newComment,
    }));
  };

  // Axios ile backend'e veri gönderme fonksiyonu
  const handleSubmit = async () => {
    // Tüm yolculuklar için rating ve comment verilerini birleştiriyoruz
    const yolculukVerileri = yolculuklar.map((yolculuk) => ({
      ...yolculuk,
      rating: ratings[yolculuk.id] || null, // Eğer rating yoksa null olarak gönderir
      comment: comments[yolculuk.id] || "", // Eğer comment yoksa boş string gönderir
    }));

    // Tüm form verilerini güncelliyoruz, restoranın genel rating ve yorumunu ekliyoruz
    const updatedFormData = {
      ...formData,
      yolculuklar: yolculukVerileri,
      generalRating,    // Restoranın genel rating'i
      generalComment,   // Restoranın genel yorumu
      google_id: id  // Restoranın ID'si
    };

    try {
      const response = await axios.post(window.BASE_URL + '/review/create', updatedFormData);
      console.log('Data submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const NewTarget = ({ name, image }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="sm" icon="add" bgColor="dark" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
        </SoftBox>
      </SoftBox>
    } color="light" size="xl" container />
  );

  const Target = ({ name, image }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={window.BASE_URL + "/" + image} size="sm" icon="local_dining" bgColor="flatwhite" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
        </SoftBox>
      </SoftBox>
    } color="light" size="xl" container />
  );

  return (
    <SoftBox px={3} pt={2}>
      <BasicModal open={modalOpen} handleClose={handleModalClose} content={modalContent} handleModalSubmit={handleModalSubmit} />
      <SoftTypography variant="h5">Place Rating</SoftTypography>

      {/* Restoranın genel ratingi */}
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Overall Rating
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Rating
            sx={{ marginLeft: "30px" }}
            name="generalRating"
            value={generalRating}
            onChange={(event, newValue) => setGeneralRating(newValue)} // Restoran genel rating state güncelleme
            max={10}
          />
        </Grid>



        

        <SoftBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
              <SoftBox pt={2} pb={3} px={0}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                  <SoftBox display="flex" alignItems="center">
                    <SoftAvatar src="-" variant="rounded" icon="local_dining" bgColor={"dark"} />
                    <SoftBox ml={2}>
                      <SoftTypography variant="h5" fontWeight="medium">
                        Flavor Rating
                      </SoftTypography>
                      <SoftBox display="flex" alignItems="flex-end">
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          Tasting Details
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>

                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pt={3}
                    sx={{ width: "100%", maxWidth: "100%", display: "block" }}
                  >
                    <SoftSelect
                      defaultValue={[]}
                      options={[
                        { value: "create_new_flavor", label: <NewTarget name="Create New Flavor" image="" /> },
                        ...availableFoods.map((food) => ({
                          value: food.name,
                          label: <Target name={food.name} image={food.food_photo} />
                        }))
                      ]}
                      value=""
                      size="large"
                      fullWidth
                      onChange={(e) => handleYolculukEkle(e.value)}
                      sx={{ width: "100%", minWidth: "400px", maxWidth: "100%" }}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox pt={yolculuklar.length > 0 ? 6 : 2} pb={3} px={0}>
                  {yolculuklar.map((yolculuk) => (
                    <div key={yolculuk.id}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                        <SoftBox display="flex" alignItems="center">
                          <SoftAvatar src={window.BASE_URL + "/" + yolculuk.image} alt="logo" variant="rounded" icon="local_dining" bgColor="flatwhite" />
                          <SoftBox ml={2}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {yolculuk.yolculuk_turu}
                            </SoftTypography>
                            <SoftBox display="flex" alignItems="flex-end">
                              <SoftTypography variant="button" color="text" fontWeight="regular">
                                Flavor Detail
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center" justifyContent="flex-end">
                          <SoftAvatar size="sm" variant="rounded" icon="bookmark" bgColor="flatwhite" style={{ marginRight: "9px", cursor: "pointer" }} > delete </SoftAvatar>
                          <SoftAvatar size="sm" variant="rounded" icon="delete" bgColor="dark" style={{ cursor: "pointer" }} onClick={() => handleYolculukSil(yolculuk.id, yolculuk.yolculuk_turu)} > delete </SoftAvatar>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox ml={2} pl={0} pt={2} lineHeight={1}>
                        <Rating
                          name={`rating-${yolculuk.id}`}
                          value={ratings[yolculuk.id] || null}
                          onChange={(event, newValue) => handleRatingChange(yolculuk.id, newValue)} // Her yiyecek için rating güncelleme
                          max={10}
                        />
                        <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Description&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="regular" color="text">
                                (optional)
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                          <FormField
                            type="text"
                            placeholder="Experience about flavor"
                            multiline
                            rows={5}
                            value={comments[yolculuk.id] || ""} // Her yiyecek için comment güncelleme
                            onChange={(e) => handleCommentChange(yolculuk.id, e.target.value)}
                          />
                        </SoftBox>
                      </SoftBox>
                      <Divider />
                    </div>
                  ))}
                </SoftBox>

                {/* <Divider /> */}
                        {/* Restoranın genel yorumu */}
        <Grid item xs={12} sm={12} pt={3}>
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              General Comment
            </SoftTypography>
          </SoftBox>
          <FormField
            type="text"
            placeholder="Write a general comment about the restaurant"
            multiline
            rows={5}
            value={generalComment}
            onChange={(e) => setGeneralComment(e.target.value)} // Restoran genel yorum state güncelleme
          />
        </Grid>

                <Grid item xs={12} sm={12} pt={3}>
                  <SoftBox display="flex" justifyContent="center" pt={3}>
                    <SoftButton variant="gradient" color="primary" onClick={handleSubmit}>
                      Submit Data
                    </SoftButton>
                  </SoftBox>
                </Grid>


              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default ProductInfo;
