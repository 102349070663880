

import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarMinus, faChartLine, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faUserClock, faUserTag, faUtensils } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-trt.png";
import SoftBadge from "components/SoftBadge";
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */

function Header(props) {
  const { t } = useTranslation();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const { id, view } = useParams();

  const username = useSelector(state => state.auth.username);
  console.log(username)

  useEffect(() => {
    if (view === "starred") {
      handleSetTabValue(11);
    }
    if (view === "favorite") {
      handleSetTabValue(11);
    }
    if (view === "liked") {
      handleSetTabValue(11);
    }
    if (view === "hyped") {
      handleSetTabValue(11);
    }
    if (view === "eatlist") {
      handleSetTabValue(1);
    }
    if (view === "rating") {
      handleSetTabValue(2);
    }
  }, [view]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);



  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };

  const handleButtonClick = () => {
    window.location.href = `/follow/${id}`; 
  };

  const handleEditButtonClick = () => {
    window.location.href = `/settings`; 
  };

  const eatCount = props.data?.foodie?.[0]?.eatlist_item_count || 0;
  const guideCount = props.data?.foodie?.[0]?.guide_item_count || 0;

  let displayText = '';

  if (eatCount > 0 && guideCount > 0) {
    displayText = `${eatCount} Flavor • ${guideCount} Place Rating`;
  } else if (eatCount > 0) {
    displayText = `${eatCount} Flavor • No Place Rating`;
  } else if (guideCount > 0) {
    displayText = `No Flavor • ${guideCount} Place Rating`;
  } else {
    displayText = 'No Rating Available';
  }

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light data={props.data?.foodie} />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight= {{ xs: "12rem",sm: "15.75rem" }}
        borderRadius="xl"
        sx={{
          backgroundImage: props.data?.foodie?.[0]?.foodie_coverphoto ? `url(${window.BASE_URL}/${props.data?.foodie?.[0]?.foodie_coverphoto})` :
          
          (({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.light.main, 0.6),
              rgba(gradients.light.state, 0.6)
            )}, url(/static/media/white-curved.a5b7db662b0b14ab5534.jpeg)`),
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {
            <SoftAvatar
              src={window.BASE_URL + props.data?.foodie?.[0]?.foodie_profilephoto || burceMars2}
              alt="profile-image"
              variant="rounded"
              size="xxl"
              shadow="sm"
              borderRadius="4.75rem"
              style={{borderRadius:"4.75rem"}}
              isLoading={!props.data?.foodie}
              circular={true}

            />
    }
          </Grid>

          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1} display="grid">

              <SoftTypography variant="h5" fontWeight="medium" skeletonWidth="100px" isLoading={!props.data?.foodie}>
                {props.data?.foodie?.[0]?.full_name}
              </SoftTypography>
              
              <SoftTypography variant="button" color="text" fontWeight="medium" skeletonWidth="70px" isLoading={!props.data?.foodie}>
                {displayText}
              </SoftTypography>
              {/* <SoftBadge variant="gradient" badgeContent={"gourmet"} color="leaf" size="xs" container /> */}
              <SoftBadge variant="gradient" badgeContent={"Gourmand"} color="light" size="xs" container />
              
            </SoftBox>
          </Grid>

          {id != username ? (username || username == null ? "" :  <Grid item xs={12} md={1.8} lg={1.8} sx={{ ml: "auto", mt: "30px" }}>
            <SoftButton  id="printButton" onClick={handleButtonClick}  color="dark" variant="gradient" isLoading={!props.data?.foodie} fullWidth>
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                person_add
              </Icon>&nbsp;  {t("button.follow")}
            </SoftButton>
          </Grid> : "")
          : 
          
          <Grid item xs={12} md={1.8} lg={1.8} sx={{ ml: "auto", mt: "30px" }}>
            <SoftButton  id="printButton" onClick={handleEditButtonClick}  color="dark" variant="gradient" fullWidth>
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                edit
              </Icon>&nbsp;  {t("button.edit")}
            </SoftButton>
          </Grid>
          }

          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                <Tab value={0} label="General" icon={<Cube />} />
                <Tab value={1} label="Eatlist" icon={<FontAwesomeIcon icon={faUtensils} />} />
                <Tab value={2} label="Rating" icon={<FontAwesomeIcon icon={faSplotch} />} />
                {/* <Tab value= {3} label="Terfi" icon={<FontAwesomeIcon icon={faUserClock} />} /> */}
                {/* <Tab value={4} label="İzinler" icon={<FontAwesomeIcon icon={faCalendarMinus} />} />
                <Tab value={5} label="Zimmet" icon={<FontAwesomeIcon icon={faPersonWalkingLuggage} />} />
                <Tab value={6} label="Performans" icon={<FontAwesomeIcon icon={faUserTag} />} />
                <Tab value={7} label="Disiplin" icon={<FontAwesomeIcon icon={faIdCard} />} />
                <Tab value={8} label="Değerlendirme" icon={<FontAwesomeIcon icon={faNoteSticky} />} />
                <Tab value={9} label="Evrak" icon={<FontAwesomeIcon icon={faFolderOpen} />} /> */}
                {/* <Tab value= {10} label="Düzenle" icon={<Settings />} /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
