import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import Rating from '@mui/material/Rating';
import Divider from "@mui/material/Divider";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftSelect from "components/SoftSelect";

import FormField from "layouts/place/components/new-rate/components/FormField";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import placeIcon from "assets/images/logos/icon-place.png";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/white-curved.jpeg";

import { useTheme } from '@mui/material/styles';
import { Margin, Padding } from "@mui/icons-material";
import { menuColors, menuDict, menuNum } from "assets/variables";

/* eslint-disable react/prop-types */

function Header(props) {

  props.data.result = props.data.result || {};

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const [loading, setLoading] = useState(false);

  const [expandFood, setExpandFood] = useState(false);
  const [availableFoods, setAvailableFoods] = useState(props.data || []); // Gelen foods verisini burada saklıyoruz ve boş diziyle başlatıyoruzx

  const [yolculuklar, setYolculuklar] = useState([]);

  const [usersActiveMenu, setUsersActiveMenu] = useState(null);
  const closeUsersActiveMenu = () => setUsersActiveMenu(null);
  const openUsersActiveMenu = (event) => setUsersActiveMenu(event.currentTarget);

  const [selectedMenu, setSelectedMenu] = useState(null);


  useEffect(() => {
    setAvailableFoods(props.data);
  }, [props.data]);

  function getField(food, field) {
    return food.custom_restaurant?.[field] || food.restaurant?.[field] || "No " + field;
  }


  const handleSubmit = async () => {
    setLoading(true);
    // Tüm yolculuklar için rating ve comment verilerini birleştiriyoruz
    const yolculukVerileri = yolculuklar.map((yolculuk) => ({
      ...yolculuk,
      rating: ratings[yolculuk.id] || null, // Eğer rating yoksa null olarak gönderir
      comment: comments[yolculuk.id] || "", // Eğer comment yoksa boş string gönderir
    }));

    // Tüm form verilerini güncelliyoruz, restoranın genel rating ve yorumunu ekliyoruz
    const updatedFormData = {
      ...formData,
      yolculuklar: yolculukVerileri,
      generalRating,    // Restoranın genel rating'i
      generalComment,   // Restoranın genel yorumu
      google_id: id  // Restoranın ID'si
    };

    try {
      const response = await axios.post(window.BASE_URL + "/review/create", updatedFormData);
      if (response.status >= 200 && response.status < 300) {
        console.log("Data submitted successfully:", response.data);
        onComplete();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Bir hata oluştu, lütfen tekrar deneyin.");
    } finally {
      setLoading(false); // İşlem bittiğinde loading durumunu kapat
    }
  };

  useEffect(() => {

    const foodieTags = props.data?.result?.foodie_tags ?? "-1"; // foodie_tags tanımlı değilse varsayılan olarak "-1" atanır.
    setSelectedMenu(menuDict[String(foodieTags)]);
  }, [props.data]);

  const { id } = useParams();

  const theme = useTheme();
  const { functions, palette } = theme;
  const { rgba, linearGradient } = functions;
  const { gradients } = palette;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("horizontal")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleYolculukEkle = (foodName) => {
    const selectedFood = availableFoods.find((food) => food.name === foodName);
  
    if (selectedFood) {
      // Yeni yolculuğu ekle
      const newYolculuk = {
        id: yolculuklar.length + 1,
        food_id: selectedFood.id,
        yolculuk_turu: foodName,
        image: selectedFood.food_photo,
        gidisDonus: false,
      };
      setYolculuklar((prevYolculuklar) => [...prevYolculuklar, newYolculuk]);
  
      // Seçenek listesinden seçilen öğeyi kaldır
      setAvailableFoods((prevFoods) =>
        prevFoods.filter((food) => food.name !== foodName)
      );
    } else {
      console.warn("Seçilen yiyecek bulunamadı!");
    }
  };
  


  const handleMenuSelection = async (menuName) => {
    try {

      const menuNumber = menuDict[menuName];
      // API'ye POST isteği at
      const response = await axios.post(
        `${window.BASE_URL}/review/update-bookmark`,
        { status: menuNumber, google_id: id },
        { withCredentials: true }
      );

      // Başarılı olursa seçili menüyü güncelle
      if (response.status === 200) {
        setSelectedMenu(menuName);
      }
    } catch (error) {
      console.error("API Hatası:", error);
    } finally {
      closeUsersActiveMenu(); // Menü kapatılır
    }
  };


  const Target = ({ name, image, desc, type }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={{ xs: 2, sm: 4 }} ml={{ xs: -3, sm: 1 }}>
          <SoftAvatar src={""} size="sm" icon={type ? menuColors[menuNum[type]].icon : "local_dining"} bgColor={type ? menuColors[menuNum[type]].color : "flatwhite"} variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
          <SoftTypography variant="text" fontWeight="light" color="secondary">{desc}</SoftTypography>
        </SoftBox>
      </SoftBox>
    } color="light" size="xl" container />
  );

  const NewTarget = ({ name, image, desc }) => (
    <SoftBadge variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "100%" }} >
        <SoftBox mr={{ xs: 2, sm: 4 }} ml={{ xs: -3, sm: 1 }}>
          <SoftAvatar src={image} size="sm" icon="search" bgColor="dark" variant="rounded" />
        </SoftBox>

        <SoftBox
          style={{ width: "auto" }}
          display="flex"
          flexWrap="wrap"
          flexDirection="column"
          alignItems="flex-start"
        >
          <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
          <SoftTypography variant="text" fontWeight="light" color="secondary">{desc}</SoftTypography>
        </SoftBox>

      </SoftBox>
    } color="light" size="xl" container />
  );



  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={() => handleMenuSelection("Favorite")}>
        <NewTarget name="Favorite" color="primary" icon="favorite" />
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection("Pit Stop")}>
        <NewTarget name="Pit Stop" color="info" icon="place" />
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection("Discover")}>
        <NewTarget name="Discover" color="green" icon="flag" />
      </MenuItem>
      <MenuItem onClick={""}>
        <NewTarget
          name="Eatlist"
          color="flatwhite"
          icon="add"
          white="true"
          subtext="Add to an eatlist"
        />
      </MenuItem>
    </Menu>
  );


  return (
    <SoftBox position="relative">

      {!props.data || props.data.result?.in_list_count > 0 ? (
        <DashboardNavbar absolute light data={props.data} />
      ) : (
        <DashboardNavbar absolute dark data={props.data} />
      )}



      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="10.75rem"
        borderRadius="xl"
        mt={-3}
        style={{ borderStartEndRadius: "0px", borderStartStartRadius: "0px" }}
        sx={{
          backgroundImage: !props.data.result
            ? linearGradient(
              rgba(gradients.light.main, 0.1),
              rgba(gradients.light.state, 0.1)
            )
            : props.data?.result?.in_list_count == 0
              ? linearGradient(
                rgba(gradients.light.main, 0.1),
                rgba(gradients.light.state, 0.1)
              )
              : `${linearGradient(
                // rgba(gradients.warning.state, 1.0),
                // rgba(gradients.leaf.main, 1.0)
                rgba(gradients.leaf.main, 0.05),
                rgba(gradients.leaf.state, 0.05)
              )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          maxWidth: "1000px",
          justifySelf: "center",
          width: "-webkit-fill-available"
        }}
      >
        <Grid container spacing={3} alignItems="center"  >
          <Grid item>
            <SoftAvatar
              src={""}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
              icon="local_dining"
              iconSize="2rem"
              bgColor="flatwhite"

            />
          </Grid>

          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>

              <SoftTypography variant="h5" fontWeight="medium">
                {"Create Eatlist "}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {"Eatlist Details"}
              </SoftTypography>
            </SoftBox>
          </Grid>



          <Grid item xs={12}>
            <Divider />


            <SoftBox bgColor="flatwhite" borderRadius="lg" my={0} py={1} pl={1} pr={1}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography component="label" variant="h6" fontWeight="medium">
                  Eatlist Name&nbsp;&nbsp;
                  <SoftTypography variant="caption" fontWeight="regular" color="text">

                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <FormField
                type="text"
                placeholder="My Eatlist #1"
                multiline
                rows={1}
                value={""} // Her yiyecek için comment güncelleme
                onChange={""}
              />

            </SoftBox>



            <Divider />


            <SoftBox pt={1} pb={0} px={0}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                <SoftBox style={{ width: "100%", display: "flex", justifyContent: "space-between" }} onClick={() => setExpandFood(!expandFood)}>
                  <SoftBox display="flex" alignItems="center">
                    {/* <SoftAvatar src="-" variant="rounded" icon="local_dining" bgColor={"dark"} /> */}
                    <SoftBox ml={1.7}>
                      <SoftTypography variant="h6" fontWeight="medium" >
                        List Items
                      </SoftTypography>
                      <SoftBox display="flex" alignItems="flex-end">
                        <SoftTypography variant="caption" color="text" fontWeight="regular">
                          Select Ratings
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>

                  </SoftBox>

                  <SoftButton color="secondary" variant="white" sx={{ background: "transparent", margin: "0px", padding: "0px", minWidth: "40px" }}>

                    <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "0px" }} fontSize={expandFood ? "small" : "medium"} color="dark">
                      {expandFood ? "visibility_off" : "expand_more"}
                    </Icon>
                  </SoftButton>
                </SoftBox>




                {<SoftBox
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  pt={expandFood ? 3 : 0}
                  px={1}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    display: "block",
                    opacity: expandFood ? 1 : 0,
                    transform: expandFood ? "translateY(0)" : "translateY(-20px)",
                    height: expandFood ? "auto" : 0, // Alanın kaybolması
                    // Taşmaları gizler
                    transition: "opacity 0.3s ease, transform 0.3s ease, height 0.3s ease", // Yükseklik de animasyona dahil
                    visibility: expandFood ? "visible" : "hidden",
                    zIndex: "10" // Görünürlük kontrolü
                  }}
                >
                  <SoftSelect
                    defaultValue={null}
                    options={availableFoods.map((food) => ({
                      value: food.name,
                      label: (
                        <Target
                          name={getField(food, "name")}
                          image={food.food_photo || ""}
                          desc={food.rating ? `★ ${food.rating}` : "No Score"}
                          type={food.status}
                        />
                      ),
                    }))}
                    value={null} // Başlangıçta seçili bir değer yok
                    size="large"
                    fullWidth
                    onChange={(selectedOption) => handleYolculukEkle(selectedOption.value)} // Seçilen yiyecek işlenir
                    sx={{ width: "100%", minWidth: "400px", maxWidth: "100%" }}
                  />



                </SoftBox>}
              </SoftBox>

              <SoftBox pt={yolculuklar.length > 0 ? 6 : 2} pb={3} px={0}>
                {yolculuklar.map((yolculuk) => (
                  <Card key={yolculuk.id} ml={3} sx={{ padding: "20px", paddingBottom: "10px", marginInline: "20px", marginBottom: "30px" }}>
                    <div key={yolculuk.id}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="row">
                        <SoftBox display="flex" alignItems="center">
                          <SoftAvatar src={""} alt="logo" variant="rounded" icon="local_dining" bgColor="flatwhite" />
                          <SoftBox ml={2}>
                            <SoftTypography variant="h5" fontWeight="medium">
                              {getField(yolculuk, "name")}
                            </SoftTypography>
                            <SoftBox display="flex" alignItems="flex-end">
                              <SoftTypography variant="button" color="text" fontWeight="regular">
                                Flavor Detail
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox display="flex" alignItems="center" justifyContent="flex-end">
                          <SoftAvatar size="sm" variant="rounded" icon="delete" bgColor="dark" style={{ cursor: "pointer" }} onClick={() => handleYolculukSil(yolculuk.id, yolculuk.yolculuk_turu)} > delete </SoftAvatar>
                        </SoftBox>
                      </SoftBox>

                      <SoftBox ml={2} pl={0} pt={2} lineHeight={1}>

                        <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Description&nbsp;&nbsp;
                              <SoftTypography variant="caption" fontWeight="regular" color="text">
                                (optional)
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>

                        </SoftBox>
                      </SoftBox>
                      <Divider />

                    </div>
                  </Card>
                ))}
              </SoftBox>
            </SoftBox>
          </Grid>


          <Grid item xs={12} sm={12} pt={3}>
            <SoftBox display="flex" justifyContent="center" pt={3}>
              <SoftButton variant="gradient" color={loading ? "dark" : "dark"} onClick={handleSubmit} sx={{ maxWidth: "400px" }} disabled={loading} fullWidth>
                <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: "5px" }} fontSize={expandFood ? "small" : "medium"} color="dark">
                  {"add"}
                </Icon>
                {loading ? "Creating" : "Create"}
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </Card >
    </SoftBox >
  );
}

export default Header;
