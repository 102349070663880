import Skeleton from "@mui/material/Skeleton";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import SoftAvatarRoot from "components/SoftAvatar/SoftAvatarRoot";
import Icon from "@mui/material/Icon";
/* eslint-disable react/prop-types */

const SoftAvatar = forwardRef(({ bgColor, size, shadow, icon, text, iconColor, iconSize, src, isLoading, circular, ...rest }, ref) => {
  // Varsayılan imgProps'u tanımla
  const defaultImgProps = {
    style: { objectFit: 'cover', height: '100%', width: '100%' }
  };

  iconColor = iconColor ? iconColor : "white";

  // Eğer rest içinde imgProps varsa, onu defaultImgProps ile birleştir
  const imgProps = rest.imgProps
    ? { ...defaultImgProps, ...rest.imgProps }
    : defaultImgProps;

  const gradientStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    borderRadius: 'inherit',
    
  };

  return (
    <SoftAvatarRoot
      ref={ref}
      ownerState={{ shadow, bgColor, size }}
      {...rest}
      
    >
      {isLoading ? (
        <Skeleton
        variant={circular ? "circular" : "rectangular"} // Yuvarlak mı dikdörtgen mi belirle
        animation="wave"
        style={{
          width: "100%",
          height: "100%",
          borderRadius: circular ? "50%" : "4px" // Yuvarlaklık kontrolü
        }}
      />
      ) : src ? (
        <img src={src} alt="avatar" {...imgProps} />
      ) : icon ? (
        <div style={gradientStyle}>
          <Icon
            fontSize={"50px"}
            style={{
              fontSize: "50px !important",
              color: bgColor !== "flatwhite" ? iconColor : "rgb(112 116 123)",
            }}
            sx={iconSize ? { fontSize: iconSize } : {}}
          >
            {icon}
          </Icon>
          {text ? (
            <span
              style={{
                fontSize: "15px",
                color: bgColor !== "flatwhite" ? iconColor : "rgb(112 116 123)",
              }}
            >
              {text}
            </span>
          ) : null}
        </div>
      ) : null}
    </SoftAvatarRoot>
  );
});

// SoftAvatar bileşeni için varsayılan prop değerlerini ayarlama
SoftAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
  icon: null,
  isLoading: false, // Varsayılan olarak yükleme durumu kapalı
};

// SoftAvatar bileşeni için prop türlerini tanımlama
SoftAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf([
    "none",
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "xxl",
    "inset",
  ]),
  icon: PropTypes.string,
  text: PropTypes.string,
  src: PropTypes.string,
  isLoading: PropTypes.bool, // Skeleton gösterimi için
};

export default SoftAvatar;
