

/* eslint-disable react/prop-types */
import { useState, useMemo } from "react";
import axios from "axios";
import { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import Header from "layouts/eatlist/create/components/Header";

// Bileşenler
function NewProduct({ }) {
    const [activeStep, setActiveStep] = useState(0);

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${window.BASE_URL}/review/user`, { withCredentials: true });
            setData(response.data);
          } catch (error) {
            console.log(error);
            console.log("Directing")
    
          }
        };
    
        fetchData();
      }, []);

    

    return (
        <DashboardLayout>
            <Header onChange={e => onTabChange(e)} data={data} />
            <SoftBox mt={5} mb={12} ></SoftBox>
          
        </DashboardLayout>
    );
}

export default NewProduct;
