import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import React, { useEffect, useState } from 'react';
import './FoodItem.css';



import axios from "axios"
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import MenuItem from "@mui/material/MenuItem";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

import Header from "layouts/headers/NormalHeader"
import curved0 from "assets/images/studio-5.jpg";
import { useNavigate } from 'react-router-dom';
import SoftButton from "components/SoftButton";

const FoodItem = ({ imageUrl, foodName, restaurantName, rating, inListCount , placeId}) => {
    const color = inListCount > 0 ? "chocolate" : "revert-layer";

    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate(`/place/${placeId}`);
    };

    return (
        <>
            <Grid item xs={12} md={6} xl={6} key={foodName} style={{justifyContent:"flex-start"}}>

                <Card className="h-100" onClick={handleCardClick}>

                    <div className="food-item">
                        
                        <div className="food-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                        <div className="rating-icon">
                            <i className="fa-solid fa-spoon"></i>
                        </div>
                        <div className="food-details">
                            <SoftTypography className="food-name" fontSize="14px" color="#484848">{foodName}</SoftTypography>

                            <p className="restaurant-name" style={{ color }}><i className="bi bi-geo-alt-fill" style={{ marginRight: "5px" }}></i>{restaurantName}</p>
                            {/* <p className="food-reviews"><i className="bi bi-chat-left-text-fill"></i>Show the reviews of food</p> */}
                        </div>
                        {/* <div className="food-rating">
                            <p className="rating-value">{rating}</p>

                        </div> */}
                    </div>


                </Card>

            </Grid>
        </>
    )
};

FoodItem.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    foodName: PropTypes.string.isRequired,
    restaurantName: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    inListCount: PropTypes.number.isRequired,
    placeId: PropTypes.string.isRequired,
};

export default FoodItem;
