/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import SoftSelect from 'components/SoftSelect';
import ReactDOM from 'react-dom';
import 'examples/Alerts/alerts.css'; // CSS dosyasını import edin

// Şehir seçimi için bileşen
const ShowAlertContent = ({ setSelectedCity }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('https://countriesnow.space/api/v0.1/countries/population/cities');
        setCities(response.data.data); // Gelen veriyi doğru formatta kullanın
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const options = cities.map((city) => ({ label: city.city, value: city.city }));

  return (
    <div>
      <p>Please choose your location from the options below:</p>
      <SoftSelect
        options={options}
        onChange={(e) => setSelectedCity(e.value)}
      />
    </div>
  );
};

// Alert gösterimi için fonksiyon
export const showAlert = () => {
  const wrapper = document.createElement('div');
  let selectedCity = null;

  ReactDOM.render(<ShowAlertContent setSelectedCity={(value) => { selectedCity = value; }} />, wrapper);

  // Swal.fire ayarlarında sabit sınıfı ekleyin
  Swal.fire({
    title: 'Select Location',
    html: wrapper,
    customClass: {
      container: 'swal2-container blur-40',
      popup: 'swal2-popup',
      confirmButton: 'swal2-confirm'
    },
    showConfirmButton: true,
    preConfirm: () => {
      return selectedCity;
    }
  }).then((result) => {
    if (result.isConfirmed) {
      console.log('Selected city:', result.value);
    }
  });
};
