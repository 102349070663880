/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";


function Tables() {



  


    const navigate = useNavigate();
    //const [data, setData] = useState([]);



    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };

    const [data, setData] = useState([]);


    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${window.BASE_URL}/izinler?format=json`, { withCredentials: true });
          setData(response.data);
        } catch (error) {
          console.log(error);

        }
      };

      fetchData();
    }, []);


    let egitimRows = data.map(index => {
      return {
        "Adı": [
          <img key="" src="12" alt="Logo" />,
          <span key="task1" style={truncatedTextStyle}> {index.username}  </span>
        ],
        "Gün": <SoftBadge variant="gradient" badgeContent={index.gun_sayisi+ " GÜN"} color="info" size="xs" container />,

        "Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.izin_tarihi} ({index.yil})</SoftTypography>,

        "field_username": index.username,
        "field_gun": index.gun_sayisi,


      };
    });

    const projectsTableData = {
      columns: [
        { name: "Adı", align: "left", exportableField: "field_username", searchable: true, searchField: ["field_username"] },
        { name: "Gün", align: "center" ,exportableField: "field_gun", searchable: true, searchField: ["field_gun"]},
        { name: "Tarih", align: "center" },
        //{ name: "Durum", align: "left" },

      ],

      rows: [
        ...egitimRows,

      ],
    };











    return (

            <Grid item xs={12} md={12} xl={12}>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Yıllık İzinler Listesi</SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={projectsTableData.columns} rows={projectsTableData.rows} edit="/admin/base/izinler/" />
                </SoftBox>
              </Card>

            </Grid>


    );
  
}

export default Tables;
