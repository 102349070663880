/* eslint-disable react/prop-types */


import { useEffect } from "react";
import axios from "axios"
// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import SidenavSubCollapse from "examples/Sidenav/SidenavSubCollapse";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import { showAlert } from "examples/Sidenav/alert";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import NotificationItem from "examples/Items/NotificationItem";


// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from "context";
import index from "examples/IsealimCards/IsealimCardAlim";

import { store, persistor } from "store";
import { setUserKanal } from 'store/actions/authActions';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const collapseSubName = pathname.split("/").slice(1)[1];

  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [city, setCity] = useState('Location');
  const [distance, setDistance] = useState('100km');

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/location?format=json`, { withCredentials: true });
        setCity(response.data.user_location);
        setDistance(response.data.search_distance);
      } catch (error) {
        console.error('Error fetching city:', error);
      }
    };

    fetchCity();
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/location?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const kanalitems = (
    <>
      <NotificationItem
        key="current-location"
        color="dark"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            near_me
          </Icon>
        }
        title={["User Current Location"]}
        date="Click to Choose Location"
        icon="place"
        onClick={null}
      />
      <NotificationItem
        key="select-location"
        color="dark"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            search
          </Icon>
        }
        title={["Select Manually Location"]}
        date="Click to Choose Location"
        icon="place"
        onClick={showAlert}
      />
      <NotificationItem
        key="select-range"
        color="dark"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            swap_horiz
          </Icon>
        }
        title={["Select Range Location"]}
        date="Click to Choose Range"
        icon="settings"
        onClick={showAlert}
      />
    </>
  );

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {kanalitems}
    </Menu>
  );

  const [target, setTarget] = useState(null);

  const handleItemClick = (key) => {
    if (key !== target) {
      setTarget(key);
    } else {
      setTarget(null);
    }
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);

    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, children }) => {
    let returnValue;

    if (type === "collapse") {
      const mainCollapse = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            color={color}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavCollapse
            color={"info"}
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </NavLink>
      );

      const childCollapses = children ? children.map(child => (
        <NavLink to={child.route} key={child.key}>
          <SidenavSubCollapse
            color={color}
            key={child.key}
            name={child.name}
            icon="arrow_right"
            active={child.key === collapseSubName}
            noCollapse={child.noCollapse || false}
          />
        </NavLink>
      )) : null;

      returnValue = (
        <>
          {mainCollapse}
          <Collapse in={key === collapseName || target === key}>
            {childCollapses}
          </Collapse>
        </>
      );
    } else if (type === "title") {
      returnValue = (
        <SoftTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SoftTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} />;
    }

    return returnValue;
  });

  const userKanal = useSelector(state => state.auth.userKanal);

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }} id="printButton">

      <SoftBox pt={1} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center" marginBottom="-20px">
          {brand && <SoftBox component="img" src={brand} alt="Kashik Logo" width="100px" />}
          <SoftBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SoftTypography component="h6" variant="button" fontWeight="medium" sx={{ marginInline: "4px" }}>
              {brandName}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      <button onClick={handleOpenMenu} style={{
        background: "linear-gradient(310deg, #ff6904, #ffa919)",
        boxShadow: "rgb(187 44 44 / 15%) 0px -25px 18px -14px inset, rgb(187 114 44 / 10%) 0px 1px 2px, rgb(187 122 44 / 10%) 0px 2px 4px, rgb(187 97 44 / 10%) 0px 4px 8px, rgb(187 111 44 / 10%) 0px 8px 16px, rgb(187 52 44 / 10%) 0px 16px 32px",
        display: "flex",
        padding: "9px",
        justifyContent: "flex-start",
        borderRadius: "0.5rem",
        marginInline: "25px",
        marginBlock: "10px",
        minHeight: "max-content",
        alignItems: "center",
        border: "red"
      }}>
        <div style={{
          background: 'transparent',
          padding: '0px',
          marginInline: '15px',
          minHeight: '25px',
          height: "5px"
        }}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold", paddingLeft: "-20px", fontSize: "10px", color: "white !important" }} fontSize="small" color="dark">
            {userKanal === "Global" ? "public" : "gps_fixed"}
          </Icon>
        </div>
        <div style={{ display: "inherit" }}>
          <span style={{
            color: '#fff',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: '600',
            fontSize: '0.80rem',
            whiteSpace: "normal",
            width: "120px",
            overflowWrap: "break-word",
            textAlign: "left",
            paddingRight: "5px",
          }}>{city}          <span style={{
            color: '#ffffff80',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: '600',
            fontSize: '0.70rem',
            whiteSpace: "normal",
            

          }}>{distance}</span></span>

 
        </div>
      </button>
      {renderMenu()}

      <Divider />
      <List>{renderRoutes}</List>
      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SidenavCard />
        <SoftBox mt={2}>
        </SoftBox>
      </SoftBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;