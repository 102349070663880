import RouteProjectCard from "examples/Cards/ProjectCards/RouteExploreProjectCard";

// Gerekli kütüphaneler ve stil dosyaları
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import team5 from "assets/images/logos/food-icon-white-story2.png";
import ComplexProjectCard from "examples/Cards/ProjectCards/EatlistProjectCard";


/* eslint-disable react/prop-types */

// Öneri kartları
function Stories({ eatlistData }) {
    // Öneri kartlarını hazırlama
    const suggestions = eatlistData?.nearby_eatlists?.map(eatlist => ({
        background: window.BASE_URL + "/" + eatlist.cover || team5, // Eğer cover varsa kullan, yoksa team5
        owner: eatlist.foodie.full_name || 'No Foodie', // Foodie'nin adı
        title: eatlist.title,
        description: eatlist.description,
        distance: eatlist.distance, // Mesafe
        count: eatlist.like_count, // Count
        foodieName: window.BASE_URL + "/" + eatlist.foodie.foodie_profilephoto,  // Restoran adı
    })) || []; // Eğer storiesData undefined veya null ise boş bir dizi döndür

    // Öneri sayısını kontrol etme ve eksikleri doldurma
    const processedSuggestions = suggestions.length >= 6 ? suggestions.slice(0, 6) : [
        ...suggestions,
        ...Array(6 - suggestions.length).fill({
            background: team5,
            owner: '',
            distance: 0,
            title: "-",
            description: "",
            count: '',
            foodieName: ''
        })
    ];

    const loadContent = (content) => {
        // İçerik yükleme mantığı
        console.log(`Loading content for: ${content}`);
    };

    const showAlert = () => {
        // Uyarı mantığı
        alert('Suggestion card clicked');
    };

    const ScrollableSuggestions = () => (
        <div style={{ width: "100%" }}>
            <Grid container>
                <div className="guide-suggestions" onClick={() => loadContent('eatlist')}>

                    {processedSuggestions.map((suggestion, index) => (
                        <SuggestionCard key={index} {...suggestion} />
                    ))}

                </div>
            </Grid>
        </div>
    );

    const SuggestionCard = ({ background, title, owner, distance, foodieName, count, description }) => (
        <Grid item xs={12} md={5} lg={3.5}  >
            <div >
                <RouteProjectCard
                    image={background}
                    title={title}
                    description={description || "No description available"}
                    dateTime="6.4 ⭐"
                    members={[foodieName]}
                    owner={owner}
                    raterCount={"item.rate_count"}
                    ratingScore={count}
                    dropdown={{
                        action: "openDesignToolsMenu",
                        menu: "",
                    }}
                    color="light"
                />
            </div>
        </Grid>
    );

    return <ScrollableSuggestions />;
}

export default Stories;
