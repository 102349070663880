/* eslint-disable react/prop-types */
// Soft UI Dashboard React components

import React, { useEffect, useState } from 'react';
import axios from 'axios';


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
//import burceMars2 from "assets/images/bilal.alemdaroglu.png";

// Fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPenToSquare } from '@fortawesome/free-solid-svg-icons'

function Author({ id, image, name, email, tel }) {
  return (
    <a href={`/profile/${id}`}>
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="dark">
            {email}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {tel}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </a>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}


export default async function fetchData() {

  try {
    // API adresinizi buraya yazın
    //const apiUrl = `${window.BASE_URL}/userlist?format=json`;

    // API'den veriyi çek
    //const response = await fetch(apiUrl);

    // Veriyi JSON formatına çevir
    //const data = await response.json();
    

    //const columns = []

    // Eğer API'den gelen veri uygun formatta değilse, uygun formata getir
    const rows = data.map(item => ({
      Adı: <Author id={item.id} image={window.BASE_URL + item.personel_fotograf} name={item.name + " " + item.surname } email={item.trt_arabi_mail} tel={item.kisisel_cep_no} />,
      Pozisyon: <Function job={item.trt_arabi_unvan} org={item.trt_unvan} />,
      Başlangıç: <SoftBadge variant="gradient" badgeContent={item.sozlesme_baslangic} color="default" size="xs" container />,
      Uyruk: <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.uyruk} </SoftTypography>,
      Sicil: <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.sicil_no} </SoftTypography>,
      Düzenleme: (<SoftTypography component="a" href={`profile/${item.id}`} variant="caption" color="secondary" fontWeight="medium" > <FontAwesomeIcon icon={faPenToSquare} /></SoftTypography>),

      field_name: String(item.name + " " + item.surname),
      field_position: String(item.trt_arabi_unvan),
      field_status: String(item.sozlesme_baslangic),


    }));


    // columns ve rows listelerini içeren bir obje döndür
    console.log(rows);
    return {
      columns: [
        { name: "Adı", align: "left", exportableField: "field_name", searchable: true, searchField: ["field_name"] },
        { name: "Sicil", align: "center" },
        { name: "Uyruk", align: "center" },
        { name: "Pozisyon", align: "left", exportableField: "field_position", searchable: true, searchField: ["field_position"] },
        { name: "Başlangıç", align: "center", exportableField: "field_status" },

        //{ name: "Düzenleme", align: "center" },
      ],
      rows: rows,
    };
  } catch (error) {
    console.error('API verisi çekerken bir hata oluştu:', error);
    throw error;
  }
}
