export const menuColors = {
    Starred: { color: "warning", icon: "star" },
    Favorite: { color: "primary", icon: "favorite" },
    "Pit Stop": { color: "info", icon: "place" },
    Discover: { color: "green", icon: "flag" },
    Eatlist: { color: "flatwhite", icon: "add" },
  };
  
  export const menuDict = {
    Starred: 4,
    Favorite: 3,
    "Pit Stop": 2,
    Discover: 0,
    Eatlist: -1,
  };
  

  export const menuNum = {
    "4": "Starred",
    "3": "Favorite",
    "2": "Pit Stop",
    "0": "Discover",
    "-1": "Eatlist",
  };