import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import './App.css';
import SoftBox from "components/SoftBox";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ComplexProjectCard from "examples/Cards/ProjectCards/EatlistProjectCard";

import Grid from "@mui/material/Grid";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Stories from "examples/Stories/PhotoStories";
import EatlistCards from "examples/EatlistCards";
import RouteCards from "examples/RouteCards";
import PlaceCards from "examples/PlaceCards";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

/* eslint-disable react/prop-types */

import { showAlert } from "layouts/explore/reels-alert";
import { t } from 'i18next';



const App = () => {
  const [categoryData, setCategoryData] = useState([]);
  const navigate = useNavigate();
  const username = useSelector(state => state.auth.username);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/nearby-category?format=json`, { withCredentials: true });
        setCategoryData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryData();
  }, []);

  const [storyData, setStoryData] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/nearby-story?format=json`, { withCredentials: true });
        setStoryData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryData();
  }, []);

  const [eatlistData, setEatlistData] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/nearby-eatlist?format=json`, { withCredentials: true });
        setEatlistData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryData();
  }, []);

  const [placeData, setPlaceData] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/nearby-place?format=json`, { withCredentials: true });
        setPlaceData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryData();
  }, []);

  const categoryIconMap = {
    Hamburger: "lunch_dining",
    Pizza: "local_pizza",
    Healthy: "spa",
    World: "restaurant_menu",
    Dessert: "icecream",
    Cocktail: "local_bar",
    "Ice Cream": "icecream",
    Salad: "spa",
    Coffee: "local_cafe",
    Sushi: "set_meal",
    Bread: "bakery_dining",
    Tofu: "spa",
    Steak: "restaurant_menu",
    Pasta: "local_pizza",
    Noodles: "ramen_dining"
  };

  const softBoxItemsTemplate = [
    { title: "Foods Near", count: "Hamburger", icon: { color: "warning", component: "lunch_dining" } },
    { title: "Drinks Nearby", count: "Cocktail", icon: { color: "warning", component: "local_bar" } },
    { title: "Desserts", count: "Ice Cream", icon: { color: "warning", component: "icecream" } },
    { title: "Fast Foods", count: "Pizza", icon: { color: "warning", component: "local_pizza" } },
    { title: "Healthy Options", count: "Salad", icon: { color: "warning", component: "spa" } },
    { title: "Coffee Shops", count: "Coffee", icon: { color: "warning", component: "local_cafe" } },
    { title: "Seafood", count: "Sushi", icon: { color: "warning", component: "set_meal" } },
    { title: "Bakeries", count: "Bread", icon: { color: "warning", component: "bakery_dining" } },
    { title: "Vegan Options", count: "Tofu", icon: { color: "warning", component: "spa" } },
    { title: "Steakhouses", count: "Steak", icon: { color: "warning", component: "restaurant_menu" } },
    { title: "Italian", count: "Pasta", icon: { color: "warning", component: "local_pizza" } },
    { title: "Asian Cuisine", count: "Noodles", icon: { color: "warning", component: "ramen_dining" } }
  ];

  const transformData = (data) => {
    if (!data || !data.categories) return []; // Eğer data veya data.categories yoksa boş bir array döndür

    return Object.keys(data.categories).map(category => {
      const count = data.categories[category];
      const iconComponent = categoryIconMap[category] || "local_dining"; // İkon haritasından ikon seç
      const templateItem = softBoxItemsTemplate.find(item => item.count === category);

      if (templateItem) {
        return {
          ...templateItem,
          title: `${count} ${t("food.subtitle")}`,
          count: t("food."+category),
          icon: { color: "dark", component: iconComponent }
        };
      } else {
        return {
          title: `${count} ${t("food.subtitle")}`,
          count: t("food."+category),
          icon: { color: "dark", component: iconComponent }
        };
      }
    });
  };

  const softBoxItems = transformData(categoryData);

  return (
    <DashboardLayout>
      <div id="index-elements">
        <div className="content-explore">
          <ProfileCard username={username} />

          <EatlistItems softBoxItems={softBoxItems} storyData={storyData} eatlistData={eatlistData} placeData={placeData} />
          {/* <Suggestions /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};


const ProfileCard = ({username}) => (
  <div className="profile-card">
    <DashboardNavbar hidden={true} onlyWeb={true} />

    <p className="featured-text">{t("main.pretitle")}</p>
    <h2 className="explore-title">{t("pages.explore")}</h2>
    <p className="username">{username ? "@" + username : t("title.guestview")}</p>
  </div>
);

const EatlistItems = ({ softBoxItems, storyData, eatlistData, placeData }) => (
  <div className="eatlist-items">
    <EatlistItem
      //background="linear-gradient(310deg, rgb(249 205 0), rgb(255 162 0))"
      background="linear-gradient(132deg, rgb(255, 128, 4), rgb(255, 204, 0))"
      title={t("main.moodboard1.title")}
      description={t("main.moodboard1.subtitle")}
      icon="fas fa-compass"
      onClick={() => loadContent('category', '/hamburger')}
    />
    <Grid item xs={12} md={12} lg={12} style={{ gap: "4%", display: "flex", overflow: "auto", width: "inherit", marginTop: "20px", marginBottom: "-25px", paddingBottom: "10px", paddingInline: "15px" }}>
      {softBoxItems.map((item, idx) => (
        <SoftBox mb={3} className="section-category" key={idx}>
          <a href={`/category/${encodeURIComponent(item.count)}`} style={{ textDecoration: 'none' }}>
            <MiniStatisticsCard
              title={{ fontWeight: "medium", text: item.title }}
              count={item.count}
              icon={{ color: item.icon.color, component: item.icon.component }}
              direction="left"
            />
          </a>
        </SoftBox>
      ))}
    </Grid>
    <SectionTitle title={t("suggestion.foryou")} style={{ marginTop: "0px" }} />

    {/* <ScrollableSuggestions /> */}

    <Stories storiesData={storyData} />

    <EatlistItem
      background="linear-gradient(310deg, rgb(51 51 51), rgb(31, 31, 31))"
      //background="linear-gradient(310deg, rgb(249 205 0), rgb(255 162 0))"
      title={t("main.moodboard2.title")}
      description={t("main.moodboard2.subtitle")}
      icon="fas fa-utensils"
      onClick={() => loadContent('category', '/pizza')}
    />
    <SectionTitle title={t("suggestion.foryou")} />


    <EatlistCards eatlistData={eatlistData.nearby_eatlists} />


    <EatlistItem
      background="linear-gradient(132deg, rgb(255 128 4), rgb(255 204 0))"
      title={t("main.moodboard3.title")}
      description={t("main.moodboard3.subtitle")}
      icon="fas fa-route"
      onClick={() => loadContent('category', '/pizza')}
    />
    <SectionTitle title={t("suggestion.foryou")}/>

    <PlaceCards placeData={placeData.nearby_branches} />

    <SectionTitle title={t("suggestion.foryou")} />

    <RouteCards eatlistData={eatlistData} />

  </div>
);


const EatlistItem = ({ background, title, description, icon, onClick }) => (
  <div className="eatlist-item" style={{ background }} onClick={onClick}>
    <div className="eatlist-item-content">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
    <i className={icon}></i>
  </div>
);

const SectionTitle = ({ title, style }) => (
  <h3 className="section-title" style={style}>{title}</h3>
);



const SuggestionCard = ({ background, owner }) => (
  <div className="suggestion-card" style={{ backgroundImage: `url(${background})` }} onClick={showAlert}>
    <div className="suggestion-card-overlay">
      <p>{owner}</p>
    </div>
  </div>
);

const Suggestions = () => (
  <div className="suggestions">
    <SectionTitle title={t("suggestion.foryou")} />
    <GuideSuggestions />
  </div>
);

const GuideSuggestions = () => (
  <div className="guide-suggestions">
    {guides.map((guide, index) => (
      <GuideCard key={index} {...guide} />
    ))}
  </div>
);

const GuideCard = ({ title, owner, rating }) => (
  <div style={{ marginTop: "20px" }}>
    <ComplexProjectCard
      image={"item.photo" ? window.BASE_URL + "/" + "item.photo" : "foodIcon"}
      title={"item.name"}
      description={"item.description2" || "No description available"}
      dateTime="6.4 ⭐"
      members={["team1", "team2", "team3"]}
      raterCount={"item.rate_count"}
      ratingScore={"item.point" + ""}
      dropdown={{
        action: "openDesignToolsMenu",
        menu: "",
      }}
      color="light"
    />
  </div>
);

// Mock Data
const suggestions = [
  {
    background: 'https://i.pinimg.com/originals/33/1a/43/331a434f0a4c7be8f07e40e17524a0d1.jpg',
    owner: 'Eatlish owner full name here'
  },
  {
    background: 'https://i.pinimg.com/736x/a6/27/b4/a627b4072b334d6b105ed94f638b8b09.jpg',
    owner: 'Eatlish owner full name here'
  },
  {
    background: 'https://i.pinimg.com/736x/9a/d3/42/9ad342e5e98ff84928473c3b68fbf2a9.jpg',
    owner: 'Eatlish owner full name here'
  },
  {
    background: 'https://twolovesstudio.com/wp-content/uploads/2016/03/Black-and-White-Food-Photography-Rachel-Korinek-Black-Sesame-Buttermilk-Pana-Cotta9202-RK-Blog-726x1088.jpg',
    owner: 'Eatlish owner full name here ormay'
  },
  {
    background: 'https://twolovesstudio.com/wp-content/uploads/2016/03/Black-and-White-Food-Photography-Rachel-Korinek-Sake-Macarons10009-RK-Blog-1-726x1088.jpg',
    owner: 'Eatlish owner full name here'
  },
  {
    background: 'https://i0.wp.com/i.pinimg.com/originals/9a/8f/16/9a8f16c1cb422c9fa92c60021dd93f4d.jpg?resize=160,120',
    owner: 'Eatlish owner full name here'
  },
];

const guides = [
  { title: 'Guide Contains a long Name Allows wrap on', owner: 'Guide owner full name example name', rating: '4.7' },
  { title: 'Guide Contains a long Name Allows wrap on', owner: 'Guide owner full name example name', rating: '4.7' },
  { title: 'Guide Contains a long Name Allows wrap on', owner: 'Guide owner full name example name', rating: '4.7' },
  { title: 'Guide Contains a long Name Allows wrap on', owner: 'Guide owner full name example name', rating: '4.7' },
];

// Helper functions
const loadContent = (type, url) => {
  // Load content logic here
};

export default App;
