import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React icons
import Settings from "examples/Icons/Settings";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHeart, faHouse, faIdCard, faNoteSticky, faPen, faPenToSquare, faPersonWalkingLuggage, faSplotch, faStar, faStarHalfStroke, faThumbsUp, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'


function Account() {
  const [design, setDesign] = useState(false);
  const [code, setCode] = useState(false);
  const [develop, setDevelop] = useState(false);

  const handleSetDesign = () => setDesign(!design);
  const handleSetCode = () => setCode(!code);
  const handleSetDevelop = () => setDevelop(!develop);

  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary, light },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${light.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
        fontSize: "24px"
      },
    },
  });

  const iconcolor = "white"

  return (
    <SoftBox>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={4}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="regular">
            What are you doing? (checkboxes)
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Give us more details about you. What do you enjoy doing in your spare time?
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                color={design ?"warning":"light"}
                variant={design ? "gradient" : "outlined"}
                onClick={handleSetDesign}
                sx={customButtonStyles}
              >
                <FontAwesomeIcon icon={faStar}  style={{color:design ? "white" : "#70747a"}} />
              
              </SoftButton>
              <SoftTypography variant="h6">Starred</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                color={code ?"primary":"light"}
                variant={code ? "gradient" : "outlined"}
                onClick={handleSetCode}
                sx={customButtonStyles}
              >
                <FontAwesomeIcon icon={faHeart} style={{color:code ? "white" : "#70747a"}} />
              </SoftButton>
              <SoftTypography variant="h6">Favorite</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                
                color={develop ?"info":"light"}
                variant={develop ? "gradient" : "outlined"}
                onClick={handleSetDevelop}
                sx={customButtonStyles}
                fontSize="24px !important"

              >
                <FontAwesomeIcon icon={faThumbsUp}  style={{color:develop ? "white" : "#70747a"}} />
              </SoftButton>
              <SoftTypography variant="h6">Like</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default Account;
