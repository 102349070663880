/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/haberpaket`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);


  try {


    function Author({ image, name, haber }) {
      return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="dark">
              {haber}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );
    }


    const truncatedTextStyle = {
      width: '300px',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      height: '20px',
      WebkitBoxOrient: 'vertical'
    };



    const tableRows = data.haberPaketleri.map(item => {
      return {

        "İsim": <Author image="" name={item.haberi_yapan_isim} haber={item.haber_basligi} />,
        "Haber Birim": <SoftBadge variant="gradient" badgeContent={item.haber_birimi} color="info" size="xs" />,
        "Haber İçerik": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.haber_icerik}</SoftTypography>,
        "Haber Türü": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.haber_turu}</SoftTypography>,
        "Haber Bölge": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.haberin_yapildigi_bolge}</SoftTypography>,
        "Haber Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{item.haberin_yapilma_tarihi}</SoftTypography>,

        "search_name": item.haberi_yapan_isim, // hidden
        "search_title": item.haber_basligi,   // hidden

      };
    });

    const projectsTableData = {
      columns: [
        { name: "İsim", align: "left", searchable: true, searchField: ["search_name", "search_title"] },
        { name: "Haber Birim", align: "center", searchable: true },
        { name: "Haber İçerik", align: "center" },
        { name: "Haber Türü", align: "center", searchable: true },
        { name: "Haber Bölge", align: "center", searchable: true },
        { name: "Haber Tarih", align: "center", searchable: true },
      ],
      rows: tableRows
    };

    /* ######################################    GRAFİK DETAILS   ############################# */

    const safeValue = (value) => value === undefined ? 0 : value;

    const safeValueWithTryCatch = (value) => {
      try {
        return value || 0;
      } catch (error) {
        return 0;
      }
    };

    const safeDeepValue = (object, path) => {
      try {
        return path.split('.').reduce((o, k) => o[k], object) || 0;
      } catch (error) {
        return 0;
      }
    };



    const regions = [
      "Ortadoğu ve Kuzey Afrika",
      "Türkiye",
      "Avrupa ve Balkanlar",
      "Kuzey Amerika ve Güney Amerika",
      "Afrika",
      "Rusya, Türki Cumhuriyetler, Orta Asya"
    ];


    let items = []
    let datasetsData = []
    try {
      items = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: safeValue(data.Haber["PKG-WT"][region]),
          percentage: (Number(safeValue(data.Haber["PKG-WT"][region])) / Number(data.Haber["PKG-WT"]["Toplam"])) * 100
        }
      }));

      datasetsData = regions.map(region => Number(safeValueWithTryCatch(data.Haber["PKG-WT"][region]) || 0)); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: 0,
          percentage: 0,
        }
      }));


      datasetsData = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }

    const reportsBarChartData = {
      chart: {
        labels: regions,
        datasets: { label: "Sayı", data: datasetsData },
      },
      items: items,
    };









    let items2 = []
    let datasetsData2 = []
    try {
      items2 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: safeValue(data.Program["PKG-WT"][region]),
          percentage: (Number(safeValue(data.Program["PKG-WT"][region])) / Number(safeValue(data.Program["PKG-WT"]["Toplam"]))) * 100
        }
      }));


      datasetsData2 = regions.map(region => Number(safeValueWithTryCatch(data.Program["PKG-WT"][region]) || 0)); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items2 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: 0,
          percentage: 0,
        }
      }));


      const datasetsData2 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }


    const reportsBarChartData2 = {
      chart: {
        labels: regions,
        datasets: { label: "Sayı", data: datasetsData2 },
      },
      items: items2,
    };
















    let items3 = []
    let datasetsData3 = []
    try {
      items3 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: safeValue(data.Haber["LIVE"][region]),
          percentage: (Number(safeValue(data.Haber["LIVE"][region])) / Number(data.Haber["LIVE"]["Toplam"])) * 100
        }
      }));

      datasetsData3 = regions.map(region => Number(safeValueWithTryCatch(data.Haber["LIVE"][region]) || 0)); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items3 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: 0,
          percentage: 0,
        }
      }));


      datasetsData3 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }

    const reportsBarChartData3 = {
      chart: {
        labels: regions,
        datasets: { label: "Sayı", data: datasetsData3 },
      },
      items: items3,
    };









    let items4 = []
    let datasetsData4 = []
    try {
      items4 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: safeValue(data.Program["LIVE"][region]),
          percentage: (Number(safeValue(data.Program["LIVE"][region])) / Number(safeValue(data.Program["LIVE"]["Toplam"]))) * 100
        }
      }));


      datasetsData4 = regions.map(region => Number(safeValueWithTryCatch(data.Program["LIVE"][region]) || 0)); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items4 = regions.map(region => ({
        icon: { color: "info", component: "library_books" },
        label: region,
        progress: {
          content: 0,
          percentage: 0,
        }
      }));


      const datasetsData4 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }


    const reportsBarChartData4 = {
      chart: {
        labels: regions,
        datasets: { label: "Sayı", data: datasetsData4 },
      },
      items: items4,
    };




















    let items5 = []
    let datasetsData5 = []
    try {
      items5 = data.haber_icerikler.map(item => ({
        icon: { color: "info", component: "library_books" },
        label: item.haber_icerik,
        progress: {
          content: item.count,
          percentage: Number(Number(item.count) / Number(data.toplam) * 100),
        }
      }));

      datasetsData5 = data.haber_icerikler.map(item => item.count); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items5 = data.haber_icerikler.map(item => ({
        icon: { color: "info", component: "library_books" },
        label: item.haber_icerik, // doğru erişim: item.haber_icerik
        progress: {
          content: item.count, // doğru erişim: item.count
          percentage: 0, // Burada yüzde hesabı nasıl yapılacağına dair bilgi vermediğiniz için 0 olarak bıraktım.
        }
      }));


      datasetsData5 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }

    const reportsBarChartData5 = {
      chart: {
        labels: data.haber_icerikler.map(item => item.haber_icerik),
        datasets: { label: "Sayı", data: datasetsData5 },
      },
      items: items5,
    };









    let items6 = []
    let datasetsData6 = []
    try {
      items6 = data.muhabir_top.map(item => ({
        icon: { color: "info", component: "library_books" },
        label: item.haberi_yapan_isim,
        progress: {
          content: item.count,
          percentage: Number(Number(item.count) / Number(data.toplam) * 100),
        }
      }));

      datasetsData6 = data.muhabir_top.map(item => item.count); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items6 = data.muhabir_top.map(item => ({
        icon: { color: "info", component: "library_books" },
        label: item.haberi_yapan_isim, // doğru erişim: item.haber_icerik
        progress: {
          content: item.count, // doğru erişim: item.count
          percentage: 0, // Burada yüzde hesabı nasıl yapılacağına dair bilgi vermediğiniz için 0 olarak bıraktım.
        }
      }));


      datasetsData6 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }

    const reportsBarChartData6 = {
      chart: {
        labels: data.muhabir_top.map(item => item.haberi_yapan_isim),
        datasets: { label: "Sayı", data: datasetsData6 },
      },
      items: items6,
    };


    return (
      <>
        <SoftBox mb={3} >
          <Grid container spacing={3}>
            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart
                  title="Haber Grafiği (PKG-WT)"
                  description={
                    <>
                      Toplam (<strong>{safeDeepValue(data, 'Haber.PKG-WT.Toplam')}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData.chart}
                  items={reportsBarChartData.items}
                />
              </Card>

            </Grid>
            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart color="secondary"
                  title="Program Grafiği (PKG-WT)"
                  description={
                    <>
                      Toplam (<strong>{safeDeepValue(data, 'Program.PKG-WT.Toplam')}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData2.chart}
                  items={reportsBarChartData2.items}
                />
              </Card>

            </Grid>



            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart
                  title="Haber Grafiği (Live)"
                  description={
                    <>
                      Toplam (<strong>{safeDeepValue(data, 'Haber.LIVE.Toplam')}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData3.chart}
                  items={reportsBarChartData3.items}
                />
              </Card>

            </Grid>
            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart color="secondary"
                  title="Program Grafiği (Live)"
                  description={
                    <>
                      Toplam (<strong>{safeDeepValue(data, 'Program.LIVE.Toplam')}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData4.chart}
                  items={reportsBarChartData4.items}
                />
              </Card>

            </Grid>











            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart color="info"
                  title="İçerik Grafiği "
                  description={
                    <>
                      Toplam (<strong>{safeValue(data.toplam)}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData5.chart}
                  items={reportsBarChartData5.items}
                />
              </Card>

            </Grid>
            <Grid item xs={6} md={6} xl={6}>
              <Card>
                <ReportsBarChart color="info"
                  title="En İyi Muhabirler"
                  description={
                    <>
                      Toplam (<strong>{safeValue(data.toplam)}</strong>) içerik
                    </>
                  }
                  chart={reportsBarChartData6.chart}
                  items={reportsBarChartData6.items}
                />
              </Card>

            </Grid>









            <Grid item xs={12} md={12} xl={12}>


            </Grid>

            <Grid item xs={12} md={12} xl={12}>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftTypography variant="h6">Haber Paketleri Listesi</SoftTypography>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
                </SoftBox>
              </Card>

            </Grid>
          </Grid>
        </SoftBox>
      </>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <PermissionNavbar/>
      </DashboardLayout>
    );
  }
}

export default Tables;
