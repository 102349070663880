// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    width: 100%;
    margin-top: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.menu-title {
    width: 80%;
    margin-bottom: 0px;
    margin-top: 55px;
    font-weight: 600;
    font-size: 15px;
}

.eatlist-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding-bottom: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/search/Content/Content.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".content {\n    width: 100%;\n    margin-top: 125px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.menu-title {\n    width: 80%;\n    margin-bottom: 0px;\n    margin-top: 55px;\n    font-weight: 600;\n    font-size: 15px;\n}\n\n.eatlist-items {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    overflow: hidden;\n    padding-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
