import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faSplotch, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import placeIcon from "assets/images/logos/icon-place.png";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-food.png";

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
//mport { useMediaQuery } from 'react-responsive';

/* eslint-disable react/prop-types */

function Header(props) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const theme = useTheme();
  const { functions, palette } = theme;
  const { rgba, linearGradient } = functions;
  const { gradients } = palette;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("horizontal")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    props.onChange(newValue);
  };

  const handleButtonClick = () => {
    window.location.href = `/cv/${props.data.id}`;
  };

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));


  const eatlistCover = "https://static.ticimax.cloud/9247/uploads/urunresimleri/buyuk/hamburger-180c.jpg"

  return (
    <SoftBox position="relative" style={{ textAlign: "-webkit-center" }}>

      {/* {!props.data || props.data.result?.in_list_count <= 0 ? (
    <DashboardNavbar absolute light data={props.data} />
  ) : (
    <DashboardNavbar absolute dark data={props.data} />
  )} */}



      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="23.75rem"
        borderRadius="xl"
        margin="-70px"



        sx={{
          backgroundImage: !props.data.result
            ? linearGradient(
              rgba(gradients.flatwhite.main, 1.0),
              rgba(gradients.flatwhite.state, 1.0)
            )
            : props.data?.result?.in_list_count > 0
              ? linearGradient(
                rgba(gradients.flatwhite.main, 1.0),
                rgba(gradients.flatwhite.state, 1.0)
              )
              : `${linearGradient(
                rgba(gradients.flatwhite.main, 1.0),
                rgba(gradients.flatwhite.state, 1.0)
              )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          background: "repeating-linear-gradient(82deg, #efefef, transparent 25%)",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={"warning"}
          color="white"
          width="9rem"
          height="9rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="30px !important" style={{ fontSize: "60px" }}>{"route"}</Icon>
        </SoftBox>
        {/* <img
          src={"eatlistCover"}
          alt="Background Image"
          style={{
            position: "absolute",
            width: "110%",
            objectFit: "cover",
            filter: isXs ? "blur(105px) saturate(2.5)" : "blur(105px) saturate(1.2)",
            transform: "rotate(180deg)",
            opacity: 0.87,
            height: "110%",
            height: isXs ? "300%" : "600%",
          }}
          filter={{xs:"blur(105px) saturate(2.5)", sm:"blur(105px) saturate(1.5)"}}
        />

        
        <img
          src={eatlistCover}
          alt="Header Image"
          style={{
            maxWidth: "70%",
            maxHeight: "260px",
            boxShadow: "rgba(0, 0, 0, 0.96) 0px 5px 50px 0px",
            zIndex: 1,
            aspectRatio: "1",
            objectFit: "cover",
            borderRadius: "8px",
            width: "260px",
          }}
        /> */}

      </SoftBox>
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">


          <Grid item style={{ width: "100%" }}>
            <SoftBox height="100%" mt={0} lineHeight={1}>



              <SoftTypography variant="h5" fontWeight="medium" style={{ marginTop: "10px" }}>
                {props.data?.name}
              </SoftTypography>

              <a href={"/foodie/" + props.data?.foodie?.username} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5px" }}>


                  <SoftAvatar
                    src={window.BASE_URL + "/" + props.data?.foodie?.foodie_profilephoto}
                    alt="profile-image"
                    variant="soft"
                    size="xs"
                    shadow="sm"


                  />
                  <SoftTypography variant="button" color="text" fontWeight="medium" ml={1}>
                    {props.data?.foodie?.full_name}
                  </SoftTypography>
                </Grid>
              </a>
            </SoftBox>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto", marginBlock: "0px" }}>
            {/* <SoftButton id="printButton" onClick={() => handleSetTabValue(10)} color="dark" variant="gradient" style={{paddingInline: "calc(20% + 80px)", maxWidth:"10px"}}>
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                bookmark
              </Icon>  Save
            </SoftButton> */}
          </Grid>


        </Grid>
      </Card>


      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -2,
          mx: 3,
          py: 2,
          px: 2,
          width: "70%",
          alignItems: "center",
          justifyContent: "center",

        }}
      >
        <Grid container spacing={3} alignItems="center">

          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                <Tab value={0} label={isXs ? "" : "General"} icon={<Cube />} />
                <Tab value={1} label={isXs ? "" : "Menu"} icon={<FontAwesomeIcon icon={faBook} />} />
                <Tab value={2} label={isXs ? "" : "Rating"} icon={<FontAwesomeIcon icon={faSplotch} />} />
                <Tab value={3} label={isXs ? "" : "Social"} icon={<FontAwesomeIcon icon={faComments} />} />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>


    </SoftBox>
  );
}

export default Header;
