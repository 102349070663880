import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// import ProfileCardSpecial from "examples/ProfileCards/ProfileCardSpecial";
// import ProfileCardSpecialEatlist from "examples/ProfileCards/ProfileCardSpecialEatlist";
// import ProfileCardSpecialRating from "examples/ProfileCards/ProfileCardSpecialRating";
// import ProfileCardKurum from "examples/ProfileCards/ProfileCardKurum";
// import ProfileCardKurumSema from "examples/ProfileCards/ProfileCardKurumSema";
// import ProfileCardEgitim from "examples/ProfileCards/ProfileCardEgitim";
// import ProfileCardIzinler from "examples/ProfileCards/ProfileCardIzinler";
// import ProfileCardTerfi from "examples/ProfileCards/ProfileCardTerfi";
// import ProfileCardZimmet from "examples/ProfileCards/ProfileCardZimmet";
// import ProfileCardZimmetBack from "examples/ProfileCards/ProfileCardZimmetBack";
// import ProfileCardNot from "examples/ProfileCards/ProfileCardNot";
// import ProfileCardDosya from "examples/ProfileCards/ProfileCardDosya";
// import ProfileCardDisiplin from "examples/ProfileCards/ProfileCardDisiplin";
// import ProfileCardDisiplinVerilen from "examples/ProfileCards/ProfileCardDisiplinVerilen";
// import ProfileCardPerformans from "examples/ProfileCards/ProfileCardPerformansInews";

// Overview page components
import Header from "layouts/eatlist/components/Header";
import NewRate from "layouts/eatlist/components/new-rate";

import MenuPlace from "layouts/eatlist/components/menu-place"


// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { Button } from "@mui/material";
import { useState , useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Overview() {
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (index) => {
    setTabIndex(index);
  }
  const { id, username, view } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  // if (id == ":id"){
  //   window.location.href = '/profile/2'
  //   //navigate('/profile/2');
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response 
        if (view!=null){ 
           response = await axios.get(`${window.BASE_URL}/highlight/${username}/${view}`, { withCredentials: true });
        }
        else{
           response = await axios.get(`${window.BASE_URL}/eatlist/${id}/`, { withCredentials: true });
        }
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [view]);


  return (
    <DashboardLayout>
      <DashboardNavbar onlyMobile={true} />
      <Header onChange={e => onTabChange(e)} data={data} />
      <SoftBox mt={5} mb={9}>
        <Grid container spacing={3}>


          {tabIndex === 0 && (
            <>
              <MenuPlace data={data} />
              
            </>
          )}
          {tabIndex === 1 && (
            <>
              <MenuPlace data={data} />
              
            </>
          )}
          {tabIndex === 2 && (
            <>
              {/* <ProfileCardEgitim data={data} /> */}
              
            </>
          )}

          {tabIndex === 3 && ( 
            <>
              {/* <ProfileCardTerfi data={data} /> */}
              
            </>
          )}



          {tabIndex === 10 && (
            <>
              <NewRate data={data} />
              
            </>
          )}



        </Grid>
      </SoftBox>
      

     
    </DashboardLayout>
  );
}

export default Overview;
