import { useMemo } from "react";

import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from '@mui/styles';

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';


// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import TimelineItem from "examples/Timeline/TimelineItem";
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SoftButton from "components/SoftButton";
import './style.css';


const useStyles2 = makeStyles({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '100px',
      height: '100px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      '&:hover': {
        backgroundColor: '#555',
      },
    },
  },
});

// Add custom styles for sticky header
const useStyles = makeStyles({
  stickyColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: 'white !important', // adjust based on your theme
    zIndex: 1020, // to ensure it's above other content
  },
  stickySecondColumn: {
    //position: 'sticky',
    //left: '150px', // adjust this based on the width of your first column
    backgroundColor: 'white !important', // adjust based on your theme
    //zIndex: 1030, // slightly less than the first column
  },
});

function Table({ columns, rows, edit }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [isFilterBoxVisible, setIsFilterBoxVisible] = useState(false);

  const handleFilterButtonClick = () => {
    setIsFilterBoxVisible(prevIsFilterBoxVisible => {
      //console.log(prevIsFilterBoxVisible);
      const newIsFilterBoxVisible = !prevIsFilterBoxVisible;
      //console.log(newIsFilterBoxVisible);
      return newIsFilterBoxVisible;
    });
  };

  const [isPinBoxVisible, setIsPinBoxVisible] = useState(false);

  const handlePinButtonClick = () => {
    setIsPinBoxVisible(prevIsPinBoxVisible => {
      const newIsPinBoxVisible = !prevIsPinBoxVisible;
      return newIsPinBoxVisible;
    });
  };


  console.log(isFilterBoxVisible);
  console.log(isPinBoxVisible);


  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };



  const [filterField, setFilterField] = useState(""); // Filtre yapılacak alan
  const [filterValue, setFilterValue] = useState(""); // Filtre değeri

  const handleFilterFieldChange = (event) => {
    setFilterField(event.target.value);
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
  };


  const [filterColumn, setFilterColumn] = useState("");

  const handleFilterColumnChange = (event) => {
    setFilterColumn(event.target.value);
  };



  function defaultLabelDisplayedRows({ from, to, count }) {
    const text = `${from}–${to}  (${count !== -1 ? count : `${to}+`} Toplam)`;
    return (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {text}
      </SoftTypography>
    );
  }


  let filteredRows = useMemo(() => {





    if (!search) {
      return rows;
    }
    return rows.filter((row) => {
      return columns.some((column) => {
        if (!column.searchable && column.searchable !== undefined) {
          return false;
        }
        let searchFields = Array.isArray(column.searchField) ? column.searchField : [column.searchField || column.name];
        return searchFields.some(field => {
          let value = row[field];
          if (value == null) {
            return false;
          }
          if (React.isValidElement(value) && typeof value.props.children === 'string') {
            value = value.props.children;
          } else if (Array.isArray(value)) {
            value = value.join(" ");
          } else {
            value = value.toString();
          }
          return value.toLocaleLowerCase('tr-TR').includes(search.toLocaleLowerCase('tr-TR'));
        });
      });
    });
  }, [search, rows, columns]);

  let finalFilteredRows = useMemo(() => {
    if (!filterValue || !filterColumn || !columns.find(c => c.name === filterColumn)) {
      return filteredRows;
    }

    const filterColumnData = columns.find(c => c.name === filterColumn);

    return filteredRows.filter(row => {
      let searchFields = Array.isArray(filterColumnData.searchField)
        ? filterColumnData.searchField
        : [filterColumnData.searchField || filterColumn];

      return searchFields.some(field => {
        let value = row[field];
        if (value == null) {
          return false;
        }
        if (React.isValidElement(value) && typeof value.props.children === 'string') {
          value = value.props.children;
        } else if (Array.isArray(value)) {
          value = value.join(" ");
        } else {
          value = value.toString();
        }
        return value.toLowerCase().includes(filterValue.toLowerCase());
      });
    });
  }, [filteredRows, filterValue, filterColumn, columns]);



  filteredRows = finalFilteredRows;

  const paginatedRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const renderColumns = columns.map(({ name, align, width, altName }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    let className = "";
    if (isPinBoxVisible) {
      if (key === 0) {
        className = classes.stickyColumn;
      } else if (key === 1) {
        className = classes.stickySecondColumn;
      }
    }

    return (
      <SoftBox
        key={name}
        component="th"
        className={className}
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.9}
        filter='blur(5px)'
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {(altName || name).toUpperCase()}
      </SoftBox>
    );
  });


  function extractTextFromReactElement(element) {
    if (typeof element === 'string' || typeof element === 'number') {
      return element.toString();
    }

    if (React.isValidElement(element) && element.props.children) {
      return React.Children.map(element.props.children, child => extractTextFromReactElement(child)).join('');
    }

    return '';
  }

  function directToEditPage(link) {
    //window.location.href = `${window.BASE_URL}${edit}`;
    window.open(`${window.BASE_URL}${edit}`, '_blank');
  }

  function downloadExcel() {
    const processedRows = filteredRows.map(row => {
      const newRow = {};
      columns.forEach(column => {
        if (column.exportableField && row[column.exportableField] !== undefined) {
          newRow[column.name] = row[column.exportableField].toString();
        } else {
          newRow[column.name] = extractTextFromReactElement(row[column.name]);
        }
      });
      return newRow;
    });

    const ws = XLSX.utils.json_to_sheet(processedRows);

    // En üst başlık satırını al ve tüm hücreleri kalın yap
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + "1"; // 1. satırdaki hücreler
      if (!ws[address]) continue;
      ws[address].s = {
        font: {
          bold: true
        }
      };
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const buf = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(new Blob([buf], { type: "application/octet-stream" }), "Tablo.xlsx");
  }




  const renderRows = paginatedRows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }, index) => {
      let template;
      let className = "";
      if (isPinBoxVisible) {
        if (index === 0) {
          className = classes.stickyColumn;
        } else if (index === 1) {
          className = classes.stickySecondColumn;
        }
      }
      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            className={className}
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            className={className}
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  function renderLabel() {
    return (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        Satır sayısı
      </SoftTypography>
    );
  }

  const classes2 = useStyles2();

  return useMemo(
    () => (
      <>




        <SoftBox mt={5} mb={3} sx={{ display: 'flex', alignItems: 'center' }}>



          <SoftInput
            placeholder="Yazınız..."
            icon={{ component: "search", direction: "left" }}
            value={search}
            onChange={handleSearchChange}
            sx={{ flexGrow: 1, ml: 2, marginleft: "10px" }}

          />


          <SoftButton onClick={handleFilterButtonClick} sx={{ ml: 2 }} color={isFilterBoxVisible ? "dark" : "white"}>
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
              filter_alt
            </Icon>
          </SoftButton>


          <SoftButton onClick={handlePinButtonClick} sx={{ ml: 2 }} color={isPinBoxVisible ? "dark" : "white"}>
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
              push_pin
            </Icon>
          </SoftButton>

          <SoftButton onClick={downloadExcel} sx={{ ml: 2 }} >
            <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
              get_app
            </Icon> Excele Aktar
          </SoftButton>




          {
            edit !== null &&
            <SoftButton onClick={() => directToEditPage(edit)} sx={{ ml: 2 }} >
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" style={{ color: "dodgerblue" }}>
                https
              </Icon>
              Düzenle
            </SoftButton>
          }




        </SoftBox>



        {isFilterBoxVisible && (
          <>
            <SoftBox
              component="th"
              width="auto"
              pt={0.5}
              pb={0}
              pl={2.75}
              pr="center"
              textAlign="center"
              fontSize={size.xxs}
              fontWeight={fontWeightBold}
              color="secondary"
              opacity={0.7}
              height={-10}
            >
              FILTRELEME
            </SoftBox>
            <SoftBox mt={5} mb={3} sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              {/* Mevcut arama kutusu ve "Excele Aktar" butonu burada yer alıyor... */}



              <Select
                icon={{ component: "search", direction: "left" }}
                value={filterColumn}
                onChange={handleFilterColumnChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ ml: 2, minWidth: 120, display: 'inline-flex' }}
              >

                <MenuItem value="" disabled>
                  <SoftBox sx={{ display: 'flex', alignItems: 'center' }} color="secondary">
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold", marginRight: 1 }} fontSize="small" color="secondary">
                      filter_alt
                    </Icon>
                    <em>Sutün Seçin</em>
                  </SoftBox>
                </MenuItem>
                {columns.map((column, index) => (
                  <MenuItem key={index} value={column.name}>
                    {column.name}
                  </MenuItem>
                ))}
              </Select>

              <SoftInput
                placeholder="Filtrele..."
                value={filterValue}
                onChange={handleFilterValueChange}
                sx={{ flexGrow: 1, ml: 2 }}
              />


            </SoftBox>
          </>
        )}


<TableContainer component={Paper} className="custom-scrollbar" style={{ maxHeight: '400px' }}>
          <MuiTable stickyHeader>
            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          component="div"
          SelectProps={{
            sx: {
              width: '70px !important',
              // Bu satırı ekleyerek taşmayı engelleyebiliriz.
            }
          }}
          sx={{ fontSize: '12px' }}  // Bu satırı da ekleyerek taşmayı engelleyebiliriz.
          style={{ fontSize: '12px', width: '100%' }}  // Bu satırı da ekleyerek taşmayı engelleyebiliriz.
          count={filteredRows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={defaultLabelDisplayedRows}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 10, 25, { label: 'Hepsi', value: 9999999 }]}
          labelRowsPerPage={renderLabel()}  // "Rows per page:" yazısını "Satır sayısı:" olarak değiştirebilirsiniz.
        />

      </>
    ),
    [columns, rows, page, rowsPerPage, search, filterColumn, filterValue, isFilterBoxVisible, isPinBoxVisible]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
  edit: null,
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  edit: PropTypes.string,
};

export default Table;
