
import { useState } from "react";
/* eslint-disable react/prop-types */
import axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import ProductInfo from "layouts/place/components/new-food/components/ProductInfo";
import Media from "layouts/place/components/new-food/components/Media";
import AddList from "layouts/place/components/new-food/components/AddList";
import Pricing from "layouts/place/components/new-food/components/Pricing";

import { useParams, useNavigate } from 'react-router-dom';

function getSteps() {
  return ["1. Description", "2. Media", "3. Add List", "4. Other"];
}


function getStepContent(stepIndex, handleFormChange) {
  switch (stepIndex) {
    case 0:
      return <ProductInfo onFormChange={handleFormChange} />;
    case 1:
      return <Media />;
    case 2:
      return <AddList />;
    case 3:
      return <Pricing />;
    default:
      return null;
  }
}

function NewProduct(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const { id } = useParams();

  const handleFormChange = (newData) => setFormData(newData);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const restaurant_name = props.data.result.name;

  const handleSubmit = async () => {
    try {
      const dataToSubmit = { ...formData, id, restaurant_name };  // Add id to formData
      const response = await axios.post(`${window.BASE_URL}/custom-food/submit`, dataToSubmit, { withCredentials: true });
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  return (

        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Card sx={{ overflow: "visible" }}>
              <SoftBox p={2}>
                <SoftBox>
                  {getStepContent(activeStep,handleFormChange)}
                  <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <SoftBox />
                    ) : (
                      <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                        back
                      </SoftButton>
                    )}
                    <SoftButton
                      variant="gradient"
                      color="dark"
                      onClick={!isLastStep ? handleNext : handleSubmit}
                    >
                      {isLastStep ? "send" : "next"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
  );
}



export default NewProduct;
