import { useState } from "react";
import axios from "axios";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved-food.png";
import { useTranslation } from 'react-i18next';

function SignUp() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [agreement, setAgreement] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSetAgreement = () => setAgreement(!agreement);

  const handleSignUp = async () => {
    const { username, email, password, confirmPassword } = formData;

    if (!agreement) {
      alert("You must agree to the Terms and Conditions.");
      return;
    }

    if (password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);

      const response = await axios.post(`${window.BASE_URL}/api/register`, {
        username,
        email,
        password,
        full_name: username
      });

      if (response.status === 201) {
        window.location.href = "/authentication/sign-in";
      } else {
        alert("Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Sign-up error:", error.message);
      alert("An error occurred during registration. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BasicLayout
      //title="Welcome!"
      //description="Use these awesome forms to login or create new account in your project for free."
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            {t("auth.form.title.registerwith")}
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <Socials />
        </SoftBox>
        <Divider />
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mb={2}>
              <SoftInput
                name="username"
                placeholder={t("auth.form.placeholder.username")}
                value={formData.username}
                onChange={(e) => {
                  const sanitizedValue = e.target.value.toLowerCase().replace(/\s+/g, ""); // Büyük harf ve boşlukları kaldırır
                  handleInputChange({
                    target: {
                      name: e.target.name,
                      value: sanitizedValue,
                    },
                  });
                }}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                name="email"
                type="email"
                placeholder={t("auth.form.placeholder.email")}
                value={formData.email}
                onChange={handleInputChange}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                name="password"
                type="password"
                placeholder={t("auth.form.placeholder.password")}
                value={formData.password}
                onChange={handleInputChange}
              />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput
                name="confirmPassword"
                type="password"
                placeholder={t("auth.form.placeholder.confirmpass")}
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgreement} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgreement}
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                &nbsp;&nbsp;{t("auth.form.title.agree")}&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                {t("auth.form.title.terms")}
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="dark"
                fullWidth
                onClick={handleSignUp}
                disabled={loading}
              >
                {loading ? t("auth.title.signup.active") : t("auth.title.signup")}
              </SoftButton>
            </SoftBox>
            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {t("auth.form.title.already")}&nbsp;
                <SoftTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  {t("auth.title.signin")}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>

    </BasicLayout>
  );
}

export default SignUp;
