/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { useMemo } from "react";

import React, { useState } from "react";
import TablePagination from "@mui/material/TablePagination";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from '@mui/styles';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";


const useStyles = makeStyles({
  select: {
    width: '20% !important',
  },
});

function Table({ columns, rows, rowdetails }) {


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function defaultLabelDisplayedRows({ from, to, count }) {
    const text = `${from}–${to}  (${count !== -1 ? count : `${to}+`} Toplam)`;
    return (
        text
    );
  }
  
  const paginatedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {name.toUpperCase()}
      </SoftBox>
    );
  });



  const renderRows = paginatedRows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (

          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    const detailAccordion = (
      <TableRow >
        <SoftBox component="td" colSpan={columns.length} >
          <Accordion sx={{ border: 'none', boxShadow: 'none' , marginTop: '-50px', background: 'transparent'}} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <SoftTypography variant="button" fontWeight="regular" color="secondary" >
                
              </SoftTypography>
            </AccordionSummary>
            <AccordionDetails >
              <SoftTypography variant="body2" sx={{ marginTop: '-30px', borderBottom: '0.0625rem solid #ffffff' }}>
                {row.detail} 
              </SoftTypography>
            </AccordionDetails>
          </Accordion>
        </SoftBox>
      </TableRow>
    );

    return (
      <React.Fragment key={rowKey}>
        <TableRow>{tableRow}</TableRow>
        {detailAccordion}
      </React.Fragment>
    );
  });
  const classes = useStyles();
  return useMemo(
    () => (
      <>
        <TableContainer>
          <MuiTable>

            <SoftBox component="thead">
              <TableRow>{renderColumns}</TableRow>
            </SoftBox>
            <TableBody>{renderRows}</TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          component="div"
          SelectProps={{
            sx: { width: '20%' }
          }}
          sx={{ fontSize: '12px' }}
          style={{ fontSize: '12px', width: '100%' }}
          count={rows.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={defaultLabelDisplayedRows}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: 9999999 }]}
        />
      </>
    ),
    [columns, rows, page, rowsPerPage]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
