import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React icons
import Settings from "examples/Icons/Settings";
import Cube from "examples/Icons/Cube";
import SpaceShip from "examples/Icons/SpaceShip";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHeart, faHouse, faIdCard, faLocationDot, faNoteSticky, faPen, faPenToSquare, faPersonWalkingLuggage, faSplotch, faStar, faStarHalfStroke, faThumbsUp, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'


function Account() {

  const [selected, setSelected] = useState(null); // Tek bir durum değişkeni


  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, dark, secondary, light },
  }) => ({
    width: pxToRem(150),
    height: pxToRem(120),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa": {
      blockSize: "27px !important",
    },

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${light.main} !important`,

      "& svg g": {
        fill: rgba(dark.main, 0.75),
        fontSize: "24px",

      },
    },
  });

  const iconcolor = "white"

  return (
    <SoftBox mb={12} mt={6}>
      <SoftBox width="80%" textAlign="center" mx="auto" mb={8} mt={3}>
        <SoftBox mb={1} mt={3}>
          <SoftTypography variant="h5" fontWeight="regular">
            Discover Your Interests
          </SoftTypography>
        </SoftBox>
        <SoftTypography variant="body2" fontWeight="regular" color="text">
          Let us know what excites you the most. Share your favorite activities and make the experience truly yours.
        </SoftTypography>
      </SoftBox>

      <SoftBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                color={selected === "favorite" ? "primary" : "light"}
                variant={selected === "favorite" ? "gradient" : "outlined"}
                onClick={() => setSelected(selected === "favorite" ? null : "favorite")} // Toggle
                sx={customButtonStyles}
              >
                <FontAwesomeIcon
                  icon={faHeart}
                  style={{ color: selected === "favorite" ? "white" : "#70747a" }}
                />
              </SoftButton>
              <SoftTypography variant="h6">Favorite</SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SoftBox textAlign="center">
              <SoftButton
                color={selected === "pitstop" ? "info" : "light"}
                variant={selected === "pitstop" ? "gradient" : "outlined"}
                onClick={() => setSelected(selected === "pitstop" ? null : "pitstop")} // Toggle
                sx={customButtonStyles}
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  style={{ color: selected === "pitstop" ? "white" : "#70747a" }}
                />
              </SoftButton>
              <SoftTypography variant="h6">Pit Stop</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default Account;
