import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';

import pinIcon from "assets/images/small-logos/Pin-location.png";
/* eslint-disable react/prop-types */

const Map = ({ data }) => {
  const [response, setResponse] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [center, setCenter] = useState({ lat: 41.0082, lng: 28.9784 }); // Varsayılan konum

  const mapStyles = {
    height: '100vh',
    width: '100%',
    outline: 'none'
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false,
    draggable: true,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    keyboardShortcuts: false,
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#c9c9c9" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#434343" }],
      },
      {
        featureType: "landscape",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ visibility: "off" }],
      },
    ]
  };

  const directionsCallback = useCallback((res) => {
    if (res !== null) {
      if (res.status === 'OK') {
        setResponse(res);
      } else {
        console.log('response: ', res);
      }
    }
  }, []);

  const waypoints = data?.route_list_items
    ? data.route_list_items
        .filter(item => !isNaN(parseFloat(item.item.lat)) && !isNaN(parseFloat(item.item.lng)))
        .map(item => ({
          location: { lat: parseFloat(item.item.lat), lng: parseFloat(item.item.lng) },
          stopover: true,
        }))
    : [];

  useEffect(() => {
    if (waypoints.length > 0) {
      setCenter(waypoints[0].location); // İlk waypoint'i merkez olarak ayarla
    }
  }, [waypoints]);

  const origin = waypoints[0]?.location || center;
  const destination = waypoints[waypoints.length - 1]?.location || center;

  useEffect(() => {
    if (response === null && origin && destination && waypoints.length > 1) {
      setResponse(null);
    }
  }, [origin, destination, waypoints, response]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyD76O6M6qnbZrGbfvU9e6btN26PaMhcY0I" // Gerçek API anahtarınızı buraya ekleyin
      onLoad={() => setIsLoaded(true)}
    >
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={13}
          center={center}
          options={options}
          tabIndex={-1}
        >
          {waypoints.map((waypoint, index) => (
            <Marker
              key={index}
              position={waypoint.location}
              icon={{
                url: pinIcon,
                scaledSize: new window.google.maps.Size(32, 32),
              }}
              title={`Point ${index + 1}`}
            />
          ))}

          {/* DirectionsService sadece response boş olduğunda çağrılır */}
          {origin && destination && waypoints.length > 1 && !response && (
            <DirectionsService
              options={{
                origin: origin,
                destination: destination,
                waypoints: waypoints.slice(1, -1),
                travelMode: 'WALKING',
              }}
              callback={directionsCallback}
            />
          )}

          {response && (
            <DirectionsRenderer
              options={{
                directions: response,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: '#434343',
                  strokeOpacity: 0.6,
                  strokeWeight: 6,
                },
              }}
            />
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default Map;
