import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import ProfilesList from 'examples/Lists/ProfilesList';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";


import Stories from "examples/Stories/PhotoStories";
import EatlistCards from "examples/EatlistCards";
import RouteCards from "examples/RouteCards";
import RatingCards from "examples/RatingCards";

/* eslint-disable react/prop-types */

const index = (data) => {
  const safeValue = (value) => value === null ? "-" : value;



  const SectionTitle = ({ title, style }) => (
    <h3 className="section-title" style={{ marginLeft: "60px" }}>{title}</h3>
  );

  return (



    <SoftBox position="relative">
        

          <SectionTitle title="Latest Stories" />

          <Stories storiesData={data?.data?.stories} />

          <SectionTitle title="Latest Eatlists" />

          <EatlistCards eatlistData={data?.data?.eatlists} />

          <SectionTitle title="Latest Ratings" />

          <RatingCards placeData={data?.data?.ratings} />

          {/* <SectionTitle title="Latest Routes" />

          <RouteCards eatlistData={[]} /> */}

        </SoftBox>
       
      



  )
}

export default index
