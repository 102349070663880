import { forwardRef } from "react";
import PropTypes from "prop-types";
import SoftAvatarRoot from "components/SoftAvatar/SoftAvatarRoot";
import Icon from "@mui/material/Icon";
/* eslint-disable react/prop-types */

const SoftAvatar = forwardRef(({ bgColor, size, shadow, icon, text, iconColor, iconSize, ...rest }, ref) => {
  // Varsayılan imgProps'u tanımla
  const defaultImgProps = {
    style: { objectFit: 'cover', height: '100%', width: '100%' }
  };

  iconColor = iconColor ? iconColor : "white"

  // Eğer rest içinde imgProps varsa, onu defaultImgProps ile birleştir
  const imgProps = rest.imgProps
    ? { ...defaultImgProps, ...rest.imgProps }
    : defaultImgProps;

  const gradientStyle = {
    //background: 'linear-gradient(310deg, rgb(34, 107, 255), rgb(33, 212, 253))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    borderRadius: 'inherit',

  };

  return (
    <SoftAvatarRoot
      ref={ref}
      ownerState={{ shadow, bgColor, size }}
      {...rest}
    >
      {icon ? (
        <div style={gradientStyle}>
          <Icon fontSize={"50px"} style={{ fontSize: "50px !important", color: bgColor != "flatwhite" ? iconColor : "rgb(112 116 123)" }} sx={iconSize ? { fontSize: iconSize } : {}}
          >{icon}</Icon>
          {text ? <span style={{ fontSize: "15px", color: bgColor != "flatwhite" ? iconColor : "rgb(112 116 123)" }}>{text}</span> : ""}

        </div>
      ) : null}
    </SoftAvatarRoot>
  );
});

// SoftAvatar bileşeni için varsayılan prop değerlerini ayarlama
SoftAvatar.defaultProps = {
  bgColor: "transparent",
  size: "md",
  shadow: "none",
  icon: null,
};

// SoftAvatar bileşeni için prop türlerini tanımlama
SoftAvatar.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
  icon: PropTypes.string,
  text: PropTypes.string,
};

export default SoftAvatar;
