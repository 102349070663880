// Gerekli kütüphaneler ve stil dosyaları
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import team5 from "assets/images/logos/food-icon-white-story2.png";

/* eslint-disable react/prop-types */

// Öneri kartları
function Stories({ storiesData }) {
  // Öneri kartlarını hazırlama
  const suggestions = storiesData?.nearby_stories?.map(story => ({
    background: window.BASE_URL + "/" + story.cover || team5, // Eğer cover varsa kullan, yoksa team5
    owner: story.foodie.full_name || 'No Story', // Foodie'nin adı
    distance: story.distance, // Mesafe
    count: story.interaction_count, // Count
    restaurantName: story.custom_restaurant ? story.custom_restaurant.name : story.restaurant ? story.restaurant.name : 'Unknown Restaurant' // Restoran adı
  })) || []; // Eğer storiesData undefined veya null ise boş bir dizi döndür

  // Öneri sayısını kontrol etme ve eksikleri doldurma
  const processedSuggestions = suggestions.length >= 6 ? suggestions.slice(0, 6) : [
    ...suggestions,
    ...Array(6 - suggestions.length).fill({
      background: team5,
      owner: '',
      distance: 0,
      count: '',
      restaurantName: 'No Story'
    })
  ];

  const loadContent = (content) => {
    // İçerik yükleme mantığı
    console.log(`Loading content for: ${content}`);
  };

  const showAlert = () => {
    // Uyarı mantığı
    alert('Suggestion card clicked');
  };

  const ScrollableSuggestions = () => (
    <div className="scrollable-suggestions" onClick={() => loadContent('eatlist')}>
      {processedSuggestions.map((suggestion, index) => (
        <SuggestionCard key={index} {...suggestion} />
      ))}
    </div>
  );

  const SuggestionCard = ({ background, owner, distance, restaurantName,count }) => (
    <div className="suggestion-card" style={{ backgroundImage: `url(${background})` }} onClick={showAlert}>
      <div className="suggestion-card-overlay">
        <p>{restaurantName}
          
          <div style={{display:"flex",flexDirection:"row",height: "12px"}}>{owner ? <SoftAvatar style={{width:"20px",height:"20px",color:"#ffffffc9",marginLeft: "-5px"}}/>: ""} <p style={{fontSize:"12px", fontWeight:"lighter",color:"#ffffffc9"}}> {owner}</p> </div>
          <p style={{textAlign:"end",marginRight:"-5px",marginTop:"-3px",fontSize:"13px"}}>{count}</p>
        
        </p>


      </div>
    </div>
  );

  return <ScrollableSuggestions />;
}

export default Stories;
