import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Çeviri dosyalarını yükler
  .use(LanguageDetector) // Tarayıcı dilini algılar
  .use(initReactI18next) // React entegrasyonu
  .init({
    supportedLngs: ['en', 'tr'], // Desteklenen diller
    fallbackLng: 'en', // Bulunamazsa varsayılan dil
    debug: true, // Geliştirme sırasında hata ayıklama
    interpolation: {
      escapeValue: false, // React zaten XSS koruması sağlıyor
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Çeviri dosyalarının yolu
    },
    react: {
        useSuspense: false
      }
  });

export default i18n;
