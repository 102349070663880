/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import SoftSelect from 'components/SoftSelect';
import ReactDOM from 'react-dom';
import 'examples/Alerts/alerts.css';
import restaurantIcon from 'assets/images/logos/food-icon-white.png';


// Şehir seçimi için bileşen
const ShowAlertContent = ({ setSelectedCity }) => {
  const [cities, setCities] = useState([]);


  return (
    <div className="story-custom-content">
      <img className="background-image blur-4" src="https://i.pinimg.com/736x/77/c5/10/77c510d44cbd18fad99360def0e73399.jpg" />
      <div className="story-overlay">
        <div className="story-profile-card">
          <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" className="story-profile-image" alt="Profile Image" />
          <div className="story-profile-info">
            <div style={{display:"flex", flexDirection:"row", alignItems: "center"}}>
            <div className="story-profile-name">Ömer Faruk Kışlakçı</div>
            <div className="story-profile-date">2 days ago</div>
            </div>
            <div className="story-profile-rating">5,0</div>
          </div>
        </div>
        <div className="story-profile-review">
          Girdiğim restoranlar arasından en iyisini seçmem gerekirse en iyisi burası diyebilmek için... <span className="story-more">More</span>
        </div>
      </div>
    </div>
  );
};

// Alert gösterimi için fonksiyon
export const showAlert = () => {
  const wrapper = document.createElement('div');
      let selectedCity = null;

      ReactDOM.render(<ShowAlertContent setSelectedCity={(value) => { selectedCity = value; }} />, wrapper);

      Swal.fire({
        html: wrapper,
      customClass: {
        container: 'swal2-container blur-4',
      popup: 'swal2-popup blur-4',
      confirmButton: 'swal2-confirm blur-4'
    },
      confirmButtonText: '<div class="custom-button-content"></div>',
      showConfirmButton: true,
    preConfirm: () => {
        return selectedCity;
    },
    didOpen: () => {
        document.querySelector('.swal2-html-container').classList.add('blur-4');
      document.querySelector('.swal2-actions').classList.add('blur-4');

      const confirmButtonContent = `
      <img src=${restaurantIcon} alt="icon" />
      <div>
        <div class="title">Kışlakçı Burger House</div>
        <div>
          <span class="rating">4,5</span>
          <span class="description">Hamburger restaurant</span>
        </div>
      </div>
      `;
      document.querySelector('.custom-button-content').innerHTML = confirmButtonContent;

    }
}).then((result) => {
    if (result.isConfirmed) {
        console.log('Selected city:', result.value);
    }
});

};
