import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompass, faMagnifyingGlass, faSquarePlus, faMapLocation, faUser, faPlus } from "@fortawesome/free-solid-svg-icons";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Icon from "@mui/material/Icon";


const Navbar = () => {
  const [activeIcon, setActiveIcon] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleIconClick = (iconName, route) => {
    setActiveIcon(iconName);
    navigate(route);
  };


  const handleSignInClick = () => {
    navigate('/authentication/sign-in');
  };

  const handleSignUpClick = () => {
    navigate('/authentication/sign-up');
  };

  return (
    <div
      style={{
        display: window.innerWidth < 1200 ? "flex" : "none",
        flexDirection: "column",
        overflowY: "scroll",
        WebkitOverflowScrolling: "touch",
        paddingBottom: "25px",
        position: "fixed",
        height: "130px",
        width: "100%",
        bottom: "0px",
        background: "linear-gradient(rgba(255, 255, 255, 0.96), rgb(248 249 250 / 60%))",
        borderRadius: "18px",
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        //maxWidth: "400px",
        gap: "40px",
        overflow: "visible",
        WebkitBackdropFilter: "blur(10px)",
        backdropFilter: "blur(10px)",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "1009090",
        boxShadow: "inset 0rem 0rem 0.0625rem .0625rem rgba(255, 255, 255, 0.9),0rem -0.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.1)",
      }}
    >

      <SoftButton
        id="printButton"
        onClick={handleSignInClick}
        color="warning"
        variant="gradient"
        fullWidth
        style={{ width: "75%", marginBottom: "-30px", marginTop: "5px", maxWidth: "400px" }}
      >
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
          login
        </Icon>
        &nbsp; Sign In
      </SoftButton>

      <SoftTypography
        variant="button"
        color="text"
        fontWeight="medium"
        onClick={handleSignUpClick}
        style={{ cursor: "pointer" }}
      >
        Create an account <b>Sign Up</b>
      </SoftTypography>

    </div>
  );
};

export default Navbar;
