/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);





  function Author({ image, name }) {
    return (
      <a href="/profile">
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox mr={2}>
            <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </a>
    );
  }


  const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/userlistmini?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  let egitimRows = data.map(index => {
    return {
      "Adı": [
        <img key="" src="12" alt="Logo" />,
        <span key="task1" style={truncatedTextStyle}> {index.name + " " + index.surname}  </span>
      ],
      "Pozisyon": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.trt_arabi_unvan}</SoftTypography>,
      "field_name": index.name + " " + index.surname,



    };
  });

  const projectsTableData = {
    columns: [
      { name: "Adı", align: "left", exportableField: `field_name`, searchable: true, searchField: [`field_name`], },
      { name: "Pozisyon", align: "center" },
      //{ name: "Durum", align: "left" },

    ],

    rows: [
      ...egitimRows,

    ],
  };











  return (
    <DashboardLayout>
      <Grid container spacing={3} mt={0} pb={3} mb={8}>
        {/* <Grid item xs={6} sm={6} lg={6}  >
          <DefaultInfoCard
            icon="camera_alt"
            title="Place Story"
            description="Share Your Moments"
            value="Create"
            color="warning"
          />
        </Grid> */}
        <Grid item xs={6} sm={6} lg={6}>
          <DefaultInfoCard
            icon="restaurant"
            title="Food Taste"
            description="Share Your Thoughts"
            value="Create"
            color="leaf"
            link="/search"
          />
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <DefaultInfoCard
            icon="local_dining"
            title="Eatlist"
            description="Share Your Flavors"
            value="Create"
            color="dark"
            link="/create/eatlist"
          />
        </Grid>
        {/* <Grid item xs={6} sm={6} lg={6}>
          <DefaultInfoCard
            icon="route"
            title="Route Plan"
            description="Share Your Route"
            value="Create"
            color="light"
          />
        </Grid> */}

      </Grid>

    </DashboardLayout>
  );

}

export default Tables;
