/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";
import { Avatar } from '@mui/material';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";

// Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'


import logoNewspaper from "assets/images/small-logos/application-4.png";

import logoInvesion from "assets/images/small-logos/logo-invision.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoWebDev from "assets/images/small-logos/logo-webdev.svg";
import logoXD from "assets/images/small-logos/logo-xd.svg";

function Completion({ value, color }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {value}%&nbsp;
      </SoftTypography>
      <SoftBox width="8rem">
        <SoftProgress value={value} color={color} variant="gradient" label={false} />
      </SoftBox>
    </SoftBox>
  );
}

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

const truncatedTextStyle = {
  width: '300px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  height: '20px',
  WebkitBoxOrient: 'vertical'
};

const projectsTableData = {
  columns: [
    { name: "Görev", align: "left" },
    { name: "budget", align: "left" },
    { name: "status", align: "left" },
    { name: "completion", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: [
    {
      Görev: [
        <img key="logoNewspaper" src={logoNewspaper} alt="Logo" />,
        <span key="task1" style={truncatedTextStyle}>Amazon Ormanları Yardım Çağrısı</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $2,500
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          working
        </SoftTypography>
      ),
      completion: <Completion value={60} color="info" />,
      action,
    },
    {
      Görev: [
        <span key="logoNewspaper2">
        <img src={logoNewspaper} alt="Logo" />
      </span>,
        <span key="taskParis" style={truncatedTextStyle}>Paris Sanat Festivali</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $5,000
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          done
        </SoftTypography>
      ),
      completion: <Completion value={100} color="success" />,
      action,
    },
    {
      Görev: [
        <img key="logoNewspaper3" src={logoNewspaper} alt="Logo" />,
        <span key="taskHongKong" style={truncatedTextStyle}>Hong Kong Yasa Tasarısı Protestoları</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $3,400
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          canceled
        </SoftTypography>
      ),
      completion: <Completion value={30} color="error" />,
      action,
    },
    {
      Görev: [
        <img key="logoNewspaper4" src={logoNewspaper} alt="Logo" />,
        <span key="taskCapeTown" style={truncatedTextStyle}>Cape Town Su Kıtlığı Endişesi</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $1,400
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          canceled
        </SoftTypography>
      ),
      completion: <Completion value={0} color="error" />,
      action,
    },
    {
      Görev: [
        <img key="logoNewspaper5" src={logoNewspaper} alt="Logo" />,
        <span key="taskBerlin" style={truncatedTextStyle}>Berlin Duvarı 40. Yıl Kutlamaları</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $14,000
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          working
        </SoftTypography>
      ),
      completion: <Completion value={80} color="info" />,
      action,
    },
    {
      Görev: [
        logoNewspaper,
        <span key="taskTokyo" style={truncatedTextStyle}>Tokyo Robot Teknolojileri Fuarı</span>
      ],
      budget: (
        <SoftTypography variant="button" color="text" fontWeight="medium">
          $2,300
        </SoftTypography>
      ),
      status: (
        <SoftTypography variant="caption" color="text" fontWeight="medium">
          done
        </SoftTypography>
      ),
      completion: <Completion value={100} color="success" />,
      action,
    },
  ],
};

export default projectsTableData;
