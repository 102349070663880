// Gerekli kütüphaneler ve stil dosyaları
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import team5 from "assets/images/logos/food-icon.png";
import ComplexProjectCard from "examples/Cards/ProjectCards/PlaceProjectCard";


/* eslint-disable react/prop-types */

// Öneri kartları
function Stories({ placeData, maxCount = 6 }) {
    // Öneri kartlarını hazırlama
    const suggestions = placeData?.map(place => ({
        background: place.custom_food?.food_photo ? window.BASE_URL + "/" + place.custom_food?.food_photo : team5, // Eğer cover varsa kullan, yoksa team5
        owner: place.custom_food?.custom_restaurant?.name || '', // Foodie'nin adı
        title: place.custom_food.name,
        description: place.comment,
        distance: place.comment, // Mesafe
        count: place.interaction_count, // Count
        score: (place.rating).toFixed(1),
        foodieName: window.BASE_URL + "/" + place.custom_food?.food_photo,  // Restoran adı
    })) || []; // Eğer storiesData undefined veya null ise boş bir dizi döndür

    // Öneri sayısını kontrol etme ve eksikleri doldurma
    const processedSuggestions = suggestions.length >= maxCount ? suggestions.slice(0, maxCount) : [
        ...suggestions,
        ...Array(maxCount - suggestions.length).fill({
            background: team5,
            owner: '',
            distance: 0,
            title: "",
            description: "",
            count: '',
            foodieName: ''
        })
    ];

    const loadContent = (content) => {
        // İçerik yükleme mantığı
        console.log(`Loading content for: ${content}`);
    };

    const showAlert = () => {
        // Uyarı mantığı
        alert('Suggestion card clicked');
    };

    const ScrollableSuggestions = () => (
        <div style={{ width: "100%" }}>
            <Grid container>
                <div className="guide-suggestions" onClick={() => loadContent('eatlist')}>

                    {processedSuggestions.map((suggestion, index) => (
                        <SuggestionCard key={index} {...suggestion} />
                    ))}

                </div>
            </Grid>
        </div>
    );

    const SuggestionCard = ({ background, title, owner, distance, foodieName, count, score, description }) => (
        <Grid item xs={12} sm={5} lg={3.5}  >
            <div >
                <ComplexProjectCard
                    image={background}
                    title={title}
                    description={description ? description : ""}
                    dateTime="6.4 ⭐"
                    members={[foodieName]}
                    owner={owner}
                    raterCount={count}
                    ratingScore={score}
                    dropdown={{
                        action: "openDesignToolsMenu",
                        menu: "",
                    }}
                    color="light"
                />
            </div>
        </Grid>
    );

    return <ScrollableSuggestions />;
}

export default Stories;
