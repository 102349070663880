/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";


function Tables() {

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/yayinoranlari?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [id, navigate]);

 
  console.log("data")
  console.log(data)


    /* ######################################    GRAFİK DETAILS   ############################# */

    const safeValue = (value) => value === undefined ? 0 : value;

    const safeValueWithTryCatch = (value) => {
      try {
        return value || 0;
      } catch (error) {
        return 0;
      }
    };

    const safeDeepValue = (object, path) => {
      try {
        return path.split('.').reduce((o, k) => o[k], object) || 0;
      } catch (error) {
        return 0;
      }
    };



    const regions = [
      "Taze (Fresh) içerik oranları",
      "Canlı (Live) yayın oranı",
      "Yüksek profil (High Profile) konuk oranı",
      "Özel (VIP) konuk oranı ",
    ];



    let items6 = []
    let datasetsData6 = []
    try {
      items6 = [
        {
          icon: { color: "info", component: "library_books" },
          label: "Taze (Fresh) içerik oranları",
          progress: {
            content:  "%" + Number(data["0"].taze_icerik_sayisi),
            percentage: Number(Number(data["0"].taze_icerik_sayisi))/Number(data["0"]?.toplam) * 100,
          },

        },
        {
          icon: { color: "info", component: "library_books" },
          label: "Canlı (Live) yayın oranı",
          progress: {
            content:  "%" + Number(data["0"].canli_yayin_sayisi), 
            percentage: Number(Number(data["0"].canli_yayin_sayisi))/Number(data["0"]?.toplam) * 100,
          }
        },
        {
          icon: { color: "info", component: "library_books" },
          label: "Yüksek profil (High Profile) konuk oranı",
          progress: {
            content: "%" + Number(data["0"].yuksek_profil_konuk_sayisi),
            percentage: Number(Number(data["0"].yuksek_profil_konuk_sayisi))/Number(data["0"]?.toplam) * 100,
          }
        },
        {
          icon: { color: "info", component: "library_books" },
          label: "Özel (VIP) konuk oranı ",
          progress: {
            content:  "%" + Number(data["0"].ozel_konuk_sayisi), 
            percentage: Number(Number(data["0"].ozel_konuk_sayisi))/Number(data["0"]?.toplam) * 100,
          }
        }

      ];

      datasetsData6 = [Number(data["0"].taze_icerik_sayisi),Number(data["0"].canli_yayin_sayisi),Number(data["0"].yuksek_profil_konuk_sayisi),Number(data["0"].ozel_konuk_sayisi)]; // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }
    catch {
      items6 = data.map(item => ({
        icon: { color: "info", component: "library_books" },
        label: item.haberi_yapan_isim, // doğru erişim: item.haber_icerik
        progress: {
          content: item.count, // doğru erişim: item.count
          percentage: 0, // Burada yüzde hesabı nasıl yapılacağına dair bilgi vermediğiniz için 0 olarak bıraktım.
        }
      }));


      datasetsData6 = regions.map(region => 0); // Burada eğer veri yoksa 0 olarak değerlendiriliyor

    }

    const reportsBarChartData6 = {
      chart: {
        labels: regions,
        datasets: { label: "Oran", data: datasetsData6 },
      },
      items: items6,
    };











    return (
        <>
        <SoftBox display="flex" alignItems="center">
          
          <SoftTypography variant="h6" fontWeight="medium" ml={1}>
            Yayın Faaliyet Grafiği
            </SoftTypography>
            
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          
          <SoftTypography variant="h6" fontWeight="medium" color="secondary">
          
            </SoftTypography>
            
        </SoftBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12}>
            <Card>
              <ReportsBarChart
                title="Yayın Oranları 2023 Yılı Dağılımı"
                description={
                  <>
                    Toplam Oran (<strong>%{safeValue(data["0"]?.toplam)}</strong>) 
                  </>
                }
                chart={reportsBarChartData6.chart}
                items={reportsBarChartData6.items}
              />
            </Card>

          </Grid>













        </Grid>
        </>
    );


}

export default Tables;
