

import React, {useEffect, useRef, useState} from "react";
import './AnimationStyles.css';
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-kashik-left2.png";

import axios from "axios";

import {useDispatch, useSelector} from "react-redux";
import { setUserKanal} from "../../../store/actions/authActions";
import { store, persistor }  from "../../../store";

import { useParams, useNavigate } from 'react-router-dom';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import { createStore } from 'redux';

  

const BASE_URL = process.env.REACT_APP_URL

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  //const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [pwd, setPwd] = useState();

  // const handleCheckLoggedIn = () => {

  // }

  // const setCookie = (name, value, days) => {
  //   const expirationDate = new Date();
  //   expirationDate.setDate(expirationDate.getDate() + days);

  //   document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/; Secure; HttpOnly`;
  // };

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    // This will log the updated state whenever it changes
    console.log('isLoggedIn state is now:', isLoggedIn);
  }, [isLoggedIn]);

  const handleLogin = async () => {
    try {
      
      const loginResponse = await axios.post(`${window.BASE_URL}/api/login`, {
        username: email,
        password: pwd
      }, {withCredentials: true});

      if (loginResponse.data.tfa_status == 1){
        window.location.href = '/authentication/two-factor'
      }
      else{
        window.location.href = '/kurum/anasayfa'
      }
      
    } catch (e) {
      console.log('Login error:', e.message);
      alert(e.message);
    }
  }

  return (
    <CoverLayout
      title="KASHIK "
      description="Enter your account information and password to Sign In"
      image={curved9}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Account 
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Username" onChange={(e) => setEmail(e.target.value)}/>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password" onChange={(e) => setPwd(e.target.value)} />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton onClick={() => handleLogin()} variant="gradient" color="warning" fullWidth>
            
            Sign In
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t you have account? {" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="warning"
              fontWeight="medium"
              textGradient
            >
              Register
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
