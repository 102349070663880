/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Icon from '@mui/material/Icon';
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import FormField from "layouts/place/components/new-rate/components/FormField";
import SoftButton from "components/SoftButton";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '5px',
    maxHeight: '90vh',
    maxWidth: '500px',
    overflowY: 'auto',
    width: "80%"
};

const useStyles = makeStyles({
    stepper: {
        fontSize: '10px !important',
    },
    stepLabel: {
        fontSize: '10px !important',
    },
});

function createItems(title, text) {
    let items = [];

    if (title) {
        items.push(
            <SoftBox pt={3} px={2} key={title}>
                <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                        label_important
                    </Icon>
                    <strong>{title} </strong>  <br></br>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
                </SoftTypography>
            </SoftBox>
        );
    }

    return items;
}



export default function BasicModal({ open, handleClose, content, handleModalSubmit }) {
    const classes = useStyles();
    const [flavorName, setFlavorName] = useState("");
    const [flavorDescription, setFlavorDescription] = useState("");

    // Modal form submit işlemi
    const handleSubmit = () => {
        handleModalSubmit({
            name: flavorName,
            description: flavorDescription,
            food_photo: 'custom-image-url' // Burada özel bir görsel URL'si belirtilebilir.
        });
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={style}>
                {createItems(content, "")}
                <Divider />
                <SoftBox pt={2} pb={3} px={0}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                        <SoftBox display="flex" alignItems="center">
                            <SoftAvatar src="-" variant="rounded" icon="local_dining" bgColor={"dark"} />
                            <SoftBox ml={2}>
                            <SoftTypography variant="h5" fontWeight="medium">
                    Flavor Details
                  </SoftTypography>
                  <SoftBox display="flex" alignItems="flex-end">
                    <SoftTypography variant="button" color="text" fontWeight="regular">
                      Tasting Details
                    </SoftTypography>
                  </SoftBox>
                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            pt={3}
                            sx={{ width: "100%", maxWidth: "100%", display: "block" }}
                        >
                            {/* <Divider /> */}

                            <SoftBox ml={0} pl={0} pt={0} lineHeight={1}>
                                <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Title&nbsp;&nbsp;
                                        </SoftTypography>
                                    </SoftBox>
                                    <FormField
                                        type="text"
                                        placeholder="Name of flavor"
                                        multiline
                                        rows={1}
                                        value={flavorName}
                                        onChange={(e) => setFlavorName(e.target.value)}
                                    />
                                </SoftBox>
                            </SoftBox>

                            <SoftBox ml={0} pl={0} pt={0} lineHeight={1}>
                                <SoftBox bgColor="flatwhite" borderRadius="lg" my={3} py={1} pl={{ xs: 1, sm: 2 }} pr={1}>
                                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Description&nbsp;&nbsp;
                                            <SoftTypography variant="caption" fontWeight="regular" color="text">
                                                (optional)
                                            </SoftTypography>
                                        </SoftTypography>
                                    </SoftBox>
                                    <FormField
                                        type="text"
                                        placeholder="Experience about flavor"
                                        multiline
                                        rows={5}
                                        value={flavorDescription}
                                        onChange={(e) => setFlavorDescription(e.target.value)}
                                    />
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>

                        {/* Submit butonu */}
                        <SoftBox style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}>
                            <SoftButton variant="gradient" color="dark" onClick={handleSubmit}>
                                Add Flavor
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </Modal>
    );
}
