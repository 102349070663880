// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSplotch } from '@fortawesome/free-solid-svg-icons';

function ComplexProjectCard({ color, image, title, dateTime, description, members, dropdown, ratingScore, raterCount, link }) {
  const renderMembers = members.map((member, key) => (
    <SoftAvatar
      key={`member-${key}`}
      src={member}
      alt="member profile"
      size="xs"
      sx={({ borders: { borderWidth }, palette: { white } }) => ({
        border: `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",
        "&:not(:first-of-type)": { ml: -1.25 },
        "&:hover, &:focus": { zIndex: "10" },
      })}
    />
  ));

  const handleClick = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <Card
      style={{ overflow: "visible", marginTop: "30px", cursor: link ? "pointer" : "default" }}
      onClick={handleClick}
    >
      <SoftBox p={2}>
        {/* Avatar, Title, and Dropdown */}
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar src={image} alt={title} size="xl" variant="rounded" bgColor={color} sx={{ p: 1, height: "200px", width: "145px", marginTop: "-40px" }} />
          <SoftBox ml={2} lineHeight={0}>
            <SoftTypography variant="h6" textTransform="capitalize" fontWeight="medium">
              {title}
            </SoftTypography>
            {members.length > 0 && (
              <SoftBox display="flex">
                {renderMembers}
                <SoftTypography variant="button" fontWeight="regular" fontSize="10px">
                  {"Kashiked by Ahmed, Mehmet and 36 others"}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
          {dropdown && (
            <SoftTypography
              color="secondary"
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click if dropdown is clicked
                dropdown.action();
              }}
              sx={{ ml: "auto", alignSelf: "flex-start", py: 1.25 }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>more_vert</Icon>
            </SoftTypography>
          )}
        </SoftBox>

        {/* Description */}
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>

        <Divider />

        {/* Rating and DateTime Section */}
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "30px 20px", background: "#f8f9fa", borderRadius: "1rem" }}>
          {raterCount && (
            <SoftBox display="flex" flexDirection="row" lineHeight={0}>
              <SoftTypography variant="button" fontWeight="medium">{raterCount}</SoftTypography>
              <SoftTypography variant="button" fontWeight="medium" color="secondary" sx={{ ml: "5px" }}>Total Rating</SoftTypography>
            </SoftBox>
          )}
          {dateTime && (
            <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
              <SoftTypography variant="button" fontWeight="medium">{ratingScore}</SoftTypography>
              <SoftBox display="flex" alignItems="center" justifyContent="center" sx={{ width: "35px", height: "35px", mx: "10px", background: "white", borderRadius: "1rem" }}>
                <FontAwesomeIcon icon={faSplotch} fontSize="1rem" color="#8392ab" />
              </SoftBox>
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Default props for ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
  link: null,
};

// PropTypes for ComplexProjectCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ action: PropTypes.func, menu: PropTypes.node })]),
  ratingScore: PropTypes.number,
  raterCount: PropTypes.number,
  link: PropTypes.string,
};

export default ComplexProjectCard;
