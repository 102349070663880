import { forwardRef } from "react";
import PropTypes from "prop-types";
import SoftTypographyRoot from "components/SoftTypography/SoftTypographyRoot";
import Skeleton from "@mui/material/Skeleton";
/* eslint-disable react/prop-types */

const SoftTypography = forwardRef(
  (
    {
      color,
      fontWeight,
      textTransform,
      verticalAlign,
      textGradient,
      opacity,
      children,
      isLoading,
      skeletonWidth = "100%",
      skeletonHeight = "1em",
      ...rest
    },
    ref
  ) => (
    <SoftTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{ color, textTransform, verticalAlign, fontWeight, opacity, textGradient }}
    >
      {isLoading ? (
        <Skeleton
          variant="text"
          width={skeletonWidth}
          height={skeletonHeight}
          animation="wave"
        />
      ) : (
        children
      )}
    </SoftTypographyRoot>
  )
);

// Setting default values for the props of SoftTypography
SoftTypography.defaultProps = {
  color: "dark",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
  isLoading: false, // Skeleton varsayılan olarak kapalı
};

// Typechecking props for the SoftTypography
SoftTypography.propTypes = {
  
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node,
  opacity: PropTypes.number,
  isLoading: PropTypes.bool, // Skeleton göstergesi
  skeletonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  skeletonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SoftTypography;
