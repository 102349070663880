

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


/* eslint-disable react/prop-types */
import axios from "axios"

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

import Search from "examples/SearchBars"

import NewFood from "layouts/place/components/new-food";

// Project page components


// Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPen, faPenToSquare, faPersonWalkingLuggage, faSplotch, faStar, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import foodIcon from "assets/images/logos/food-icon-white.png";
import logoInvision from "assets/images/small-logos/logo-invision.svg";

function AllProjects(props) {
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [premiumSupportMenu, setPremiumSupportMenu] = useState(null);
  const [designToolsMenu, setDesignToolsMenu] = useState(null);
  const [lookingGreatMenu, setLookingGreatMenu] = useState(null);
  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openPremiumSupportMenu = (event) => setPremiumSupportMenu(event.currentTarget);
  const closePremiumSupportMenu = () => setPremiumSupportMenu(null);
  const openDesignToolsMenu = (event) => setDesignToolsMenu(event.currentTarget);
  const closeDesignToolsMenu = () => setDesignToolsMenu(null);
  const openLookingGreatMenu = (event) => setLookingGreatMenu(event.currentTarget);
  const closeLookingGreatMenu = () => setLookingGreatMenu(null);
  const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>

        <FontAwesomeIcon icon={faPenToSquare} style={{ marginLeft: -4, marginRight: 4 }} />

        Rate the Food
      </MenuItem>
      <MenuItem onClick={close}>See All Ratings</MenuItem>
      <MenuItem onClick={close}>Report</MenuItem>
    </Menu>
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    // Arama işlemini burada gerçekleştirin
    console.log('Searching for:', searchTerm);
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get(`${window.BASE_URL}/custom-foods/?place_id=${id}&search=${searchTerm}`, { withCredentials: true });
        setData(response.data);

      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, [searchTerm]);


  const [showNewFood, setShowNewFood] = useState(false);

  const handleCreateFoodClick = () => {
    setShowNewFood(true);
  };

  return (
    <>
      {showNewFood ? (
        <NewFood data={props.data} />
      ) : (
        
          <SoftBox pt={5} pb={2} width={"inherit"}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <SoftBox mb={1}>
                  <SoftTypography variant="h5">Menu</SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  {/* <SoftTypography variant="body2" color="text">
                    This is the Menu
                  </SoftTypography> */}
                </SoftBox>

                <Search
                    searchTerm={searchTerm}
                    handleChange={handleChange}
                    handleSearch={handleSearch}
                  />
              </Grid>
            </Grid>
            <SoftBox mt={{ xs: 1, lg: 3 }} mb={1}>
              <Grid container spacing={3}>
                {data?.results?.map((item, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    
                    <ComplexProjectCard
                      image={item.photo ? window.BASE_URL +"/"+item.photo : foodIcon}
                      title={item.name}
                      description={item.description2 || "No description available"}
                      dateTime="6.4 ⭐"
                      members={[team1, team2, team3]}
                      raterCount={item.rate_count}
                      ratingScore= {item.point + ""}
                      dropdown={{
                        action: openDesignToolsMenu,
                        menu: renderMenu(designToolsMenu, closeDesignToolsMenu),
                      }}
                      color="light"
                    />
                  </Grid>
                ))}
                {/* <Grid item xs={12} md={6} lg={4} onClick={handleCreateFoodClick} sx={{ cursor: 'pointer' }}>
                  <PlaceholderCard title={{ variant: "h6", text: "Create & Add Flavor" }} />
                </Grid> */}
              </Grid>
            </SoftBox>
          </SoftBox>
        
      )}
    </>
  );
}

export default AllProjects;
