

import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


/* eslint-disable react/prop-types */
import axios from "axios"

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StarIcon from "@mui/icons-material/Star";


import Map from 'examples/Maps';
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexProjectCard from "examples/Cards/ProjectCards/ComplexProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

import NewFood from "layouts/place/components/new-food";
// Project page components


// Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBuilding, faCalendarMinus, faComments, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPen, faPenToSquare, faPersonWalkingLuggage, faSplotch, faStar, faStarHalfStroke, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoXD from "assets/images/logos/food-icon.png";
import logoInvision from "assets/images/small-logos/logo-invision.svg";

import Search from "examples/SearchBars"
import RoutePost from "examples/RoutePosts";

function AllProjects(data) {
  console.log(data)
  // ComplexProjectCard dropdown menu state
  const [slackBotMenu, setSlackBotMenu] = useState(null);
  const [premiumSupportMenu, setPremiumSupportMenu] = useState(null);
  const [designToolsMenu, setDesignToolsMenu] = useState(null);
  const [lookingGreatMenu, setLookingGreatMenu] = useState(null);
  const [developerFirstMenu, setDeveloperFirstMenu] = useState(null);

  // TeamProfileCard dropdown menu handlers
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const openPremiumSupportMenu = (event) => setPremiumSupportMenu(event.currentTarget);
  const closePremiumSupportMenu = () => setPremiumSupportMenu(null);
  const openDesignToolsMenu = (event) => setDesignToolsMenu(event.currentTarget);
  const closeDesignToolsMenu = () => setDesignToolsMenu(null);
  const openLookingGreatMenu = (event) => setLookingGreatMenu(event.currentTarget);
  const closeLookingGreatMenu = () => setLookingGreatMenu(null);
  const openDeveloperFirstMenu = (event) => setDeveloperFirstMenu(event.currentTarget);
  const closeDeveloperFirstMenu = () => setDeveloperFirstMenu(null);

  // Dropdown menu template for the ComplexProjectCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem onClick={close}>

        <FontAwesomeIcon icon={faPenToSquare} style={{ marginLeft: -4, marginRight: 4 }} />

        Rate the Food
      </MenuItem>
      <MenuItem onClick={close}>See All Ratings</MenuItem>
      <MenuItem onClick={close}>Report</MenuItem>
    </Menu>
  );

  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    // Arama işlemini burada gerçekleştirin
    console.log('Searching for:', searchTerm);
  };

  const { id } = useParams();


  const [showNewFood, setShowNewFood] = useState(false);

  const handleCreateFoodClick = () => {
    setShowNewFood(!showNewFood);
  };

  return (
    <>

      <Grid container spacing={2} m={3} mr={0}>
        <SoftBox pt={2} pb={2} width={"inherit"}>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} >
              <SoftBox display="flex" alignItems="center" justifyContent="center">

                <SoftBox display="flex" alignItems="center" mr={2} width={"55%"}>
                  <SoftTypography style={{ fontSize: "30px" }} component="a" href="#" variant="body2" color="text">
                    <Icon >favorite_border</Icon>&nbsp;
                  </SoftTypography>
                  <SoftTypography variant="button" fontWeight="regular" color="text">
                    {data?.data?.like_count > 0 ? data?.data?.like_count : ""}
                  </SoftTypography>
                </SoftBox>

                <SoftBox id="search" display="flex" alignItems="center" mr={1} onClick={handleCreateFoodClick} >
                  <SoftTypography style={{ fontSize: "30px" }} component="a" href="#" variant="body2" color="text">
                    <Icon >search</Icon>&nbsp;
                  </SoftTypography>

                </SoftBox>

                <SoftBox display="flex" alignItems="center" mr={1} >
                  <SoftTypography style={{ fontSize: "30px" }} component="a" href="#" variant="body2" color="text">
                    <Icon >share</Icon>&nbsp;
                  </SoftTypography>

                </SoftBox>

              </SoftBox>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={7} style={{ maxWidth: "none" }} >

              {showNewFood ? <Search
                searchTerm={searchTerm}
                handleChange={handleChange}
                handleSearch={handleSearch}
                style={{ maxWidth: "none", marginBottom: "2px" }}
              /> : <></>}
            </Grid>
          </Grid>

          <SoftBox mt={{ xs: 1, lg: 3 }} mb={1}>
            <Grid container justifyContent="center" spacing={6}>


              <Grid item xs={12} md={8} lg={8} onClick={null} sx={{ cursor: 'pointer' }}>
                <Card

                  style={{
                    height: "200px",
                    background: "#353535",
                    background: "rgb(245 245 245)",
                    width: "100%",
                    borderRadius: "0.5rem",
                    boxShadow: "0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)"
                  }}
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    height="112%"
                    width="100%"
                    style={{ position: "relative" }}

                  >
                    <Map data={data.data} />
                  </SoftBox>
                </Card>
              </Grid>

              <Grid item xs={12} md={8} lg={6} mt={-4} style={{ flexDirection: "column" }}>

                {data?.data?.route_list_items?.map((item, index) => (

                  <RoutePost key={index} data={item} />


                ))}


                
              </Grid>
              <Grid item xs={12} md={8} lg={8} onClick={null} sx={{ cursor: 'pointer' }}>
                <PlaceholderCard title={{ variant: "h5", text: "Create & Add Flavor" }} />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </Grid>
    </>
  );
}

export default AllProjects;
