import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom"; // React Router'dan navigate hook'u

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faMessage } from '@fortawesome/free-solid-svg-icons';

function ComplexProjectCard({ color, image, title, titleLink, dateTime, description, members, owner, ownerLink, dropdown, ratingScore, commentCount }) {
  const navigate = useNavigate(); // React Router navigate

  const renderMembers = members.map((member, key) => (
    <SoftAvatar
      key={`member-${key}`}
      src={member}
      alt="member profile"
      size="xs"
      sx={({ borders: { borderWidth }, palette: { white } }) => ({
        border: `${borderWidth[2]} solid ${white.main}`,
        cursor: "pointer",
        position: "relative",

        "&:not(:first-of-type)": { ml: -1.25 },
        "&:hover, &:focus": { zIndex: "10" },
      })}
    />
  ));

  return (
    <Card style={{ overflow: "visible", marginTop: "30px" }}>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center">
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, height: "145px", width: "145px", marginTop: "-40px", position: "absolute", filter: "blur(15px)" }}
          />
          <SoftAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, height: "145px", width: "145px", marginTop: "-40px" }}
          />

          <SoftBox ml={2} lineHeight={0}>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{ cursor: "pointer", textDecoration: "none" }}
                onClick={() => navigate(titleLink)}
              >
                {title}
              </SoftTypography>
            </SoftBox>
            {members.length > -1 && (
              <SoftBox display="flex">
                {renderMembers}
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  fontSize="10px"
                  alignContent="center"
                  marginLeft="3px"
                  sx={{ cursor: "pointer", textDecoration: "none" }}
                  onClick={() => navigate(ownerLink)}
                >
                  {owner}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
          {dropdown && (
            <SoftTypography
              color="secondary"
              onClick={(e) => {
                e.stopPropagation(); // Prevent dropdown click from triggering card click
                dropdown.action();
              }}
              sx={{ ml: "auto", alignSelf: "flex-start", py: 1.25 }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </SoftTypography>
          )}
          {dropdown.menu}
        </SoftBox>
        <SoftBox my={2} lineHeight={1}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {description}
          </SoftTypography>
        </SoftBox>
        <Divider />

        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{ paddingInline: "10px", paddingBlock: "15px", background: "#f8f9fa", borderRadius: "1rem" }}>
          <SoftBox display="flex" flexDirection="row" lineHeight={0} alignItems="center">
            <SoftBox display="flex" alignItems="center" justifyContent="center" sx={{ width: "35px", height: "35px", mx: "10px", background: "white", borderRadius: "1rem" }}>
              <FontAwesomeIcon icon={faHeart} fontSize="1rem" color="#8392ab" />
            </SoftBox>
            <SoftTypography variant="button" fontWeight="medium">
              {ratingScore}
            </SoftTypography>

            <SoftBox display="flex" alignItems="center" justifyContent="center" sx={{ width: "35px", height: "35px", mx: "10px", background: "white", borderRadius: "1rem" }}>
              <FontAwesomeIcon icon={faMessage} fontSize="1rem" color="#8392ab" />
            </SoftBox>
            <SoftTypography variant="button" fontWeight="medium">
              {commentCount}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Default props for ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  dateTime: "",
  members: [],
  dropdown: false,
  titleLink: "/",
  ownerLink: "/",
};

// PropTypes for ComplexProjectCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
  members: PropTypes.arrayOf(PropTypes.string),
  owner: PropTypes.string,
  ownerLink: PropTypes.string,
  dropdown: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ action: PropTypes.func, menu: PropTypes.node })]),
  ratingScore: PropTypes.number,
  commentCount: PropTypes.number,
};

export default ComplexProjectCard;
