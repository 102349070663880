
/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import MobileNavbar from "examples/Navbars/DashboardNavbar/mobile";

import axios from "axios"
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";



function DashboardNavbar({ absolute, light, isMini, data, onlyMobile = false, onlyWeb= false , hidden = false }) {

  const { t } = useTranslation();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const navigate = useNavigate();

  function copyCurrentUrl() {
    const { pathname, search, hash } = window.location; // Tarayıcının mevcut URL'sini alır
    const fullUrl = `${window.location.origin}${pathname}${search}${hash}`; // Tüm URL'yi birleştirir
  
    navigator.clipboard.writeText(fullUrl) // Panoya kopyalar
      .then(() => {
        setOpenMenu(false);
        
      })
      .catch((error) => {
        console.error('URL kopyalama başarısız:', error); // Hata olursa konsola yaz
        alert('Error'); // Hata mesajı
      });
      
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleProfileCloseMenu = (path) => {
    setOpenMenu(false);
    navigate(path);
  };


  const logout = async () => {
    try {
      const response = await axios.post(`${window.BASE_URL}/api/logout`, {}, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      //console.log('Logout successful:', response.data);
      //navigate('/kurum');
      window.location.href = '/authentication/sign-in'
    } catch (error) {
      console.error('Logout failed:', error);
      // Logout başarısızsa burada hata işleme yapabilirsiniz.
    }
  };

  const handleLogoutMenu = () => {
    logout();
    setOpenMenu(false);
  };

  const userId = useSelector(state => state.auth.userId);
  //const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const username = useSelector(state => state.auth.username);
  const userRole = useSelector(state => state.auth.userRole);
  const userPhoto = useSelector(state => state.auth.userPhoto);
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2, backdropFilter:"blur(1px)" }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.8) !important", // Menü arka plan rengi
            color: "white", // Menü metin rengi
          },
          className: "MuiPaper-root MuiMenu-paper MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-bv446k-MuiPaper-root-MuiMenu-paper-MuiPopover-paper"
        },
      }}

    >


<NotificationItem
    //image={<img src={team2} alt="person" />}
    color="light"
    image={
       <SoftAvatar
          src={""}
          alt="profile-image"
          variant="rounded"
          size="sm"
          icon="share"
          shadow="sm"
          bgColor="warning"

        />
    }
    title={[t("detail.title.share"), t("detail.subtitle.share")]}
    date=""
    icon=""
    onClick={copyCurrentUrl}
  />
      {username ? <>
        {/* <NotificationItem
          //image={<img src={team2} alt="person" />}
          color="light"
          image={
            userPhoto == "-" ?
              <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                person
              </Icon> : <SoftAvatar
                src={window.BASE_URL + userPhoto}
                alt="profile-image"
                variant="rounded"
                size="sm"
                icon="person"
                shadow="sm"

              />
          }
          title={[username, userRole]}
          date="Profili Görüntüle"
          icon="person"
          onClick={() => handleProfileCloseMenu(`/profile/${userId}`)}
        /> */}

        <NotificationItem
          color="dark"
          image={
            <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
              logout
            </Icon>
          }
          title={[t("detail.title.logout"), t("detail.subtitle.logout")]}
          date=""
          icon=""
          onClick={handleLogoutMenu}
        />
      </> : <>
    
  </>
    }
    </Menu>
  );

  const cog =  <>
  <div className="cog-icon">
    <IconButton
      sx={{ display: "inherit", position: "initial" }}
      onClick={handleOpenMenu}
    >
      <i className="fas fa-cog fa-lg"></i>
    </IconButton>
  </div>

  {renderMenu()} </>

  return (
    <>
      {!miniSidenav ? onlyMobile ? "" : hidden ? cog  :
        <AppBar
          position={absolute ? "absolute" : navbarType}
          color="inherit"
          sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}


        >
          <Toolbar sx={(theme) => navbarContainer(theme)} >
            <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })} mt={4} >
              <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} data={data} />
            </SoftBox>
            {isMini ? null : (
              <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>

                <SoftBox color={light ? "white" : "inherit"}>

                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarMobileMenu}
                    onClick={handleMiniSidenav}
                  >
                    <Icon className={light ? "text-white" : "text-dark"}>
                      {miniSidenav ? "menu_open" : "menu"}
                    </Icon>
                  </IconButton>

                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleOpenMenu}
                  >
                    <Icon size={70} className={light ? "text-white" : "text-dark"}>person</Icon>
                    <SoftTypography color={light ? "text-white" : "text"} variant="caption" fontWeight="medium">Profile</SoftTypography>
                  </IconButton>
                  {renderMenu()}
                </SoftBox>
              </SoftBox>
            )}
          </Toolbar>
        </AppBar> : onlyWeb ? cog : <> <MobileNavbar renderMenu={handleOpenMenu} />  {renderMenu()}</> }
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
